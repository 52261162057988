import React, {useEffect} from "react";
import toast, {Toaster, useToasterStore} from 'react-hot-toast';

const HotToaster = ({limit = 1}: any) => {
    const {toasts} = useToasterStore();

    useEffect(() => {
        toasts.filter((toast: any) => toast.visible).filter((item: any, i: number) => i >= limit).forEach((t: any) => toast.dismiss(t.id));
    }, [toasts]);

    return (
        <Toaster position="top-right" containerClassName={'hot-toaster'}/>
    )
}

export interface IToastMethods {
    closeToast: () => void
}

const showToast = (render: (methods: IToastMethods) => JSX.Element, id?: string) => {
    toast(
        (t: any) => {

            const closeToast = () => toast.dismiss(t.id);
            return (
                <div className={`${t.visible ? "top-0" : "-top-96"}`}>
                    {render({closeToast})}
                </div>
            )
        },
        {
            id,
            duration: 6000,
            style: {
                background: "transparent",
                boxShadow: "none",
                padding: 0
            }
        }
    );
}
export {HotToaster, showToast}