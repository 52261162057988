import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import FlowTable from "../../../../modules/admin/crud/entities/flow/FlowTable";


const FlowsRoute = ({}) => {

    useEffect(() => {

    }, []);

    return (
        <>
            <Helmet>
                <title>Flows</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl py-5'}>
                <div className={''}>
                    <FlowTable/>
                </div>
            </div>
        </>
    )
}

export default FlowsRoute
