import React from 'react';
import {FaDiscord} from 'react-icons/fa';
import {useDiscord} from "../hooks/useDiscord";
import {ButtonConnectWith} from "./ButtonConnectWith";


interface IProps {
    disabled?: boolean;
    fullWidth?: boolean;
}


export const ConnectWithDiscord = ({disabled, fullWidth}: IProps): JSX.Element => {

    const {connectWithDiscord} = useDiscord();
    return (


        <ButtonConnectWith onClick={connectWithDiscord} className={'bg-discord hover:bg-discord-dark'}>
            <FaDiscord className={'w-6 h-6'}/>
            <span className={"ml-3"}>Login With Discord</span>
        </ButtonConnectWith>

    )

}