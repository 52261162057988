import React, {useEffect} from "react";
import {FormSelect, Role} from "front-lib";

interface IProps {
    // name?: string;
    defaultValue?: string;
    readOnly?: boolean
    rules?: any
}

const FormSelectEnvAccessibility = ({defaultValue, readOnly, ...props}: IProps) => {

    return (
        <>
            <FormSelect label={"Accessibility"} name={"accessibility"} options={envAccessibilityOptions}
                        defaultValue={defaultValue} readOnly={readOnly} {...props} />
        </>
    );
};


export const envAccessibilityOptions = (() => {
    const items = [
        {
            value: "private",
            name: "Private"
        },
        {
            value: "workspace",
            name: "Workspace"
        },
        {
            value: "public",
            name: "Public"
        }
    ];
    return items;
})();
export default FormSelectEnvAccessibility;
