import React from "react";
import {Helmet} from "react-helmet-async";
import {ConnectForm} from "front-lib";

const ConnectRoute = () => {
    return (
        <>
            <Helmet>
                <title>Connect</title>
            </Helmet>
            <section>
                <ConnectForm/>
            </section>
        </>
    );
};

export default ConnectRoute;
