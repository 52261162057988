import React, {useState} from "react";
import {useForm , LoadingStripes} from "front-lib";

const ButtonConnectWith = ({className, onClick, children}: any) => {
    const [isLoading, setIsLoading] = useState(false);

    const {handleSubmit} = useForm();

    return (
        <>
            <button type="button"
                    onClick={(e) => {
                        handleSubmit(async () => {
                            setIsLoading(true);
                            onClick(e);
                        })
                    }}
                    className={`${className} w-full flex justify-center items-center px-6 py-4 text-white font- tracking-wide text-md md:text-lg  uppercase rounded shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out`}
            >
                {!isLoading && children}
                {isLoading && <LoadingStripes text={""} className={'small white'}/>}
            </button>
        </>
    )
}

export {ButtonConnectWith}