import React from "react"
import {DialogBasic} from "front-lib";
import TranslationForm from "../forms/TranslationForm";
import {useGenericTable} from "../../../table/context/GenericTableContext";
import {useTranslationCrudApi} from "../api/useTranslationCrudApi";

const DialogCreateTranslation = ({}) => {
    const crudApi = useTranslationCrudApi();
    const {loadRows} = useGenericTable();
    return (
        <>
            <DialogBasic
                title={"Create Translation"}
                renderTrigger={<button
                    className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    Create
                </button>}
                renderContent={({toggleOpen}: any) => {
                    return <>
                        <TranslationForm translation={{}} onSubmit={async ({fieldValues}: any) => {
                            console.log("fieldValues", fieldValues)
                            await crudApi.create(fieldValues);
                            await loadRows();
                            toggleOpen()
                        }} onCancel={toggleOpen}/>
                    </>
                }}
            />
        </>
    )
}

export default DialogCreateTranslation