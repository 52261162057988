import React from 'react';
import {LabelNull} from "./LabelNull";

const LabelNullOrValue = ({ value }: any) => {
  const haveValue = value !== null && value !== undefined && value !== '';
  return (
    <>
      {haveValue && value}
      {!haveValue && <LabelNull />}
    </>
  );
};

export {LabelNullOrValue};
