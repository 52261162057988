import React from "react";
import {FaDiscord, FaTwitch} from "react-icons/all";
import {LabelDateTime} from "front-lib";
import {Link, useNavigate} from "react-router-dom";

interface IProps {
    build: any;
    env: any;
    width?: number | string;
}

const LabelBuildScreenshot = ({env, build, width = 130}: IProps) => {
    const navigate = useNavigate();

    const withScreenshot = build && build.screenshot;
    return (
        <figure className={"cursor-pointer"} style={{width}}>
            {withScreenshot && <Link to={`/app/v/${env.key}/${build.key}`}>
                <img className="h-auto max-w-full rounded-lg"
                     src={build.screenshot}
                     alt="build preview"/>
                <figcaption
                    className="mt-2 text-sm text-center text-gray-500 dark:text-gray-400">

                    {/*<LabelDateTime timestamp={ env.lastBuildTime}/>*/}
                    {/*{env.root}*/}
                    {build.key}
                </figcaption>
            </Link>}
            {!withScreenshot && <NoScreenshotImage/>}

        </figure>
    );
};

const NoScreenshotImage = () => {
    return <div className={'relative w-full h-full'}>
        <svg id="building-screenshot" className={'w-full h-full p-5 '} enableBackground="new 0 0 70 70"
             viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg">
            <g>
                <g>
                    <g>
                        <path
                            d="m63.77 8.06v50.83c0 2.67-2.17 4.84-4.84 4.84h-50.92c-2.67 0-4.84-2.17-4.84-4.84v-50.83c0-2.67 2.17-4.84 4.84-4.84h50.92c2.67-.01 4.84 2.16 4.84 4.84z"
                            fill="#5b52c5"/>
                        <path
                            d="m58.93 64.23h-50.92c-2.95 0-5.34-2.4-5.34-5.34v-50.83c0-2.95 2.4-5.34 5.34-5.34h50.92c2.95 0 5.34 2.4 5.34 5.34v50.83c0 2.94-2.39 5.34-5.34 5.34zm-50.92-60.52c-2.4 0-4.34 1.95-4.34 4.34v50.83c0 2.4 1.95 4.34 4.34 4.34h50.92c2.4 0 4.34-1.95 4.34-4.34v-50.82c0-2.4-1.95-4.34-4.34-4.34h-50.92z"/>
                    </g>
                    <g>
                        <path
                            d="m66.83 11.12v50.83c0 2.67-2.17 4.84-4.84 4.84h-50.92c-2.67 0-4.84-2.17-4.84-4.84v-50.83c0-2.67 2.17-4.84 4.84-4.84h50.92c2.67-.01 4.84 2.16 4.84 4.84z"
                            fill="#efefed"/>
                        <path
                            d="m61.99 67.29h-50.92c-2.95 0-5.35-2.4-5.35-5.34v-50.83c0-2.95 2.4-5.34 5.35-5.34h50.92c2.95 0 5.34 2.4 5.34 5.34v50.83c0 2.94-2.39 5.34-5.34 5.34zm-50.92-60.52c-2.4 0-4.35 1.95-4.35 4.34v50.83c0 2.4 1.95 4.34 4.35 4.34h50.92c2.4 0 4.34-1.95 4.34-4.34v-50.82c0-2.4-1.95-4.34-4.34-4.34h-50.92z"/>
                    </g>
                    <g>
                        <path d="m66.83 18.34v-7.22c0-2.67-2.17-4.84-4.84-4.84h-50.92c-2.67 0-4.84 2.17-4.84 4.84v7.22z"
                              fill="#8f90fb"/>
                        <path
                            d="m66.83 18.84h-60.6c-.28 0-.5-.22-.5-.5v-7.23c0-2.95 2.4-5.34 5.35-5.34h50.92c2.95 0 5.34 2.4 5.34 5.34v7.23c-.01.28-.23.5-.51.5zm-60.1-1h59.61v-6.73c0-2.4-1.95-4.34-4.34-4.34h-50.93c-2.4 0-4.35 1.95-4.35 4.34v6.73z"/>
                    </g>
                    <g>
                        <circle cx="11.75" cy="12.31" fill="#fff" r="1.44"/>
                        <path
                            d="m11.75 14.25c-1.07 0-1.94-.87-1.94-1.94s.87-1.94 1.94-1.94 1.95.87 1.95 1.94-.88 1.94-1.95 1.94zm0-2.89c-.52 0-.94.42-.94.94s.42.94.94.94.95-.42.95-.94-.43-.94-.95-.94z"/>
                    </g>
                    <g>
                        <circle cx="17" cy="12.31" fill="#fff" r="1.44"/>
                        <path
                            d="m17 14.25c-1.07 0-1.94-.87-1.94-1.94s.87-1.94 1.94-1.94 1.94.87 1.94 1.94-.87 1.94-1.94 1.94zm0-2.89c-.52 0-.94.42-.94.94s.42.94.94.94.94-.42.94-.94-.42-.94-.94-.94z"/>
                    </g>
                    <g>
                        <circle cx="22.25" cy="12.31" fill="#fff" r="1.44"/>
                        <path
                            d="m22.25 14.25c-1.07 0-1.94-.87-1.94-1.94s.87-1.94 1.94-1.94 1.94.87 1.94 1.94-.87 1.94-1.94 1.94zm0-2.89c-.52 0-.94.42-.94.94s.42.94.94.94.94-.42.94-.94-.42-.94-.94-.94z"/>
                    </g>
                    <g>
                        <path
                            d="m62.75 12.81h-22.69c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h22.69c.28 0 .5.22.5.5s-.22.5-.5.5z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <g>
                                <path
                                    d="m30.98 45.33c-.31 0-.62-.1-.89-.29l-6.44-4.75c-.4-.3-.63-.77-.61-1.27s.29-.95.71-1.21l6.94-4.28c.71-.43 1.63-.22 2.06.49s.22 1.63-.49 2.06l-5.05 3.11 4.65 3.43c.67.49.81 1.43.32 2.1-.29.4-.74.61-1.2.61z"
                                    fill="#fbe063"/>
                                <path
                                    d="m30.98 45.83c-.43 0-.84-.14-1.19-.39l-6.44-4.75c-.54-.4-.84-1.03-.81-1.69s.38-1.27.95-1.62l6.94-4.28c.94-.58 2.17-.29 2.75.65s.29 2.17-.65 2.75l-4.42 2.72 4.05 2.99c.89.66 1.08 1.91.42 2.8-.37.51-.97.82-1.6.82zm.5-12.03c-.18 0-.36.05-.52.15l-6.94 4.28c-.28.17-.46.48-.47.81s.14.65.41.85l6.44 4.75c.43.32 1.08.22 1.4-.21.33-.44.23-1.07-.21-1.4l-4.65-3.43c-.13-.1-.21-.26-.2-.42.01-.17.1-.32.24-.4l5.05-3.11c.47-.29.62-.91.33-1.38-.22-.32-.54-.49-.88-.49z"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path
                                    d="m41.58 53.76c-.5 0-.99-.25-1.28-.71-.43-.71-.22-1.63.49-2.06l5.05-3.11-4.65-3.43c-.67-.49-.81-1.43-.32-2.1s1.43-.81 2.1-.32l6.44 4.75c.4.3.63.77.61 1.27s-.29.95-.71 1.21l-6.94 4.28c-.25.15-.52.22-.79.22z"
                                    fill="#fbe063"/>
                                <path
                                    d="m41.58 54.26c-.7 0-1.34-.36-1.7-.95-.28-.46-.37-.99-.24-1.51.12-.52.44-.96.9-1.24l4.42-2.72-4.05-2.99c-.89-.65-1.08-1.91-.42-2.8s1.91-1.08 2.8-.42l6.44 4.75c.54.4.84 1.03.81 1.69s-.38 1.27-.95 1.62l-6.94 4.28c-.34.19-.7.29-1.07.29zm.5-12.03c-.31 0-.61.14-.81.41-.16.21-.22.48-.18.74s.18.5.4.66l4.65 3.43c.13.1.21.26.2.42-.01.17-.1.32-.24.4l-5.05 3.11c-.47.29-.62.91-.33 1.38.28.45.92.61 1.38.33l6.94-4.28c.28-.17.46-.48.47-.81s-.14-.65-.41-.85l-6.44-4.75c-.16-.12-.37-.19-.58-.19z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <path
                                d="m33.72 54.94c-.14 0-.27-.02-.41-.06-.8-.23-1.26-1.06-1.03-1.85l5.63-19.82c.23-.8 1.06-1.26 1.85-1.03.8.23 1.26 1.06 1.03 1.85l-5.63 19.82c-.19.66-.79 1.09-1.44 1.09z"
                                fill="#fbe063"/>
                            <path
                                d="m33.72 55.44c-.19 0-.37-.03-.55-.08-.51-.15-.94-.48-1.2-.95s-.32-1.01-.18-1.52l5.63-19.82c.15-.51.48-.94.95-1.2s1.01-.32 1.52-.17.94.48 1.2.95.32 1.01.18 1.52l-5.63 19.82c-.25.85-1.04 1.45-1.92 1.45zm5.62-22.82c-.17 0-.33.04-.48.13-.23.13-.4.34-.48.6l-5.63 19.82c-.07.26-.04.53.09.76s.34.4.6.47c.53.15 1.09-.17 1.24-.69l5.63-19.82c.07-.26.04-.53-.09-.76s-.34-.4-.6-.47c-.09-.03-.19-.04-.28-.04z"/>
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <g>
                            <g>
                                <path
                                    d="m23.03 58c0 .59-.48 1.07-1.07 1.07h-.75c-3.24 0-5.66-1.41-5.66-5.34 0-1.06.16-2.15.32-3.2.15-1.06.31-2.11.31-3.16 0-1.07-.31-2.51-2.3-2.82-.49-.08-.86-.48-.86-.98v-.16c0-.5.38-.9.87-.98 1.98-.29 2.29-1.62 2.29-2.82 0-1.09-.16-2.19-.31-3.28-.16-1.09-.32-2.14-.32-3.24 0-3.58 2.34-5.1 5.66-5.1h.75c.59 0 1.07.48 1.07 1.07s-.48 1.07-1.07 1.07h-.63c-2.31 0-3.09 1.25-3.09 3.35 0 .9.12 1.84.28 2.77.15.98.27 1.91.27 2.97.04 2.49-1.05 3.74-2.81 4.21v.08c1.76.43 2.85 1.75 2.81 4.25 0 1.05-.12 2.03-.27 2.96-.16.97-.27 1.85-.28 2.78 0 1.88 1.5 3.42 3.39 3.42h.33c.59 0 1.07.48 1.07 1.08z"
                                    fill="#54e28e"/>
                                <path
                                    d="m21.96 59.57h-.75c-3.97 0-6.16-2.07-6.16-5.84 0-1.05.15-2.11.33-3.28l.03-.21c.14-.99.27-1.93.27-2.87 0-1.39-.6-2.13-1.88-2.33-.75-.12-1.28-.74-1.28-1.47v-.16c0-.73.54-1.35 1.29-1.47 1.33-.19 1.87-.87 1.87-2.33 0-1-.14-2.04-.28-3.04l-.04-.27c-.16-1.08-.31-2.11-.31-3.21 0-3.56 2.25-5.6 6.16-5.6h.75c.87 0 1.57.7 1.57 1.57s-.7 1.57-1.57 1.57h-.63c-1.84 0-2.59.83-2.59 2.85 0 .75.09 1.6.27 2.69.13.88.28 1.9.28 3.05.03 2.04-.66 3.47-2.05 4.24 1.11.62 2.09 1.86 2.05 4.3 0 1.17-.15 2.24-.28 3.03-.19 1.13-.27 1.91-.27 2.71 0 1.6 1.3 2.91 2.89 2.91h.33c.87 0 1.57.71 1.57 1.58 0 .88-.71 1.58-1.57 1.58zm-.75-31.08c-2.35 0-5.16.8-5.16 4.6 0 1.03.15 2.02.3 3.06l.04.28c.14 1.04.29 2.11.29 3.18 0 .72 0 2.92-2.72 3.31-.25.04-.44.25-.44.49v.16c0 .24.18.44.44.49 1.24.19 2.72.93 2.72 3.31 0 1.01-.14 2.03-.28 3.01l-.03.22c-.17 1.13-.31 2.15-.31 3.13 0 3.21 1.74 4.84 5.16 4.84h.75c.31 0 .57-.26.57-.57 0-.32-.26-.58-.57-.58h-.33c-2.15 0-3.89-1.76-3.89-3.92.01-1.06.15-2.04.29-2.86.12-.76.26-1.77.26-2.88.03-2.13-.76-3.36-2.43-3.76-.22-.05-.38-.26-.38-.49s.15-.5.37-.56c1.7-.45 2.47-1.63 2.44-3.72 0-.98-.1-1.84-.26-2.9-.19-1.13-.29-2.04-.29-2.85 0-2.59 1.17-3.85 3.59-3.85h.63c.31 0 .57-.26.57-.57s-.26-.57-.57-.57z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <path
                            d="m50.03 58c0 .59.48 1.07 1.07 1.07h.75c3.24 0 5.66-1.41 5.66-5.34 0-1.06-.16-2.15-.32-3.2-.15-1.06-.31-2.11-.31-3.16 0-1.07.31-2.51 2.3-2.82.49-.08.86-.48.86-.98v-.16c0-.5-.38-.9-.87-.98-1.98-.29-2.29-1.62-2.29-2.82 0-1.09.16-2.19.31-3.28.16-1.09.32-2.14.32-3.24 0-3.58-2.34-5.1-5.66-5.1h-.75c-.59 0-1.07.48-1.07 1.07s.48 1.07 1.07 1.07h.63c2.31 0 3.09 1.25 3.09 3.35 0 .9-.12 1.84-.28 2.77-.15.98-.27 1.91-.27 2.97-.04 2.49 1.05 3.74 2.81 4.21v.08c-1.76.43-2.85 1.75-2.81 4.25 0 1.05.12 2.03.27 2.96.16.97.27 1.85.28 2.78 0 1.88-1.5 3.42-3.39 3.42h-.33c-.59 0-1.07.48-1.07 1.08z"
                            fill="#54e28e"/>
                        <path
                            d="m51.85 59.57h-.75c-.87 0-1.57-.7-1.57-1.57s.7-1.58 1.57-1.58h.33c1.59 0 2.89-1.31 2.89-2.92-.01-.98-.14-1.92-.27-2.7-.13-.8-.28-1.87-.28-3.04-.04-2.43.94-3.67 2.05-4.3-1.39-.78-2.08-2.2-2.05-4.25 0-1.03.11-1.92.28-3.04.19-1.09.27-1.94.27-2.69 0-2.02-.75-2.85-2.59-2.85h-.63c-.87 0-1.57-.7-1.57-1.57s.7-1.57 1.57-1.57h.75c3.92 0 6.16 2.04 6.16 5.6 0 1.1-.15 2.12-.31 3.2l-.04.28c-.14 1-.28 2.04-.28 3.04 0 1.46.54 2.13 1.86 2.33.76.12 1.3.74 1.3 1.48v.16c0 .73-.54 1.35-1.28 1.47-1.28.2-1.88.94-1.88 2.33 0 .94.13 1.88.27 2.87l.03.22c.14.95.33 2.13.33 3.27 0 3.76-2.19 5.83-6.16 5.83zm-.75-2.15c-.31 0-.57.26-.57.58 0 .31.26.57.57.57h.75c3.42 0 5.16-1.63 5.16-4.84 0-1.06-.17-2.21-.31-3.12l-.03-.22c-.14-.98-.28-2-.28-3.01 0-2.39 1.48-3.12 2.72-3.31.26-.04.44-.24.44-.49v-.16c0-.24-.19-.44-.45-.49-2.71-.4-2.71-2.59-2.71-3.31 0-1.07.15-2.14.29-3.18l.04-.29c.15-1.04.3-2.03.3-3.06 0-3.8-2.81-4.6-5.16-4.6h-.76c-.31 0-.57.26-.57.57s.26.57.57.57h.63c2.42 0 3.59 1.26 3.59 3.85 0 .81-.09 1.72-.29 2.85-.16 1.06-.26 1.91-.26 2.88-.03 2.09.74 3.27 2.44 3.73.22.06.37.26.37.48 0 .23-.16.51-.38.57-1.67.41-2.46 1.64-2.43 3.76 0 1.11.14 2.13.26 2.89.14.82.27 1.8.29 2.85 0 2.17-1.75 3.93-3.89 3.93z"/>
                    </g>
                </g>
            </g>
        </svg>
        {/*<svg height="424pt" className={'w-full h-full p-7 '}  viewBox="0 -25 424 424" width="424pt" xmlns="http://www.w3.org/2000/svg">*/}
        {/*    <path*/}
        {/*        d="m167.289062 272.132812c-1.601562 0-3.214843-.550781-4.53125-1.671874l-45.296874-38.570313c-1.5625-1.332031-2.460938-3.277344-2.460938-5.332031 0-2.050782.902344-3.996094 2.460938-5.328125l45.296874-38.570313c2.941407-2.507812 7.359376-2.152344 9.867188.789063 2.503906 2.945312 2.152344 7.363281-.792969 9.867187l-39.035156 33.242188 39.035156 33.242187c2.945313 2.507813 3.296875 6.925781.792969 9.867188-1.386719 1.628906-3.355469 2.464843-5.335938 2.464843zm0 0"/>*/}
        {/*    <path*/}
        {/*        d="m256.710938 272.132812c-1.980469 0-3.949219-.835937-5.332032-2.464843-2.507812-2.941407-2.152344-7.359375.789063-9.867188l39.035156-33.242187-39.035156-33.242188c-2.941407-2.503906-3.296875-6.921875-.789063-9.867187 2.503906-2.941407 6.925782-3.296875 9.863282-.789063l45.296874 38.570313c1.5625 1.332031 2.460938 3.277343 2.460938 5.328125 0 2.054687-.898438 4-2.460938 5.332031l-45.296874 38.570313c-1.316407 1.121093-2.929688 1.671874-4.53125 1.671874zm0 0"/>*/}
        {/*    <path*/}
        {/*        d="m195.964844 301.0625c-.480469 0-.964844-.050781-1.453125-.152344-3.78125-.796875-6.203125-4.511718-5.40625-8.292968l28.230469-134.035157c.796874-3.78125 4.507812-6.203125 8.292968-5.40625 3.78125.796875 6.203125 4.507813 5.40625 8.292969l-28.230468 134.035156c-.695313 3.296875-3.601563 5.558594-6.839844 5.558594zm0 0"/>*/}
        {/*    <path*/}
        {/*        d="m377 374.085938h-330c-25.914062 0-47-21.082032-47-47v-280.085938c0-25.914062 21.085938-47 47-47h330c25.914062 0 47 21.085938 47 47v280.085938c0 25.917968-21.085938 47-47 47zm-330-360.085938c-18.195312 0-33 14.804688-33 33v280.085938c0 18.195312 14.804688 33 33 33h330c18.195312 0 33-14.804688 33-33v-280.085938c0-18.195312-14.804688-33-33-33zm0 0"/>*/}
        {/*    <path*/}
        {/*        d="m417 112.089844h-410c-3.867188 0-7-3.132813-7-7 0-3.863282 3.132812-7 7-7h410c3.867188 0 7 3.136718 7 7 0 3.867187-3.132812 7-7 7zm0 0"/>*/}
        {/*    <path*/}
        {/*        d="m119.601562 78.59375c-12.210937 0-22.152343-9.941406-22.152343-22.152344 0-12.214844 9.941406-22.152344 22.152343-22.152344 12.214844 0 22.152344 9.9375 22.152344 22.152344 0 12.210938-9.9375 22.152344-22.152344 22.152344zm0-30.304688c-4.492187 0-8.152343 3.65625-8.152343 8.152344s3.660156 8.152344 8.152343 8.152344c4.496094 0 8.152344-3.65625 8.152344-8.152344s-3.65625-8.152344-8.152344-8.152344zm0 0"/>*/}
        {/*    <path*/}
        {/*        d="m51.539062 78.378906c-12.214843 0-22.152343-9.9375-22.152343-22.152344 0-12.214843 9.9375-22.152343 22.152343-22.152343 12.214844 0 22.152344 9.9375 22.152344 22.152343 0 12.214844-9.9375 22.152344-22.152344 22.152344zm0-30.304687c-4.492187 0-8.152343 3.660156-8.152343 8.152343 0 4.496094 3.660156 8.152344 8.152343 8.152344 4.496094 0 8.152344-3.65625 8.152344-8.152344 0-4.492187-3.65625-8.152343-8.152344-8.152343zm0 0"/>*/}
        {/*    <path*/}
        {/*        d="m187.664062 78.804688c-12.210937 0-22.148437-9.9375-22.148437-22.152344 0-12.210938 9.9375-22.148438 22.148437-22.148438 12.214844 0 22.152344 9.9375 22.152344 22.148438 0 12.214844-9.9375 22.152344-22.152344 22.152344zm0-30.304688c-4.492187 0-8.148437 3.65625-8.148437 8.152344s3.65625 8.152344 8.148437 8.152344c4.496094 0 8.152344-3.65625 8.152344-8.152344s-3.65625-8.152344-8.152344-8.152344zm0 0"/>*/}
        {/*</svg>*/}
        <div className={'absolute w-full bottom-1 z-50 text-center text-primary-600'}>No Preview</div>
    </div>
}
export default LabelBuildScreenshot;
