import {I18nextProvider, useTranslation} from 'react-i18next';
import i18next from "i18next";
import React, {useEffect} from "react";
import englishTranslations from "./data/en-US.json";
import hebrewTranslations from "./data//he.json";

const LocalizationProvider = ({children}: any) => {

    useEffect(() => {
        (async () => {

            // await sleep(2000);
            await i18next.init({
                interpolation: {escapeValue: false},
                lng: 'en-US',
                defaultNS: "translations",
                resources: {
                    "en-US": {
                        translations: englishTranslations
                    },
                    he: {
                        translations: hebrewTranslations
                    }

                },
            });
            // i18next.changeLanguage('en-US');

            // console.log("load languages")

        })()
    }, []);
    return (
        <I18nextProvider i18n={i18next}>
            {children}
        </I18nextProvider>
    )
}

export {LocalizationProvider}

