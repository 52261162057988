import React, {useEffect, useState} from "react";
import "../style/dev-tools.scss";
import {DevToolBreakPoint} from "./DevToolBreakPoint";
import {DevToolVersionNumber} from "./DevToolVersionNumber";
import {DevToolDialog} from "./DevToolDialog";

interface IProps {
    value: any;
}

const InlineDevTools = ({}) => {

    const [isEnabled, setIsEnabled] = useState(getValueFromStorage())
    useEffect(() => {
        const handleStorageChange = async (e: any) => {
            const isEnabled = getValueFromStorage()
            // console.log("handle storage change", isEnabled);
            setIsEnabled(isEnabled)
        };

        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange)
    }, [])

    return (
        <>
            {isEnabled && <div className={'inlineDevTools'}>
                <DevToolDialog/>
                <DevToolBreakPoint/>
                <DevToolVersionNumber/>
            </div>}
        </>
    )
}

const getValueFromStorage = () => {
    // @ts-ignore
    return JSON.parse(localStorage.getItem("inline_devtools")) || false

}
export {InlineDevTools}
