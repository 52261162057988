import React, {useState, useEffect, useRef} from "react";
import {useForm} from "../../context";
import {TextErrorMessage} from "./TextErrorMessage";
import {AlertError, AlertErrorList} from "front-lib";

interface IProps {
    scrollToView?: boolean;
    className?: string
}

function FormGlobalErrorMessage({scrollToView = false, className}: IProps) {
    const {getError, clearErrors} = useForm();

    const error = getError("global");
    const ref = useRef(null);

    const hasError = Boolean(error);

    useEffect(() => {
        if (hasError && scrollToView) {
            // @ts-ignore
            ref.current.scrollIntoView();
        }
    }, [hasError, scrollToView]);

    const withDetails = Boolean(error && error.details) && error.details.length > 1;
    // console.log("withDetails", withDetails, error)
    return (
        <div ref={ref}>
            {
                hasError && <div className={className}>
                    {!withDetails && <AlertError>
                        <TextErrorMessage error={error}/>
                    </AlertError>}
                    {withDetails && <AlertErrorList errors={error.details}/>}
                </div>
            }
        </div>
    );
}

export {FormGlobalErrorMessage};
