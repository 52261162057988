import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import WorkspaceContextProvider from "../../../modules/app/workspace/context/WorkspaceContextProvider";
import WorkspaceView from "../../../modules/app/workspace/WorkspaceView";


const MyWorkspaceRoute = ({}) => {

    useEffect(() => {

    }, []);

    return (
        <>
            <Helmet>
                <title>Workspace</title>
            </Helmet>
            {/*<WorkspaceContextProvider>*/}
                    <div className={'mx-auto max-w-screen-xl'}>
                        {/*<header>*/}
                        {/*    <div className="mx-auto max-w-7xl py-6 px-4 flex justify-between">*/}
                        {/*        <h1 className="text-3xl font-bold tracking-tight text-gray-950 dark:text-white">Home</h1>*/}
                        {/*    </div>*/}
                        {/*</header>*/}

                        <main className={'pt-4'}>
                            <div className={'bg-gray-100 dark:bg-gray-800 px-4'}>
                                <WorkspaceView/>
                            </div>
                        </main>
                    </div>
            {/*</WorkspaceContextProvider>*/}
        </>
    )
}

export default MyWorkspaceRoute
