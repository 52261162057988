import React, {useState} from "react";
import {ImEarth} from "react-icons/all";
import {Link} from "react-router-dom";
import {useEnvironmentLoader} from "../../env-loader/context/useEnvironmentLoader";

const Browser = ({title, url, children}: any) => {

    const {goToEnvRootUrl} = useEnvironmentLoader();

    const reloadBrowser = () => {
        console.log("reloadBrowser");
        goToEnvRootUrl();
    }
    return (
        <div
            className="browser relative group flex h-full w-full flex-col overflow-hidden rounded-lg border border-gray-700  dark:border-gray-800  shadow-xl ">
            <div className="flex flex-col bg-gray-950">
                <div className="flex px-1.5">
                    <div className="flex items-center space-x-1 px-2.5">
                        <div className="h-2.5 w-2.5 rounded-full bg-gray-600"/>
                        <div className="h-2.5 w-2.5 rounded-full bg-gray-600"/>
                        <div className="h-2.5 w-2.5 rounded-full bg-gray-600"/>
                    </div>
                    <div className="flex min-w-0 flex-1">
                        <button
                            className="peer relative mt-1.5 flex items-center space-x-1.5 rounded-t-md py-2 px-3 text-xs font-medium sm:min-w-[10rem] z-10 bg-gray-800 text-gray-400 hover:cursor-default">
                            <ImEarth/>
                            <span className="truncate">{title}</span></button>
                        {/*<button*/}
                        {/*    className="peer relative mt-1.5 flex items-center space-x-1.5 rounded-t-md py-2 px-3 text-xs font-medium sm:min-w-[10rem] min-w-0 flex-1 bg-gray-700/0 text-gray-500 transition-all after:absolute after:-left-px after:block after:h-5 after:w-px after:bg-gray-700 after:transition-all first:after:hidden hover:bg-gray-700/25 hover:text-gray-400 sm:flex-initial sm:hover:after:opacity-0 sm:peer-hover:after:opacity-0"*/}
                        {/*    id="headlessui-tabs-tab-4" role="tab" aria-selected="false" tabIndex={-1} type="button">*/}
                        {/*    <FaTwitch/>*/}
                        {/*    <span className="truncate">ironmouse</span></button>*/}

                    </div>
                </div>
            </div>
            <div className="flex flex-1 flex-col">
                <div
                    className="flex items-center space-x-1.5 border-b border-gray-700 dark:border-gray-800  bg-gray-800 py-1 px-1.5 sm:space-x-2.5 sm:py-1.5 sm:px-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2}
                         stroke="currentColor" aria-hidden="true" className="h-3 w-3 text-gray-600 sm:h-4 sm:w-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10 19l-7-7m0 0l7-7m-7 7h18"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2}
                         stroke="currentColor" aria-hidden="true" className="h-3 w-3 text-gray-600 sm:h-4 sm:w-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3"/>
                    </svg>
                    <button className="hover:cursor-default reload" onClick={reloadBrowser}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2}
                             stroke="currentColor" aria-hidden="true"
                             className="hidden text-gray-600 sm:block sm:h-4 sm:w-4">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"/>
                        </svg>
                    </button>
                    <Link to={url}
                          className="flex flex-1 items-center rounded bg-gray-950 py-1 px-2 text-xs text-gray-300 sm:text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2}
                             stroke="currentColor" aria-hidden="true" className="mr-1.5 h-3 w-3">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"/>
                        </svg>
                        <span className="text-gray-400">{url}</span>
                    </Link>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2}
                         stroke="currentColor" aria-hidden="true" className="h-3 w-3 text-gray-600 sm:h-4 sm:w-4">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"/>
                    </svg>
                </div>

                <div className={'w-full'}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Browser