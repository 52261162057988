import React from "react";
import FaqOpenList from "./FaqOpenList";


const data = [
    {
        title: "What is frontenvs?",
        content: <>
            Frontenvs is a platform designed to create fully functional snapshots of your current site state, serving as
            a valuable post CI/CD tool. It empowers users to generate functional, isolated environments by scraping a given site.
        </>
    },
    {
        title: "How does frontenvs work?",
        content: <div>
            Frontenvs operates by harnessing its capabilities to capture a snapshot of your current site. It seamlessly
            integrates into CI/CD pipelines, enabling the creation of functional, independent environments.
        </div>,
    },

    {
        title: "Is frontenvs free to use?",
        content: <div>

            frontenvs is currently available for free usage. we also working on a paid plans with enhanced features.

            {/*To access the platform, simply provide your OpenAI API token,*/}
            {/*which will be used for all requests associated with your account. For details regarding OpenAI pricing*/}
            {/*plans,&nbsp;*/}
            {/*<a target={"_blank"} href={'https://openai.com/pricing'}*/}
            {/*   className={'underline'}>click here</a>.*/}
        </div>,
    },
    {
        title: "What type of application you support?",
        content: <div>
            At the moment, our free plan support only public websites.
            <br/>
            We test our product on React, Angular and Vue, but even older frameworks such as JQuery
            works as well.
        </div>,
    },
];


const HomeFaqSection = ({}) => {
    return (
        <section className={'bg-gray-100'}>

            <div className={"max-w-screen-md lg:max-w-screen-xl mx-auto py-[100px] px-6 "}>
                {/*<Faq*/}
                {/*    data={data}*/}
                {/*/>*/}
                <FaqOpenList data={data}/>
                {/*<p className="text-center mt-2 text-gray-500">Can't find the answer you're looking for? Don't be a stranger!<br/>*/}
                {/*    Come find us on <a target="_blank" href="https://discord.gg/5HPyCaF742" rel="noreferrer"*/}
                {/*                       className={'text-discord'}>Discord</a>.*/}
                {/*</p>*/}
            </div>
        </section>
    )
}

export default HomeFaqSection