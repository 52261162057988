import React from 'react';
import {Link} from "react-router-dom";
import {IconLogo} from "front-lib";

const BrandName = ({className, to = "/"}: any) => {
    return (
        <Link to={to} className={`flex items-center ${className}`}>
            {/*<img src={logo} className=" h-8"*/}
            {/*     alt="Logo"/>*/}
            <IconLogo className={'w-11 h-11 -ml-1 mr-1 fill-primary-600 dark:fill-primary-400 icon-logo'}/>
            <span
                className="hidden sm:inline brand-name self-center text-2xl whitespace-nowrap text-primary-600 dark:text-primary-400">
                Frontenvs
            </span>
        </Link>
    )
}

export default BrandName