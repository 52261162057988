import React from 'react';
import { useUser } from '../hooks';

interface IProps {
  children: JSX.Element;
}

const VisibleIfNotAuthenticated = ({ children }: IProps) => {
  const { user } = useUser();

  return <>{!Boolean(user) && children}</>;
};

export {VisibleIfNotAuthenticated};
