import React, {useState} from "react";
import {MdDarkMode, MdLightMode} from "react-icons/md";
import {useTheme} from "./hooks/useTheme";

const DarkModePicker = ({onChange}: any) => {
    const {isDarkMode, setDarkMode} = useTheme();
    return (
        <>
            {/*<MdDarkMode className={'w-8 h-8'}/>*/}
            <button id="theme-toggle" type="button"
                    onClick={() => {
                        setDarkMode(!isDarkMode)
                    }}
                    className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5">
                {isDarkMode && <MdDarkMode className={'w-6 h-6'}/>}
                {!isDarkMode && <MdLightMode className={'w-6 h-6'}/>}
            </button>
        </>
    )
}

export {DarkModePicker}