import React from "react";
import {Helmet} from "react-helmet-async";
import {AlertModern, RegisterForm} from "front-lib";
import {useSearchParams} from "react-router-dom";

const RegisterRoute = () => {
    const [searchParams] = useSearchParams();
    const url = searchParams.get("url");
    const returnURL = (() => {
        if (url) {
            return `/app/start?url=${encodeURIComponent(url)}`
        }
        return "/app"
    })();
    return (
        <>
            <Helmet>
                <title>Register</title>
            </Helmet>
            <section>
                {url && <div>
                    <div className={'py-2'}>
                        <AlertModern text={'Please register in order to get access to your new environment'}
                                     badge={url}/>
                    </div>
                </div>}
                <RegisterForm returnURL={returnURL}/>
            </section>
        </>
    );
};

export default RegisterRoute;
