import React, {useState} from "react"
import {AlertModern, IconLogo, useUser} from "front-lib";

const MyFirstEnvBanner = () => {
    // const {user} = useUser();

    const [isOpen, setIsOpen] = useState(false);
    const closeBanner = () => {
        setIsOpen(false)
    }
    return (
        <>
            {isOpen &&
                <AlertModern text={'Welcome to your first environment'}
                             badge={"Learn More"}/>

            }
        </>
    )
}

export {MyFirstEnvBanner}