import React from "react"
import {FaLink} from "react-icons/fa";
import {
    AlertInfo, ButtonFormSubmit,
    FormFieldErrorMessage,
    FormGlobalErrorMessage, FormGlobalSuccessMessage,
    FormInputText,
    FormSwitch,
    isValidUrl,
    ValidationError
} from "front-lib";
import {useSearchParams} from "react-router-dom";

const StepCreateEnvironment = ({onSubmit}: any) => {
    const [searchParams] = useSearchParams();
    const rootUrl = searchParams.get("url");
    const env: any = {
        rootUrl,
        name: extractDomainName(rootUrl || ""),
        isAuthRequired: false
    };

    return (
        <>
            <div className={'wizard-step-content'}>
                <div className={'grid grid-cols-12 gap-6'}>
                    <div className="col-span-7">
                        <FormInputText label={"Root URL"} name={"rootUrl"}
                                       placeholder={"You application root URL"}
                                       rules={{
                                           required: true,
                                           validate: (value: string) => {
                                               const isValid = isValidUrl(value)
                                               return isValid || ValidationError.ERROR_NOT_VALID_URL
                                           }
                                       }}
                                       defaultValue={env.rootUrl || ''}/>


                    </div>
                    <div className="col-span-7">
                        <FormInputText label={"Environment Name"} name={"name"}
                                       rules={{required: true}}
                                       defaultValue={env.name || ''}/>
                    </div>
                    <div className="col-span-7">
                        <FormSwitch label={"Authentication is required"}
                                    name={"isAuthRequired"}
                                    defaultValue={env.isAuthRequired}
                        />
                        {/*<AlertInfo showClose={false}>*/}
                        {/*    <>*/}
                        {/*        Use this option if you need to configure credentials*/}
                        {/*    </>*/}
                        {/*</AlertInfo>*/}
                    </div>

                    <div className="col-span-7">
                        <FormGlobalErrorMessage/>
                        <FormGlobalSuccessMessage/>
                    </div>

                </div>
            </div>
            <div
                className="flex items-center justify-between p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                <div className={'flex items-center space-x-2'}>
                    <ButtonFormSubmit text={"Next"} onSubmit={onSubmit}/>
                </div>
            </div>
        </>
    )
}

function extractDomainName(url: string) {
    try {
        const u = new URL(url);
        const domain = u.hostname.split(".")[0];
        return domain;
    } catch (err: any) {
        return ""
    }

}

export {StepCreateEnvironment}