import React from "react";
import UserTable from "../../../../modules/admin/crud/entities/user/UserTable";
import {Helmet} from "react-helmet-async";

const UsersRoute = ({}) => {
    return (
        <>
            <Helmet>
                <title>Users</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl py-5'}>
                {/*<div className={''}>*/}
                {/*    <Breadcrumb/>*/}
                {/*</div>*/}
                <div className={''}>
                    <UserTable/>
                </div>
            </div>
        </>
    )
}

export default UsersRoute