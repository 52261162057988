import React from "react"
import {DashboardStats} from "./DashboardStats";
import EnvironmentsTable from "../workspace/environments/EnvironmentsTable";
import WorkspaceUsersTable from "../workspace/users/WorkspaceUsersTable";
import WorkspaceLatestBuilds from "../workspace/environments/WorkspaceLatestBuilds";

const Dashboard = () => {
    return (
        <>
            <div className="mx-auto max-w-screen-xl w-full pb-20">
                <div className="grid grid-cols-12 gap-3">
                    <div className={'col-span-12'}>
                        <header className="">
                            <div className="mx-auto pt-4">
                                <h1 className="text-3xl font-bold tracking-tight text-gray-950 dark:text-white">
                                    Dashboard</h1>
                            </div>
                        </header>
                    </div>
                    <div className={'col-span-12'}>
                        <DashboardStats/>
                    </div>
                    <div className={'col-span-12 sm:col-span-8'}>
                        <EnvironmentsTable/>
                    </div>
                    <div className={'col-span-12 sm:col-span-4'}>
                        <WorkspaceLatestBuilds/>
                    </div>
                    <div className={'col-span-12'}>
                        <WorkspaceUsersTable/>
                    </div>
                </div>

            </div>
        </>
    )
}

export {Dashboard}