import React from "react";

const Pagination = ({count, page , pageSize , className}: any) => {
    return (
        <>
            <div className={`${className} h-[75px] flex items-center justify-between border-t border-gray-200 dark:border-gray-800 bg-white dark:bg-gray-950 px-4 py-3 sm:px-6`}>
                {/*<div className="flex flex-1 justify-between sm:hidden">*/}
                {/*    <a href="#"*/}
                {/*       className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Previous</a>*/}
                {/*    <a href="#"*/}
                {/*       className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Next</a>*/}
                {/*</div>*/}
                <div className="flex sm:flex-1 sm:items-center sm:justify-between">
                    <div>
                        <p className="text-sm text-gray-700 dark:text-gray-300">
                            Showing&nbsp;
                            {/*<span className="font-medium">1</span>&nbsp;to&nbsp;*/}
                            <span className="font-medium">{count}</span> results
                            {/*&nbsp;of&nbsp;*/}
                            {/*<span className="font-medium">{count}</span>&nbsp;results*/}
                        </p>
                    </div>
                    {/*<div>*/}
                    {/*    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm"*/}
                    {/*         aria-label="Pagination">*/}
                    {/*        <span*/}
                    {/*           className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 cursor-pointer">*/}
                    {/*            <span className="sr-only">Previous</span>*/}
                    {/*            <svg className="h-5 w-5" x-description="Heroicon name: mini/chevron-left"*/}
                    {/*                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"*/}
                    {/*                 aria-hidden="true">*/}
                    {/*                <path fillRule="evenodd"*/}
                    {/*                      d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"*/}
                    {/*                      clipRule="evenodd"/>*/}
                    {/*            </svg>*/}
                    {/*        </span>*/}
                    {/*        /!*<a href="#" aria-current="page"*!/*/}
                    {/*        /!*   className="relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20">1</a>*!/*/}
                    {/*        /!*<a href="#"*!/*/}
                    {/*        /!*   className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20">2</a>*!/*/}
                    {/*        /!*<a href="#"*!/*/}
                    {/*        /!*   className="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex">3</a>*!/*/}
                    {/*        /!*<span*!/*/}
                    {/*        /!*    className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">...</span>*!/*/}
                    {/*        /!*<a href="#"*!/*/}
                    {/*        /!*   className="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 md:inline-flex">8</a>*!/*/}
                    {/*        /!*<a href="#"*!/*/}
                    {/*        /!*   className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20">9</a>*!/*/}
                    {/*        /!*<a href="#"*!/*/}
                    {/*        /!*   className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20">10</a>*!/*/}
                    {/*        <span*/}
                    {/*           className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 cursor-pointer">*/}
                    {/*            <span className="sr-only">Next</span>*/}
                    {/*            <svg className="h-5 w-5" x-description="Heroicon name: mini/chevron-right"*/}
                    {/*                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"*/}
                    {/*                 aria-hidden="true">*/}
                    {/*                <path fillRule="evenodd"*/}
                    {/*                      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"*/}
                    {/*                      clipRule="evenodd"></path>*/}
                    {/*            </svg>*/}
                    {/*        </span>*/}
                    {/*    </nav>*/}
                    {/*</div>*/}
                </div>
            </div>
        </>
    )
}

export default Pagination