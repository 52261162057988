import React, {ReactNode} from "react";
interface ITabPanelProps {
    children: ReactNode;
    value: number;
    isActive: boolean;
    className?: string;
}

// The actual content under each Tab.
const TabPanel = ({children, value, isActive, className}: ITabPanelProps) => {
    return (
        <div
            className={`${className} ${isActive ? '' : 'hidden'}`}

            id={`tabpanel-${value}`}
            aria-labelledby={`tab-${value}`}
        >
            <div>{children}</div>
            {/*{isActive && <div>{children}</div>}*/}
        </div>
    );
};

export {TabPanel};
