export enum ValidationError {
    ERROR_MIN_PASSWORD_LENGTH = "ERROR_MIN_PASSWORD_LENGTH",
    ERROR_PASSWORD_NOT_MATCH = "ERROR_PASSWORD_NOT_MATCH",
    ERROR_NOT_VALID_EMAIL = "ERROR_NOT_VALID_EMAIL",
    ERROR_NOT_VALID_URL = "ERROR_NOT_VALID_URL",
    ERROR_NOT_VALID_EMAIL_LIST = "ERROR_NOT_VALID_EMAIL_LIST",
    ERROR_NO_SPACES_ALLOWED = "ERROR_NO_SPACES_ALLOWED",
    ERROR_ILLEGAL_LETTERS = "ERROR_ILLEGAL_LETTERS",
    ERROR_NOT_VALID_PHONE = "ERROR_NOT_VALID_PHONE",
    ERROR_IS_EMPTY_STRING = "ERROR_IS_EMPTY_STRING",
    ERROR_MIN_DONATION_SIZE = "ERROR_MIN_DONATION_SIZE",
    ERROR_DISPLAY_NAME_NOT_MATCH = "ERROR_DISPLAY_NAME_NOT_MATCH",
    ERROR_INTRO_MAX_LENGTH = "ERROR_INTRO_MAX_LENGTH"
}
