import React, {useEffect, useState} from "react";
import {
    ButtonFormSubmit,
    FormGlobalErrorMessage,
    FormGlobalSuccessMessage,
    FormInputText,
    FormSwitch,
    LoadingCover, withForm
} from "front-lib";
import {useSystemConfigsApi} from "../api/useSystemConfigsApi";

const SystemConfigsForm = withForm(({}) => {
    const [systemConfigs, setSystemConfigs] = useState<any>(null);
    const {fetchSystemConfigs, saveSystemConfigs} = useSystemConfigsApi();

    useEffect(() => {
        (async () => {
            const systemConfigs = await fetchSystemConfigs();
            setSystemConfigs(systemConfigs);
        })()
    }, [])
    const onSubmit = async ({dirtyValues}: any) => {
        console.log("dirtyValues", dirtyValues)
        // await sleep(20000)
        await saveSystemConfigs(dirtyValues);

        // // if (minimumRole && user.role < minimumRole)
        // //   throw new Error('Insufficient permissions to perform this action');

        // throw new Error("bad login stuff")
        return "System configs saved successfully."


    }
    console.log("systemConfigs", systemConfigs)
    // if (!systemConfigs) return <></>;
    return (
        <>
            <LoadingCover isLoading={!systemConfigs} className={'flex items-center h-[300px]'}>
                {() => {
                    return <>
                        <div className="card">

                            <FormInputText label={'Minimum Version'} name={"minimumVersion"}
                                           defaultValue={systemConfigs.minimumVersion}
                                           rules={{
                                               required: true,
                                           }}
                            />
                            <FormSwitch label={"Maintenance"}
                                        name={"isMaintenance"}
                                        defaultValue={systemConfigs.isMaintenance}/>

                            <FormGlobalErrorMessage/>
                            <FormGlobalSuccessMessage/>
                        </div>
                        <div className="bg-gray-50 dark:bg-gray-900 px-4 py-3 text-right sm:px-6">
                            {/*<ButtonFormSubmit/>*/}
                            <ButtonFormSubmit onSubmit={onSubmit}/>
                        </div>
                    </>
                }}
            </LoadingCover>

        </>
    )
})

export {SystemConfigsForm}