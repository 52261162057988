import React from "react";
import {FaDiscord, FaLinkedin,} from "react-icons/all";
import {FaSlack} from "react-icons/fa";

const SocialIcons = ({className}: any) => {
    return (
        <>
            <ul className={`flex justify-center space-x-5 ${className}`}>


                <li className={'mb-0'}>
                    <a href="https://discord.gg/J96zSQX7jU"
                       target={"_blank"}
                       className="hover:text-gray-300 dark:hover:text-primary-300 dark:text-gray-400">
                        <FaDiscord className="w-5 h-5"/>
                    </a>
                </li>

                <li className={'mb-0'}>
                    <a href="https://join.slack.com/t/frontenvs/shared_invite/zt-2dkqer0ls-KGAA8_WwcIcTyP6OR0iFFw"
                       target={"_blank"}
                       className="hover:text-gray-300 dark:hover:text-primary-300 dark:text-gray-400">
                        <FaSlack className="w-5 h-5"/>
                    </a>
                </li>
                <li className={'mb-0'}>
                    <a href="https://www.linkedin.com/company/frontenvs"
                       target={"_blank"}
                       className="hover:text-gray-300 dark:hover:text-primary-300 dark:text-gray-400">
                        <FaLinkedin className="w-5 h-5"/>
                    </a>
                </li>

            </ul>


        </>
    )
}

export default SocialIcons