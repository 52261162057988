import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import GuestTable from "../../../../modules/admin/crud/entities/guest/GuestTable";


const GuestsRoute = ({}) => {

    useEffect(() => {

    }, []);

    return (
        <>
            <Helmet>
                <title>Guests</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl py-5'}>
                <div className={''}>
                    <GuestTable/>
                </div>
            </div>
        </>
    )
}

export default GuestsRoute
