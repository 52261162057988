import React from "react";
import {MdOutlineInfo} from "react-icons/md";
import {IUser} from "front-lib";

interface IProps {
    user: IUser
}

const ProfileSocialConnectionsForm = ({user}: IProps) => {

    return (
        <>
            {Boolean(user.twitchId) && <div className="bg-twitch text-white rounded-b  px-4 py-3 shadow-md"
                                            role="alert">
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <MdOutlineInfo className="fill-current h-6 w-6 text-white mr-4"/>
                        <div className="font-sm m-0">Twitch</div>
                    </div>
                    {/*<div className={'flex '}>*/}
                    {/*    <button type="button"*/}
                    {/*            onClick={(e) => {*/}
                    {/*                // setIsVisible(false)*/}
                    {/*            }}*/}
                    {/*            className="cursor-pointer p-2 my-[2px] -mr-[9px] text-gray-500 rounded-lg hover:text-gray-950 hover:bg-teal-200 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-0 dark:focus:ring-gray-600">*/}
                    {/*        <MdOutlineClose className={'w-5 h-5'}/>*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                    {user.twitchId && <span>Connected</span>}

                </div>

            </div>}

            {Boolean(user.discordId) && <div className="bg-discord rounded-b text-white px-4 py-3 shadow-md mt-4"
                                             role="alert">
                <div className="flex justify-between items-center">
                    <div className="flex items-center">

                        <MdOutlineInfo className="fill-current h-6 w-6 text-white mr-4"/>
                        <div className="font-sm m-0">Discord</div>

                    </div>
                    {user.discordId && <span>Connected</span>}
                    {/*<div className={'flex '}>*/}
                    {/*    <button type="button"*/}
                    {/*            onClick={(e) => {*/}
                    {/*                // setIsVisible(false)*/}
                    {/*            }}*/}
                    {/*            className="cursor-pointer p-2 my-[2px] -mr-[9px] text-gray-500 rounded-lg hover:text-gray-950 hover:bg-teal-200 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-0 dark:focus:ring-gray-600">*/}
                    {/*        <MdOutlineClose className={'w-5 h-5'}/>*/}
                    {/*    </button>*/}
                    {/*</div>*/}

                </div>
            </div>}
        </>
    )
}

export {ProfileSocialConnectionsForm}