import {api, executeCaptcha} from "front-lib"

export class FileUploadClient {
    url: string;

    constructor(url: string) {
        this.url = url;
    }

    async upload(file: any, onProgress?: (progress: number) => void, params?: any): Promise<string> {

        // console.log("sending file payload to server", file);
        // const captchaToken = await executeCaptcha("upload_file");

        const formData = new FormData()
        formData.append('file', file)
        // @ts-ignore
        // formData.append('sheker', 2)

        const response = await api.post(this.url, formData, {
            params,
            headers: {
                // "Captcha-Token": captchaToken,
                'Content-Type': 'multipart/form-data',
            },

            transformRequest: formData => formData,
            onUploadProgress: (progress) => {
                const {total = 0, loaded} = progress;
                const totalSizeInMB = total / 1000000;
                const loadedSizeInMB = loaded / 1000000;
                const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
                onProgress && onProgress(uploadPercentage)
                console.log("uploadPercentage", uploadPercentage);
            },
        })
        const uploadResponseData = response.data;
        // console.log("upload ended", fileName)


        // if (fileName)
        //     throw new Error("BAD_UPLOAD");

        return uploadResponseData;
    }

}



