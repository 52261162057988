import React from "react";
import {IColumn, useGenericTable} from "./context/GenericTableContext";
import "./style/generic-table.scss";
import {FaArrowDown, FaArrowUp} from "react-icons/all";

interface IProps {
    onSelectAllClick: (event: any) => void,
}

const DynamicHead = ({onSelectAllClick}: IProps) => {

    const {
        visibleColumns,
        isLoading,
        rows,
        selectedRows,
        onRowClick
    } = useGenericTable();

    const rowCount = (rows || []).length;
    const numSelected = selectedRows.length;
    const countOfItemsThatCanBeSelected = (rows || []).filter((r: any) => !r.isDeleted).length;
    return (
        <thead className={'sticky top-[0px]'}>
        {/*<TableRow className={style.loaderLineStickyCover}>*/}
        {/*    <TableCell colSpan={visibleColumns.length + 1}>*/}

        {/*    </TableCell>*/}
        {/*</TableRow>*/}
        <tr className={'dynamicHeader'}>

            {visibleColumns.map((column: IColumn) => (
                <SortHeader key={column.property} column={column}/>
            ))}
        </tr>
        {/*<tr className={clsx('loaderLine', "generic-table-loader")}>*/}
        {/*    <th colSpan={visibleColumns.length + 1}>*/}
        {/*        <LoadingLine isLoading={isLoading}/>*/}
        {/*    </th>*/}
        {/*</tr>*/}
        </thead>
    );
};

interface ISortHeaderProps {
    column: IColumn;
}

const SortHeader = ({column}: ISortHeaderProps) => {

    const {disableSorting, property, label} = column;
    const {
        orderBy,
        orderDirection,
        onSortChange
    } = useGenericTable();


    return <th
        className={'px-6 th-primary'}
        // sortDirection={orderBy === property ? orderDirection : false}
    >
        {!disableSorting && <div
            className={'flex items-center cursor-pointer'}

            // active={orderBy === property}
            // direction={orderBy === property ? orderDirection : "asc"}
            onClick={() => {
                onSortChange(property);
            }}
        >
            <>
                {label}
                {orderBy === property ? (
                    <span className={'ml-2'}>


                        {orderDirection === "desc" ? <FaArrowUp/> : <FaArrowDown/>}
                    </span>
                ) : null}
            </>
        </div>}
        {disableSorting && <>
            {label}
        </>}

    </th>;
};
export default DynamicHead;
