import React from "react";
import {Helmet} from "react-helmet-async";
import WorkspaceTable from "../../../../../modules/admin/crud/entities/workspace/WorkspaceTable";

const WorkspacesRoute = ({}) => {
    return (
        <>
            <Helmet>
                <title>Workspaces</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl py-5'}>
                {/*<Breadcrumb/>*/}
                {/*<br/>*/}
                {/*<TicketTable/>*/}
                <WorkspaceTable/>
            </div>
        </>
    )
}

export default WorkspacesRoute