import React from "react";
import {Helmet} from "react-helmet-async";
import AuditTable from "../../../../modules/admin/crud/entities/audit/AuditTable";

const AuditLogRoute = ({}) => {
    return (
        <>
            <Helmet>
                <title>Audit Log</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl py-5'}>
                <AuditTable/>
            </div>
        </>
    )
}

export default AuditLogRoute