import React from "react";
import {Avatar , LazyLabelDateTime} from "front-lib";
import {useSocket} from "../context/useSocket";
import { MdLocationSearching} from "react-icons/md";
import {GoDot} from "react-icons/go";

import {DialogManageSocket} from "../dialogs/DialogManageSocket";

const SocketClientsTable = ({clients}: any) => {
    const {socket} = useSocket();
    console.log("clients", clients)
    return (
        <>

            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white dark:bg-gray-950">
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-2 max-w-full flex-grow flex-1"><h3
                            className="font-semibold text-lg text-primary-700 dark:text-primary-500">Sockets ({clients.length})</h3></div>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto">
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                        <tr>
                            <th className="px-6 th-primary">Actions</th>
                            <th className="px-6 th-primary">User</th>
                            <th className="px-6 th-primary">App</th>
                            <th className="px-6 th-primary">Client</th>
                            <th className="px-6 th-primary">Address</th>
                            <th className="px-6 th-primary">Rooms</th>
                            <th className="px-6 th-primary">Issued</th>

                        </tr>
                        </thead>
                        <tbody>
                        {clients && clients.map((client: any) => {
                            const isMe = socket.id === client.id;

                            return <tr key={client.id} className={'hover:bg-gray-50 dark:hover:bg-gray-800'}>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    <DialogManageSocket client={client}/>
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {/*<span className={'font-bold'}>{room.name}</span>*/}
                                    <div className={'flex items-center'}>
                                        <div className={'h-11 w-11 relative'}>
                                            <Avatar url={client.user.profile.avatar}/>
                                            {isMe && <div
                                                className="inline-flex absolute -top-2 -right-2 justify-center items-center w-6 h-6 text-xs font-bold text-white bg-primary-500 rounded-full border-2 border-white dark:border-gray-950">
                                                <MdLocationSearching/>
                                            </div>}
                                        </div>
                                        <span
                                            className={'ml-2'}>
                                            {/*{client.user.profile.firstname} {client.userser.profile.lastname}*/}
                                            {client.user.profile.username}
                                        </span>
                                    </div>
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    <div>name: <span className={'font-semibold'}>{client.storage.name}</span></div>
                                    <div>source: {client.storage.source}</div>
                                    <div>version: {client.storage.version}</div>
                                    <div>
                                        mode: {client.storage.mode}
                                    </div>
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    {client.id}
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    <div>{client.origin}</div>
                                    <div>
                                        {client.address}
                                    </div>
                                </td>

                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    <ul>
                                        {client.rooms.map((r: any) => <li key={`${client.id}=${r}`}
                                                                          className={'mb-1 flex items-center'}>
                                            <GoDot size={8} className={'mt-1 mr-1'}/>
                                            {r}
                                        </li>)}
                                    </ul>
                                </td>

                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                    <LazyLabelDateTime timestamp={client.issued}/>
                                </td>
                                {/*<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">*/}
                                {/*    <div className="flex items-center"><span className="mr-2">${room.capacity}%</span>*/}
                                {/*        <div className="relative w-full">*/}
                                {/*            <div className="overflow-hidden h-2 text-xs flex rounded bg-red-200">*/}
                                {/*                <div*/}
                                {/*                    className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500"*/}
                                {/*                    style={{width: `${room.capacity}%`}}/>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</td>*/}

                            </tr>
                        })}

                        </tbody>
                    </table>
                </div>
            </div>

        </>
    )
}

export   {SocketClientsTable}