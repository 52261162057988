import React from 'react'
//https://developers.google.com/recaptcha/docs/faq
//https://github.com/AurityLab/recaptcha-v3
const ProtectedByReCaptchaText = ({className = "text-center"}: any) => {

    return (
        <div className={`text-sm text-gray-400  px-6 ${className} mt-2`}>
            This site is protected by reCAPTCHA and the Google&nbsp;<a href="https://policies.google.com/privacy"
                                                                       target={"_blank"} className={'text-gray-600'}>Privacy
            Policy</a> and&nbsp;<a href="https://policies.google.com/terms" target={"_blank"}
                                   className={'text-gray-600'}>Terms of
            Service</a> apply.
        </div>
    )
}

export {ProtectedByReCaptchaText}