import React from "react";
import {flowFilters} from "./FlowFilters";
import {flowColumns} from "./FlowColumns";
import GenericTable from "../../table/GenericTable";
import {useFlowCrudApi} from "./api/useFlowCrudApi";

const FlowTable = ({withHeader = true}: any) => {
    const crudApi = useFlowCrudApi();

    return (
        <>
            <GenericTable renderHeader={withHeader && <>
                <div className={'flex items-center'}>
                    <h3 className="font-semibold text-lg dark:text-white">Flows</h3>

                </div>
            </>}
                          crudApi={crudApi}
                          filters={flowFilters}
                          columns={flowColumns}
            />
        </>
    );
};

export default FlowTable;
