import React, { useEffect } from "react";
import {FormSelect , Role} from "front-lib";

interface IProps {
    // name?: string;
    defaultValue?: Role;
    readOnly? : boolean
    rules? : any
}

const FormSelectRole = ({ defaultValue ,readOnly, ...props }: IProps) => {

    return (
        <>
            <FormSelect label={"Role"} name={"role"} options={roleOptions} defaultValue={defaultValue} readOnly={readOnly} {...props} />
        </>
    );
};



export const roleOptions = (() => {
    const items = [];
    for (const type in Role) {
        const isValueProperty = parseInt(type, 10) >= 0;
        if (isValueProperty) {
            items.push({
                value: type,
                name: Role[type]
            });
        }
    }
    return items;
})();
export default FormSelectRole;
