import React, {useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {LoadingStripes, ErrorBrokenHeart, AlertError, useAuthContext, useReferral} from "front-lib";

const SocialAuthCallbackRoute = () => {
    const {loadMyProfileAndSaveToContextState} = useAuthContext();

    const navigate = useNavigate()
    // const [search] = useSearchParams();
    // const returnUrl = useReturnUrl();
    const {markAsReferred} = useReferral();
    // const provider = search.get('provider')
    const [error, setError] = useState<any>(null);

    React.useEffect(() => {
        (async () => {
            try {
                await loadMyProfileAndSaveToContextState();
                const referralId = localStorage.getItem("r")
                if (referralId) {
                    console.log("should mark as referred")
                    markAsReferred(referralId);
                    localStorage.removeItem("r")
                }
                const from = localStorage.getItem("f")
                if (from) {
                    const arr = from.split("/");
                    const referralId = arr[arr.length - 1];
                    console.log("should redirect to ", referralId, from)
                    markAsReferred(referralId);
                    localStorage.removeItem("f")
                    navigate(from);
                } else {
                    navigate("/app");
                }
                // console.log("hello", user)
                // if (provider) {
                //     gaEvents.trackSocialLogin(provider)
                // }

                // throw new Error("bad oauth login")

            } catch (e) {
                console.error(e);
                setError(e);
                throw e;
                // navigate('/login')
            }
        })()

    }, []);


    return (
        <div className={'flex justify-center items-center mt-[75px]'}>
            {!error && <LoadingStripes/>}
            {error && <div className={'flex center items-center flex-col'}>
                <ErrorBrokenHeart/>
                <AlertError showClose={false}>
                    {error.message || "Something went wrong"}
                </AlertError>
            </div>}
        </div>
    );
};

export default SocialAuthCallbackRoute;
