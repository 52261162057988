import React from "react"
import {DialogBasic} from "front-lib";
import {CreateEnvironmentWizard} from "../wizards/CreateEnvironmentWizard";
import {useWorkspace} from "../../context/useWorkspace";

const DialogCreateEnvironment = ({onCreated}: any) => {
    // const {loadEnvsAndBuildsData} = useWorkspace();
    return (
        <>
            <DialogBasic
                title={"Create Environment"}
                renderTrigger={<button
                    className={`text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}>
                    Create Environment
                </button>}
                renderContent={({toggleOpen}: any) => {
                    return <>
                        <CreateEnvironmentWizard onCompleted={async () => {
                            // await loadEnvsAndBuildsData();
                            toggleOpen()
                        }}/>
                    </>
                }}
            />
        </>
    )
}

export default DialogCreateEnvironment