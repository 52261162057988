import React from "react";
import {useForm} from "../context/useForm";
import {LoadingStripes} from "front-lib";
const FormLoadingSpinner = ({}) => {
    const {isSubmitting} = useForm();
    return (
        <>
            {isSubmitting && <LoadingStripes text={""} className={'small white'}/>}
        </>
    )
}

export {FormLoadingSpinner}