import React, {useState} from "react";
import {MdOutlineClose, MdOutlineInfo} from "react-icons/md";

const AlertInfo = ({children, showClose = true, className}: any) => {
    const [isVisible, setIsVisible] = useState(true);

    return (
        <>
            {isVisible &&
                <div
                    className={`${className} bg-gray-100 border-t-4 border-gray-500 rounded-b text-gray-950 px-4 py-3 shadow-md w-full`}
                    role="alert">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center">
                            <div>
                                <MdOutlineInfo className="fill-current h-6 w-6 text-gray-500 mr-4"/>
                            </div>
                            <div className="font-sm m-0">{children}</div>
                        </div>
                        {showClose && <div className={'flex '}>
                            <button type="button"
                                    onClick={(e) => {
                                        setIsVisible(false)
                                    }}
                                    className="cursor-pointer p-2 my-[2px] -mr-[9px] text-gray-500 rounded-lg hover:text-gray-950 hover:bg-gray-200 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-0 dark:focus:ring-gray-600">
                                <MdOutlineClose className={'w-5 h-5'}/>
                            </button>
                        </div>}

                    </div>
                </div>}
        </>
    )
}

export {AlertInfo}