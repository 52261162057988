import React from "react";
import {Helmet} from "react-helmet-async";
import EnvironmentCrudTable from "../../../../modules/admin/crud/entities/environment/EnvironmentCrudTable";

const EnvironmentsRoute = ({}) => {
    return (
        <>
            <Helmet>
                <title>Environments</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl py-5'}>
                <div className={''}>
                    <EnvironmentCrudTable/>
                </div>
            </div>
        </>
    )
}

export default EnvironmentsRoute