import React from 'react';
import {LabelNull} from "./LabelNull";
import ReactTimeAgo from 'react-time-ago'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json';

TimeAgo.addDefaultLocale(en);

interface IProps {
    timestamp: number | string;
    hideTime?: boolean;
}

const LabelDateTime = ({timestamp, hideTime = false}: IProps) => {
    const date = new Date(timestamp);
    const ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(date);
    const mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(date);
    const da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(date);
    const haveDate = Boolean(timestamp);
    return (
        <>
            {haveDate && <>
                {/*<Tooltip arrow placement={"top"}*/}
                {/*                    title={<div> {`${da}/${mo}/${ye}`} {!hideTime && date.toLocaleTimeString("en", {hourCycle: "h23"})}</div>}>*/}
                <span>
                    <ReactTimeAgo date={date} locale="en-US"/>
                </span>
                {/*</Tooltip>*/}
            </>
            }
            {!haveDate && <LabelNull/>}
        </>
    );
};
export {LabelDateTime}
export default LabelDateTime
