import React from "react";
import {Helmet} from "react-helmet-async";
import {ForgotPasswordForm} from "front-lib";

const ForgotPasswordRoute = ({}) => {
    return (
        <>
            <Helmet>
                <title>Forgot Password</title>
            </Helmet>
            <section>
                <ForgotPasswordForm/>
            </section>

        </>
    )
}

export default ForgotPasswordRoute