import React from "react"
import {LinkPreviewBox} from "frontenvs-lib";
import {useUser} from "front-lib";

const baseURL = import.meta.env.VITE_APP_BACKEND_BASE_URL;

const LabelApiCodeSnippet = ({env, build}: any) => {
    const {user} = useUser();
    const {apiToken} = user;
    const apiURL = `${baseURL}/bob/build/${env.key}`
    const code = `
    curl -X POST -H "API_TOKEN: ${apiToken}" ${apiURL}
    `;

    return (
        <LinkPreviewBox url={code}/>
    )
}

export {LabelApiCodeSnippet}