import React from "react";
import TicketTable from "../../../../modules/admin/crud/entities/ticket/TicketTable";
import {Helmet} from "react-helmet-async";

const TicketsRoute = ({}) => {
    return (
        <>
            <Helmet>
                <title>Tickets</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl py-5'}>
                {/*<Breadcrumb/>*/}
                {/*<br/>*/}
                <TicketTable/>
            </div>
        </>
    )
}

export default TicketsRoute