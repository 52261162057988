import React from "react";
import {guestFilters} from "./GuestFilters";
import {guestColumns} from "./GuestColumns";
import GenericTable from "../../table/GenericTable";
import {useGuestCrudApi} from "./api/useGuestCrudApi";

const GuestTable = ({withHeader = true}: any) => {
    const crudApi = useGuestCrudApi();

    return (
        <>
            <GenericTable renderHeader={withHeader && <>
                <div className={'flex items-center'}>
                    <h3 className="font-semibold text-lg dark:text-white">Guests</h3>

                </div>
            </>}
                          crudApi={crudApi}
                          filters={guestFilters}
                          columns={guestColumns}
            />
        </>
    );
};

export default GuestTable;
