import React from "react";
import {useWorkspace} from "../context/useWorkspace";
import WorkspaceForm from "../forms/WorkspaceForm";
import {DialogBasic, useAuthContext} from "front-lib";


const DialogEditWorkspace = ({workspace, onEdit, onRemove}: any) => {
    const {updateMyProfileAndContextState} = useAuthContext()
    const {editWorkspace} = useWorkspace();
    return (
        <>
            <DialogBasic
                title={"Edit Workspace"}
                renderTrigger={<button
                    className="rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    Edit
                </button>}
                renderContent={({toggleOpen}: any) => {
                    return <>
                        <WorkspaceForm workspace={workspace} onSubmit={async ({dirtyValues}: any) => {
                            console.log("dirtyValues", dirtyValues)
                            const updatedWorkspace = {id: workspace.id, ...dirtyValues};
                            console.log("workspace changed", updatedWorkspace)
                            await editWorkspace(updatedWorkspace);
                            const profile: any = {workspace: {...workspace, ...updatedWorkspace}}
                            await updateMyProfileAndContextState(profile)
                            onEdit && await onEdit();
                            toggleOpen()
                        }} onCancel={toggleOpen}/>
                    </>
                }}
            />
        </>
    )
}

export default DialogEditWorkspace