import React, {useEffect} from "react";
import {FormSwitch, useForm, useFormChange, withForm} from "front-lib";


const DevToolsForm = withForm(({}) => {
    const {handleSubmit} = useForm();

    const defaultData = {
        inlineDevTools: JSON.parse(localStorage.getItem("inline_devtools") as string) || false
    }
    useFormChange(() => {
        console.log("form changed")
        handleSubmit(async ({fieldValues}: any) => {
            console.log("DevToolsForm", fieldValues)

            localStorage.setItem("inline_devtools", fieldValues.inlineDevTools)
            window.dispatchEvent(new Event("storage"));
            // window.location.reload();
        });
    })

    return (
        <>
            <FormSwitch name={'inlineDevTools'} label={"Inline DevTools"} defaultValue={defaultData.inlineDevTools}/>
        </>
    )
})

export {DevToolsForm}