import React from 'react';
import {Helmet} from 'react-helmet-async';
import FeatureListSection from "../../../modules/site/features/FeatureListSection";
import HeroSection from "../../../modules/site/hero/HeroSection";
import MainFeatureSection from "../../../modules/site/features/MainFeatureSection";
import ContactUsSection from "../../../modules/site/contact-us/ContactUsSection";
import HomeFaqSection from "../../../modules/site/faq/HomeFaqSection";
import LastFeatureSection from "../../../modules/site/features/LastFeatureSection";
import VendorListSection from "../../../modules/site/companies/VendorListSection";
import BlogSection from '../../../modules/site/blog/BlogSection';
import PricingSection from '../../../modules/site/pricing/PricingSection';
import ShortFeatureList from "../../../modules/site/features/ShortFeatureList";


function HomeRoute() {
    return (
        <>
            {/*<Helmet>*/}
            {/*    <title>frontenvs - AI-Driven Video Editing</title>*/}
            {/*</Helmet>*/}
            <HeroSection/>

            {/*<MainFeatureSection/>*/}

            {/*<FeatureListSection/>*/}
            {/*<LastFeatureSection/>*/}
            {/*<VendorListSection/>*/}

            {/*<ShortFeatureList/>*/}

            {/*<BlogSection/>*/}
            {/*<PricingSection/>*/}
            <HomeFaqSection/>

            {/*<OurTeamSection/>*/}

            <ContactUsSection/>
            {/*<NewsLetterSection/>*/}

        </>
    );
}

export default HomeRoute;
