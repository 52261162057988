import React from "react";
import {FormSelect} from "front-lib";
import {themeColors} from "./services/ThemeService";

interface IProps {
    // name?: string;
    defaultValue?: any;
    readOnly?: boolean

    onChange?: any
}

const FormSelectThemePaletteColor = ({defaultValue, readOnly, ...props}: IProps) => {
    const onChange = (value: string) => {
        console.log("onChange", value)
    }
    return (
        <>
            <FormSelect label={"Palette Color"} name={"paletteColor"} options={options} defaultValue={defaultValue}
                        onChange={onChange}
                        readOnly={readOnly} {...props} />
        </>
    );
};


const options = (() => {
    const items = themeColors.map((color: string) => {
        return {
            value: color,
            name: color
        }
    })

    return items;
})();
export {FormSelectThemePaletteColor};
