import React from "react";
import {FormSwitch, IProfile, useForm, useFormChange, useTheme, withForm} from "front-lib";


interface IProps {
    profile: IProfile,
    onProfileChanged: (profile: IProfile) => Promise<void>
}

const ProfileThemeForm = withForm(({profile, onProfileChanged}: IProps) => {

    const {handleSubmit} = useForm();
    const {theme, setThemeColor, setDarkMode} = useTheme();


    useFormChange(() => {
        console.log("form changed")
        handleSubmit(async ({dirtyValues}: any) => {
            console.log("dirtyValues", dirtyValues)
            await onProfileChanged(dirtyValues);
        });
    });
    return (
        <>
            <div className="space-y-6 bg-white dark:bg-gray-950  px-4 !py-0 sm:p-6">

                <div className="grid grid-cols-6 gap-6 ">

                    {/*<div className="col-span-6 ">*/}
                    {/*    <LazyFormColorPicker defaultValue={profile.tpc || theme.palette.primary.main} name={"tpc"}*/}
                    {/*                         onChange={(hex: string) => {*/}
                    {/*                             setThemeColor("primary", hex)*/}
                    {/*                         }}*/}
                    {/*                         label={"Primary Color"}/>*/}

                    {/*</div>*/}
                    <div className="col-span-6">
                        <div className={'flex items-center'}>
                            <FormSwitch label={"Dark Mode"}
                                        name={"tdm"}
                                        onChange={(isDark: boolean) => {
                                            console.log("toggle is dark", isDark)
                                            setDarkMode(isDark)
                                        }}
                                        defaultValue={profile.tdm}
                            />
                        </div>

                    </div>
                    <div className="col-span-6">
                    </div>
                </div>


            </div>

        </>
    )
})

export {ProfileThemeForm}