import React from 'react'

const AlertModern = ({text, badge, type}: any) => {
    return (
        <>
            <div className="bg-primary-900 px-4 text-center py-4 lg:px-4">
                <span className={'text-white'}>{text}</span>
                <div
                    className="p-2 ml-2 bg-primary-800 items-center text-white leading-none lg:rounded-full flex lg:inline-flex"
                    role="alert">

                    {/*<span*/}
                    {/*    className="flex rounded-full bg-primary-500 uppercase px-2 py-1 text-xs font-bold mr-3">{type}</span>*/}
                    <span className="font-semibold text-center flex-auto">{badge}</span>
                </div>
            </div>
        </>
    )
}

export {AlertModern}