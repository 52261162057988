import React from "react";
import {IColumn} from "../../table/context/GenericTableContext";
import {Avatar, LazyLabelDateTime} from "front-lib";
import {LabelEnvironmentDomain} from "../../../../app/workspace/environments/labels/LabelEnvironmentDomain";
import LabelBuildScreenshot from "../../../../app/workspace/environments/labels/LabelBuildScreenshot";

export const environmentColumns: IColumn[] = [
    {
        property: "id",
        label: "ID",
        isVisible: false,
        component: ({row, column}) => <>
            {row.id}
        </>
    },
    {
        property: "preview",
        label: "Preview",
        disableSorting: true,
        isVisible: true,
        component: ({row, column}) => <>
            {row.builds[0] &&
                <LabelBuildScreenshot env={row} build={row.builds[0]}/>}
        </>
    },
    {
        property: "root",
        label: "Domain",
        isVisible: true,
        component: ({row, column}) => <>
            <LabelEnvironmentDomain env={row}/>
        </>
    },
    {
        property: "user",
        label: "User",
        isVisible: true,
        component: ({row, column}) => <div className={'flex items-center'}>
            <div className={'h-11 w-11'}>
                <Avatar url={row.profile.avatar}/>
            </div>
            <span className={'ml-2'}>{row.profile.firstname} {row.profile.lastname} </span>

        </div>
    },
    {
        property: "key",
        label: "Key",
        isVisible: true,
        component: ({row, column}) => <>
            {row.key}
        </>
    },
    {
        property: "builds",
        label: "Builds",
        isVisible: true,
        component: ({row, column}) => <>
            {row.builds.length} builds
        </>
    },
    {
        property: "createdAt",
        label: "Created",
        isVisible: true,
        component: ({row, column}) => <>
            <LazyLabelDateTime timestamp={row.createdAt}/>
        </>
    },

];
