import {load, ReCaptchaInstance} from 'recaptcha-v3'
import {IReCaptchaLoaderOptions} from 'recaptcha-v3/dist/ReCaptchaLoader';

export type CaptchaProtectedAction =
    'login'
    | 'register'
    | 'set_password'
    | 'request_password'
    | "confirm_email"
    | "donation_flow_create"
    | "get_public_profile"
    | "upload_file"
    | "get_referral_profile";


let loadRecaptchaPromise: Promise<ReCaptchaInstance>;

const VITE_APP_RECAPTCHA_SITE_KEY = import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY;
export async function initRecaptcha(SITE_KEY?: string, options?: IReCaptchaLoaderOptions) {
    // console.log("initRecaptcha");
    const site_key = SITE_KEY ||  VITE_APP_RECAPTCHA_SITE_KEY;

    //returning a promise into a global variable
    loadRecaptchaPromise = (async () => {
        const recaptcha = await load(site_key, options)
        // await sleep(4000)
        return recaptcha;
    })();

    return loadRecaptchaPromise;
}

export function executeCaptcha(actionName: CaptchaProtectedAction) {
    return new Promise<string>(async (resolve, reject) => {
        try {
            // we wait for the promise but started to load it previously at initRecaptcha - this is a fail-safe in case recaptcha is not loaded yet and been invoked, we will only wait the time left for it to load
            // const start = performance.now();
            const recaptcha = await loadRecaptchaPromise;
            // const end = performance.now();
            // console.log("time to load captcha", end - start)
            const token = await recaptcha.execute(actionName);
            resolve(token);
        } catch (error) {
            reject(error);
        }

    });
}

// function injectReCaptchaScript() {
//     const script = document.createElement('script');
//     script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
//     script.async = true;
//     script.id = reCaptchaScriptId;
//     document.head.appendChild(script);
// }


// const captchaToken = await executeCaptcha("login");
// await api.post('/login', credentials, {
//     headers: {
//         "Captcha-Token": captchaToken
//     }
// });