import React, {useEffect, useRef} from "react";
import {useForm} from "../../context/useForm";
import {AlertInfo} from "front-lib";

interface IProps {
    scrollToView?: boolean
}

const FormGlobalSuccessMessage = ({scrollToView = false}: IProps) => {
    const {successMessage} = useForm();
    const ref = useRef(null);


    useEffect(() => {

        if (successMessage && scrollToView) {
            // @ts-ignore
            ref.current.scrollIntoView();
        }
    }, [successMessage, scrollToView]);
    // console.log("successMessage", successMessage);
    return (
        <>
            {
                successMessage && <AlertInfo>
                    <div ref={ref}>
                        {successMessage}
                    </div>
                </AlertInfo>
            }
        </>
    );
};

export {FormGlobalSuccessMessage};
