import React from "react";
import {
    ButtonFormSubmit,
    FormGlobalErrorMessage,
    FormGlobalSuccessMessage,
    FormInputText, FormSwitch,
    FormTextarea,
    Tabs,
    useForm, withForm
} from "front-lib";
import DialogTriggerBuild from "../dialogs/DialogTriggerBuild";
import BuildsTable from "../BuildsTable";
import {EnvEditorForm} from "./EnvEditorForm";
import FormSelectEnvAccessibility from "./FormSelectEnvAccessibility";
import {LabelEnvLoaderUrl} from "../labels/LabelEnvLoaderUrl";
import {LabelApiCodeSnippet} from "../labels/LabelApiCodeSnippet";


interface IProps {
    env: any;
    onSubmit?: (formData?: any) => Promise<void>;
    onCancel?: () => void;
    onRemove?: (env: any) => void
}

const EnvironmentForm = ({env, onSubmit, onCancel, onRemove}: IProps) => {
    const {handleSubmit, watch} = useForm();
    const key = watch("key", env.key);
    return (
        <>
            <div className={'min-h-[470px]'}>
                <Tabs tabs={[
                    {
                        label: <span>Details</span>,
                        content: <div className="px-4 pt-5 sm:px-6">
                            <div className="grid grid-cols-6 gap-6">

                                {env.builds && env.builds[0] && <div className="col-span-6 sm:col-span-4 ">
                                    <LabelEnvLoaderUrl env={env}/>
                                </div>}

                                <div className="col-span-6 sm:col-span-4">
                                    <FormInputText label={"Name"} name={"name"}
                                                   rules={{required: true}}
                                                   defaultValue={env.name || ''}/>
                                </div>
                                <div className="col-span-6 sm:col-span-4 ">
                                    <FormInputText label={"Root URL"} name={"rootUrl"}
                                                   rules={{required: true}}
                                                   defaultValue={env.rootUrl || ''}/>
                                </div>
                                <div className="col-span-6 sm:col-span-4 ">
                                    <FormSelectEnvAccessibility defaultValue={env.accessibility}/>
                                </div>
                                {/*<div className="col-span-6  sm:col-span-4">*/}
                                {/*    <FormTextarea label={"Description"} name={"description"}*/}
                                {/*                  defaultValue={env.description || ''}/>*/}
                                {/*</div>*/}
                                <div className="col-span-6 sm:col-span-4">
                                    <FormGlobalErrorMessage/>
                                    <FormGlobalSuccessMessage/>
                                </div>


                            </div>
                            <div className={'-mx-6'}>
                                <div
                                    className=" flex items-center justify-between p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                                    <div className={'flex items-center space-x-2'}>
                                        <ButtonFormSubmit onSubmit={onSubmit}/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    },
                    {
                        label: <span>Builds</span>,
                        content: <div className="px-4 pt-5 sm:px-6">
                            <div className="grid grid-cols-6 gap-6">
                                {env.builds && env.builds[0] && <div className="col-span-6 ">
                                    <label
                                        className="block mb-2 text-sm font-medium text-gray-950 dark:text-gray-300">
                                        API Code Snippet
                                    </label>
                                    <LabelApiCodeSnippet env={env} build={env.builds[0]}/>
                                </div>}
                                <div className="col-span-6">
                                    <BuildsTable env={env} builds={env.builds}/>
                                </div>
                            </div>
                            <div className={'-mx-6'}>
                                <div
                                    className=" flex items-center justify-between p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                                    <div className={'flex items-center space-x-2'}>
                                        <DialogTriggerBuild env={env}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    },
                    {
                        label: <span>Configs</span>,
                        content: <div className="px-4 pt-5 sm:px-6">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-7">
                                    <FormInputText label={"Custom Domain"} name={"customDomain"}
                                                   rules={{required: false}}
                                                   defaultValue={env.customDomain || ''}/>
                                </div>
                                <div className="col-span-7">
                                    <FormSwitch label={"Hibernation"}
                                                name={"hibernation"}
                                                defaultValue={env.hibernation}
                                    />
                                </div>


                                <div className="col-span-7">
                                    {/*<FormInputText label={"Local Storage"} name={"proxySettings.localStorage"}*/}
                                    {/*               rules={{required: false}}*/}
                                    {/*               defaultValue={env.proxySettings.localStorage || ''}/>*/}
                                    <FormTextarea label={"Local Storage"} name={"proxySettings.localStorage"}
                                                  defaultValue={env.proxySettings.localStorage || ''}
                                                  placeholder={"Local Storage"}
                                                  rules={{
                                                      required: false,
                                                  }}
                                    />
                                </div>
                                <div className="col-span-7">
                                    {/*<FormInputText label={"Cookies"} name={"proxySettings.cookies"}*/}
                                    {/*               rules={{required: false}}*/}
                                    {/*               defaultValue={env.proxySettings.cookies || ''}/>*/}
                                    <FormTextarea label={"Cookies"} name={"proxySettings.cookies"}
                                                  defaultValue={env.proxySettings.cookies || ''}
                                                  placeholder={"Cookies"}
                                                  rules={{
                                                      required: false,
                                                  }}
                                    />
                                </div>
                            </div>
                            <div className={'-mx-6'}>
                                <div
                                    className=" flex items-center justify-between p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                                    <div className={'flex items-center space-x-2'}>
                                        <ButtonFormSubmit onSubmit={onSubmit}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    },
                    // {
                    //     label: <span>Plugins</span>,
                    //     content: <div className="">
                    //         <PluginsForm/>
                    //     </div>
                    // },

                    {
                        label: <span>Editor</span>,
                        content: <div className="px-4 pt-5 sm:px-6">
                            <div className="grid grid-cols-6 gap-6">
                                <EnvEditorForm env={env}/>
                            </div>
                            <div className={'-mx-6'}>
                                <div
                                    className=" flex items-center justify-between p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                                    <div className={'flex items-center space-x-2'}>
                                        <ButtonFormSubmit onSubmit={onSubmit}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    },
                    {
                        label: <span>JSON</span>,
                        content: <div className="grid grid-cols-6 gap-6  px-4 py-5 sm:p-6">
                            <div className="col-span-6 sm:col-span-4">
                                <div className={'min-h-[600px] mt-5 '}>
                                    <pre>{JSON.stringify({env}, null, 2)}</pre>
                                </div>
                            </div>
                        </div>
                    },
                ]}/>
            </div>


            {/*<div*/}
            {/*    className="flex items-center justify-between p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">*/}
            {/*    <div className={'flex items-center space-x-2'}>*/}
            {/*        <ButtonFormSubmit onSubmit={onSubmit}/>*/}
            {/*        <button type="button"*/}
            {/*                onClick={onCancel}*/}
            {/*                className="text-gray-500 min-h-[50px] bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg border border-gray-200 text-lg font-medium px-5 py-2.5 hover:text-gray-950 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">*/}
            {/*            Cancel*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*    {onRemove && <DialogConfirmation*/}
            {/*        title={"Confirm your action"}*/}
            {/*        renderTrigger={<button type="button"*/}
            {/*                               className="text-gray-500 min-h-[50px] bg-white hover:bg-error-100 focus:ring-4 focus:outline-none focus:ring-primary-300   text-lg font-medium px-5 py-2.5 hover:text-error-950 focus:z-10 dark:bg-error-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">*/}
            {/*            Delete*/}
            {/*        </button>}*/}
            {/*        renderContent={() => {*/}
            {/*            return <>*/}
            {/*                Before proceeding, please take a moment to confirm your intention to delete.*/}
            {/*                This action is irreversible and will permanently remove the selected item from*/}
            {/*                your workspace.*/}
            {/*            </>*/}
            {/*        }}*/}
            {/*        onApprove={() => {*/}
            {/*            console.log("delete")*/}
            {/*            handleSubmit(async () => {*/}
            {/*                onRemove && await onRemove(env)*/}
            {/*            })*/}
            {/*        }}*/}
            {/*    />}*/}


            {/*</div>*/}
        </>);

};

export default withForm(EnvironmentForm)
