import React from "react";
import { GenericTableProvider, IColumn, IFilter } from "./GenericTableContext";
import { IGenericTableProps } from "../GenericTable";



const withGenericTableContext = (Component: React.FunctionComponent<any>) => {
  return (props: IGenericTableProps) => {
    return <GenericTableProvider   {...props}>
      <Component />
    </GenericTableProvider>;
  };
};


export default withGenericTableContext;
