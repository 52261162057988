import React from "react";
import {LazyLabelDateTime} from "front-lib";
import {IColumn} from "../../table/context/GenericTableContext";
import DialogEditFeatureFlag from "./dialogs/DialogEditFeatureFlag";


export const featureFlagColumns: IColumn[] = [
    {
        property: "actions",
        label: "Actions",
        disableSorting: true,
        isVisible: true,
        component: ({row, column}) => <div className={'flex items-center'} onClick={(event) => event.stopPropagation()}>
            <DialogEditFeatureFlag featureFlag={row}/>
        </div>
    },
    {
        property: "id",
        label: "ID",
        isVisible: false,
        component: ({row, column}) => <>
            {row.id}
        </>
    },

    {
        property: "key",
        label: "Key",
        isVisible: true,
        component: ({row, column}) => <>
            {row.key}
        </>
    },
    {
        property: "isEnabled",
        label: "Enabled",
        isVisible: true,
        component: ({row, column}) => <>
            {row.isEnabled ? "yes" : "no"}
        </>
    },

    {
        property: "createdAt",
        label: "Created",
        isVisible: true,
        component: ({row, column}) => <>
            <LazyLabelDateTime timestamp={row.createdAt}/>
        </>
    },

];
