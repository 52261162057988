import React from "react"

const LabelEnvAccessibility = ({env}: any) => {

    const classNames: any = {
        "private": "bg-primary-100 text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-primary-700 dark:text-white",
        "public": "bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300",
    }
    const className = classNames[env.accessibility];
    return (
        <>
            <span
                className={`${className} capitalize`}>
                {env.accessibility}
            </span>

            {/*<span*/}
            {/*    className="inline-flex items-center bg-error-100 text-error-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-error-900 dark:text-error-300">*/}
            {/*    <span className="w-2 h-2 me-1 bg-error-500 rounded-full"></span>*/}
            {/*    Unavailable*/}
            {/*</span>*/}
        </>
    )
}

export {LabelEnvAccessibility}