
import {IFilter} from "../../table/context/GenericTableContext";

export const featureFlagFilters: IFilter[] = [



];


