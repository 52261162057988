import React from "react";
import {Controller} from "react-hook-form";
import {FormFieldErrorMessage} from "../messages/error/FormFieldErrorMessage";
import {useForm} from "../context/useForm";

const FormCheckbox = ({name, defaultValue, rules, label}: any) => {
    const {control} = useForm();

    return (
        <>
            <Controller
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                name={name}
                render={({field}: any) => (
                    <>
                        <div className="flex items-center">
                            <input id="default-checkbox" type="checkbox"
                                   {...field}
                                   defaultChecked={defaultValue}
                                   className="w-4 h-4 text-primary-600 bg-gray-100 rounded border-gray-300 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
                            <label htmlFor="default-checkbox"
                                   className="ml-2 text-sm font-medium text-gray-950 dark:text-gray-300 cursor-pointer">
                                {label || name}
                            </label>
                        </div>
                    </>
                )}
            />
            <FormFieldErrorMessage name={name}/>
        </>
    )

}

export {FormCheckbox}