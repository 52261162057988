import React from "react";
import {useTranslation} from "react-i18next";

interface IProps {
    error: any;
}


const TextErrorMessage = ({error}: IProps) => {
    const {t} = useTranslation();
    const type = (error.type || "").toUpperCase();
    const message = error.message || error.details;
    const key = message || `ERROR_${type}`;
    // const {t} = useLanguage();

    // console.log("error", error)
    const text = t(key)
    // console.log("TextErrorMessage", key, text, message)

    return (
        <>
            {text || "Something went wrong"}
        </>
    );
};

export {TextErrorMessage};
