import React from "react";
import {Helmet} from "react-helmet-async";
import {DevToolsForm} from "front-lib";

const DevToolsRoute = ({}) => {
    return (
        <>
            <Helmet>
                <title>DevTools</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl'}>
                <header className="">
                    <div className="py-6 px-4">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-950 dark:text-white">DevTools</h1>
                    </div>
                </header>
                <div className={'p-5 '}>
                    <div>
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-950 dark:text-gray-300">DevTools</h3>
                                    <p className="mt-1 text-sm text-gray-600 dark:text-gray-500">This information will
                                        be displayed
                                        publicly so
                                        be
                                        careful what you share.</p>
                                </div>
                            </div>
                            <div className="mt-5 md:col-span-2 md:mt-0">
                                <div className="shadow sm:overflow-hidden sm:rounded-md">
                                    <div className="card min-h-[500px]">
                                        <DevToolsForm/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {DevToolsRoute}