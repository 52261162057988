import React from "react";
import {IColumn, useGenericTable} from "./context/GenericTableContext";

interface IProps {
    index: number;
    row: any;
    onClick?: (event: any, row: any) => void;
    onSelectChanged?: (event: any, row: any) => void;
}

const DynamicRow = ({index, row, onClick, onSelectChanged}: IProps) => {

    const {
        visibleColumns,
        selectedRows,
        loadRows,
        crudApi,
        onRowClick
    } = useGenericTable();
    const isRowSelected = (row: any) => selectedRows.indexOf(row.id) !== -1;
    const isSelected = isRowSelected(row);
    const labelId = `enhanced-table-checkbox-${index}`;
    return <tr

        className={`hover:bg-gray-50 dark:hover:bg-gray-800 ${row.isDeleted ? 'row-deleted' : ''} ${onRowClick ? 'cursor-pointer' : ''}`}
        onClick={onRowClick ? (event) => {
            onRowClick({row, event});
        } : undefined}
        role="checkbox"
        aria-checked={isSelected}
        tabIndex={-1}
        key={row.id}

    >

        {visibleColumns.map((column: IColumn) => {
            return <td key={column.property}
                       className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {column.component({row, column, loadRows,crudApi})}
            </td>;
        })}

        {/*<TableCell align="right">{row.fat}</TableCell>*/}
        {/*<TableCell align="right">{row.carbs}</TableCell>*/}
        {/*<TableCell align="right">{row.protein}</TableCell>*/}
    </tr>;

};

export default DynamicRow;
