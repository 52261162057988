import React from 'react';
import {FormUploadAvatar} from "frontenvs-lib";
import FormSelectRole from "./FormSelectRole";
import {
    AvatarList,
    ButtonFormSubmit,
    FormGlobalErrorMessage,
    FormGlobalSuccessMessage,
    FormInputText,
    FormSwitch, withForm
} from "front-lib";

interface IProps {
    user: any;
    onSubmit?: (formData?: any) => Promise<void>;
    onCancel?: () => void
}

const UserForm = ({user, onSubmit, onCancel}: IProps) => {
    // const {handleSubmit} = useForm();

    // const onSubmit = () => {
    //     handleSubmit(async (dirtyValues: any, fieldValues: any) => {
    //         console.log("onSubmit", dirtyValues)
    //
    //         onSuccess && onSuccess();
    //     });
    // }
    return (
        <>
            <div className="space-y-6 bg-white dark:bg-gray-900 px-4 py-5 sm:p-6">


                <div>
                    <FormUploadAvatar name={"profile.avatar"} defaultValue={user.profile.avatar}/>
                </div>
                <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-6 sm:col-span-3">
                        <FormSwitch label={"Active"}
                                    name={"isActive"}
                                    defaultValue={user.isActive}
                        />
                    </div>
                    <div className="col-span-6 sm:col-span-4">
                        <FormInputText label={"Email"} name={"email"}
                                       rules={{
                                           required: true
                                       }}
                                       defaultValue={user.email}/>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                        <FormInputText label={"First name"} name={"profile.firstname"} placeholder={"Bruce"}
                                       defaultValue={user.profile.firstname}/>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                        <FormInputText label={"Last name"} name={"profile.lastname"} placeholder={"Wayne"}
                                       defaultValue={user.profile.lastname}/>
                    </div>


                    {/*<div className="col-span-6 sm:col-span-3">*/}
                    {/*    <FormInputText label={"Twitch ID"} name={"twitchId"}*/}
                    {/*                   defaultValue={user.twitchId}/>*/}
                    {/*</div>*/}

                    {/*<div className="col-span-6 sm:col-span-3">*/}
                    {/*    <FormInputText label={"Discord ID"} name={"discordId"}*/}
                    {/*                   defaultValue={user.discordId}/>*/}
                    {/*</div>*/}


                    <div className="col-span-6 sm:col-span-3">
                        <FormSelectRole defaultValue={user.role}/>
                    </div>
                    <div className="col-span-6">
                        <FormGlobalErrorMessage/>
                        <FormGlobalSuccessMessage/>
                    </div>
                </div>


            </div>
            {/*<div className="space-y-4 p-6 md:space-y-6">*/}
            {/*    <div>*/}
            {/*        <FormInputText label={'Your email'} name={"email"}*/}
            {/*                       placeholder={"name@company.com"}*/}
            {/*                       defaultValue={'eldar@frontenvs.com'}*/}
            {/*                       rules={{*/}
            {/*                           required: true,*/}
            {/*                       }}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        <FormInputPassword name={"password"}*/}
            {/*                           label={"Your password"}*/}
            {/*                           defaultValue={'kaboomDevPassword455!!!23&&'}*/}
            {/*                           rules={{*/}
            {/*                               required: true,*/}
            {/*                           }}*/}
            {/*        />*/}
            {/*    </div>*/}

            {/*    <div>*/}
            {/*        <FormCheckbox label={"Remember me"} name={"rememberMe"}/>*/}
            {/*    </div>*/}
            {/*    /!*<ButtonFormSubmit onSubmit={onSubmit} text={"Save"}/>*!/*/}

            {/*    <FormGlobalErrorMessage/>*/}

            {/*</div>*/}
            <div
                className="flex items-center p-4 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                {/*<button type="button"*/}
                {/*        onClick={onSubmit}*/}
                {/*        className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">*/}
                {/*    Save*/}
                {/*</button>*/}
                <ButtonFormSubmit onSubmit={onSubmit}/>
                <button type="button"
                        onClick={onCancel}
                        className="text-gray-500 min-h-[50px] bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg border border-gray-200 text-lg font-medium px-5 py-2.5 hover:text-gray-950 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                    Cancel
                </button>
            </div>
        </>
    );

};

export default withForm(UserForm)
