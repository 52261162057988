import React, {useState} from "react";
import {MdErrorOutline, MdOutlineClose} from "react-icons/md";

const AlertError = ({children, showClose = true, onClose}: any) => {
    const [isVisible, setIsVisible] = useState(true);

    return (
        <>
            {isVisible &&
                <div className="bg-error-100 border-t-4 border-error-500 rounded-b text-error-900 px-4 py-3 shadow-md"
                     role="alert">
                    <div className="flex justify-between items-center">
                        <div className="flex items-center">
                            <MdErrorOutline className="fill-current h-6 w-6 text-error-500 mr-4"/>
                            <div className="font-sm m-0">{children}</div>

                        </div>
                        {showClose && <div className={'flex'}>
                            <button type="button"
                                    onClick={(e) => {
                                        setIsVisible(false)
                                    }}
                                    className="cursor-pointer p-2 my-[2px] -mr-[9px] text-gray-500 rounded-lg hover:text-gray-950 hover:bg-error-200 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-0 dark:focus:ring-gray-600">
                                <MdOutlineClose className={'w-5 h-5'}/>
                            </button>
                        </div>}

                    </div>
                </div>}
        </>
    )
}

export {AlertError}