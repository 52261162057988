import React from 'react';
import {FaTwitch} from 'react-icons/fa';
import {useTwitch} from "../hooks/useTwitch";
import {ButtonConnectWith} from "./ButtonConnectWith";


interface IProps {

}


export const ConnectWithTwitch = ({}: IProps): JSX.Element => {

    const {connectWithTwitch} = useTwitch();
    return (
        <ButtonConnectWith onClick={connectWithTwitch} className={'bg-twitch hover:bg-twitch-dark'}>
            <FaTwitch className={'w-6 h-6'}/>
            <span className={"ml-3"}>Login with Twitch</span>
        </ButtonConnectWith>
    )

}