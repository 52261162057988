import React, {useEffect} from "react";
import {Helmet} from "react-helmet-async";
import BuildCrudTable from "../../../../modules/admin/crud/entities/build/BuildCrudTable";


const BuildsRoute = ({}) => {

    useEffect(() => {

    }, []);

    return (
        <>
            <Helmet>
                <title>Builds</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl py-5'}>
                <div className={''}>
                    <BuildCrudTable/>
                </div>
            </div>
        </>
    )
}

export default BuildsRoute
