import React from "react";
import {DialogConfirmation} from "front-lib"
import {useWorkspace} from "../../context/useWorkspace";

const DialogTriggerBuild = ({env, onEdit}: any) => {
    const {buildEnv} = useWorkspace();

    const triggerBuild = async () => {
        console.log("triggerBuild");
        await buildEnv(env.key);
    }
    return (
        <>
            <DialogConfirmation
                title={"Confirm Build"}
                onApprove={triggerBuild}
                renderTrigger={<button
                    className={`text-lg min-h-[50px] mr-1 flex justify-center items-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 `}>
                    Run Build
                </button>}
            />
        </>
    )
}

export default DialogTriggerBuild
