import React from 'react'

const Avatar = ({url, className = "w-10 h-10"}: any) => {
    return (
        <div className={className}>
            {!url && <div className={'overflow-hidden rounded-full bg-gray-100'}>

                <svg
                    className="h-full w-full text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    width="682.667"
                    height="682.667"
                    version="1.1"
                    viewBox="0 0 682.667 682.667"
                    xmlSpace="preserve"
                >
                    <defs>
                        <clipPath id="clipPath1433" clipPathUnits="userSpaceOnUse">
                            <path d="M0 512h512V0H0z"></path>
                        </clipPath>
                    </defs>
                    <g transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
                        <g>
                            <g clipPath="url(#clipPath1433)">
                                <g transform="translate(256 502)">
                                    <path
                                        fill="#ecf3f3"
                                        fillOpacity="1"
                                        fillRule="nonzero"
                                        stroke="none"
                                        d="M0 0c-135.98 0-246-110.04-246-246 0-135.736 109.836-246 246-246 136.776 0 246 110.917 246 246C246-110.02 135.96 0 0 0"
                                    ></path>
                                </g>
                                <g transform="translate(405.32 60.43)">
                                    <path
                                        fill="#cecdf4"
                                        fillOpacity="1"
                                        fillRule="nonzero"
                                        stroke="none"
                                        d="M0 0v-.01c-41.391-31.64-93.141-50.42-149.32-50.42-56.17 0-107.92 18.78-149.31 50.42 7.25 76.08 71.329 135.58 149.31 135.58C-71.34 135.57-7.271 76.08 0 0"
                                    ></path>
                                </g>
                                <g transform="translate(256 396)">
                                    <path
                                        fill="#cecdf4"
                                        fillOpacity="1"
                                        fillRule="nonzero"
                                        stroke="none"
                                        d="M0 0c44.11 0 80-35.89 80-80s-35.89-80-80-80-80 35.89-80 80S-44.11 0 0 0"
                                    ></path>
                                </g>
                                <g transform="translate(256 236)">
                                    <path
                                        fill="none"
                                        stroke="#000"
                                        strokeDasharray="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeMiterlimit="10"
                                        strokeOpacity="1"
                                        strokeWidth="20"
                                        d="M0 0c-44.11 0-80 35.89-80 80s35.89 80 80 80 80-35.89 80-80S44.11 0 0 0z"
                                    ></path>
                                </g>
                                <g transform="translate(106.65 60.03)">
                                    <path
                                        fill="none"
                                        stroke="#000"
                                        strokeDasharray="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeMiterlimit="10"
                                        strokeOpacity="1"
                                        strokeWidth="20"
                                        d="M0 0c.01.13.02.26.04.39 7.25 76.08 71.329 135.58 149.31 135.58 77.98 0 142.049-59.49 149.32-135.57"
                                    ></path>
                                </g>
                                <g transform="translate(450 420)">
                                    <path
                                        fill="#000"
                                        fillOpacity="1"
                                        fillRule="nonzero"
                                        stroke="none"
                                        d="M0 0c0-5.523-4.478-10-10-10S-20-5.523-20 0s4.478 10 10 10S0 5.523 0 0"
                                    ></path>
                                </g>
                                <g transform="translate(466.42 383.592)">
                                    <path
                                        fill="none"
                                        stroke="#000"
                                        strokeDasharray="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeMiterlimit="10"
                                        strokeOpacity="1"
                                        strokeWidth="20"
                                        d="M0 0c22.581-37.189 35.579-80.857 35.579-127.592 0-79.79-37.89-150.65-96.68-195.57v-.01c-41.39-31.64-93.14-50.42-149.32-50.42-56.17 0-107.92 18.78-149.309 50.42-58.791 44.92-96.691 115.78-96.691 195.58 0 135.96 110.02 246 246 246 56.871 0 109.207-19.25 150.851-51.598"
                                    ></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </div>}

            {url && <img className="h-full w-full rounded-full bg-gray-300"
                         src={url}
                         alt="user photo"/>}
        </div>
    )
}

export {Avatar}