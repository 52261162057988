
import {IFilter} from "../../table/context/GenericTableContext";

export const auditFilters: IFilter[] = [



];


