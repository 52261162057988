import React from "react";
import {useForm} from "../context/useForm";
import {Controller} from "react-hook-form";
import {FormDropdown} from "./FormDropdown";

interface IProps {
    name: string,
    defaultValue?: string,
    rules?: any
    label?: string | JSX.Element
    placeholder?: string,
    options: any[]
}

interface IOption {
    name: string,
    value: string
}

const FormSelect = ({
                        name,
                        defaultValue = "",
                        rules,
                        label,
                        placeholder,
                        options,
                        renderOption,
                        onChange

                    }: any) => {
    const {control} = useForm();

    // const nameOfValue = 'banana'
    // const nameOfName ='description'
    // console.log("defaultValue", defaultValue)
    return (
        <>
            <FormDropdown label={label} placeholder={placeholder} name={name} defaultValue={defaultValue} options={options}
                          rules={rules}
                          onChange={onChange}
                          renderOption={({option, selected}: any) => <div
                              className={`p-2.5 cursor-pointer hover:bg-gray-50 hover:dark:bg-gray-700 w-full ${selected ? 'bg-gray-50 dark:bg-gray-700' : ''}`}>
                              {renderOption ? renderOption() : option.name}
                          </div>}/>
            {/*<Controller*/}
            {/*    control={control}*/}
            {/*    defaultValue={defaultValue}*/}
            {/*    rules={rules}*/}
            {/*    name={name}*/}
            {/*    render={({field}: any) => (*/}
            {/*        <div>*/}

            {/*            {label && <label htmlFor={name}*/}
            {/*                             className="block text-sm font-medium text-gray-700  dark:text-gray-300">{label}</label>}*/}
            {/*            <select id={name} name={name}*/}
            {/*                    {...field}*/}
            {/*                    onChange={(e) => {*/}
            {/*                        console.log("onChange")*/}
            {/*                        onChange && onChange(e.target.value)*/}
            {/*                        field.onChange(e.target.value)*/}
            {/*                    }}*/}
            {/*                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm dark:bg-gray-950 dark:border-gray-800 dark:placeholder-gray-400 dark:text-gray-300">*/}
            {/*                {options.map((option: any) => {*/}
            {/*                    if (renderOption)*/}
            {/*                        return renderOption(option)*/}

            {/*                    return <option key={option.value} value={option.value}>*/}
            {/*                        {option.name}*/}
            {/*                    </option>*/}
            {/*                })}*/}
            {/*            </select>*/}
            {/*        </div>*/}
            {/*    )}/>*/}
        </>

    );
};

export {FormSelect};


