import React from "react";
import {DialogBasic} from "front-lib";
import {ManageSocketForm} from "../forms/ManageSocketForm";


const DialogManageSocket = ({client}: any) => {

    return (
        <>
            <DialogBasic
                title={"Manage Socket Client"}
                renderTrigger={() => <button
                    className="rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    Manage
                </button>}

                renderContent={({toggleOpen}: any) => {
                    return <>

                        <ManageSocketForm client={client} onCancel={toggleOpen}/>
                    </>
                }}
            />
        </>
    )
}

export   {DialogManageSocket}