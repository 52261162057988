import React from "react"

const ToastBuildCompleted = ({build, onClose}: any) => {
    return (
        <div className={'dark rounded-lg'}>
            <div
                className="p-4 w-full max-w-xs text-gray-950 bg-white shadow dark:bg-gray-900 dark:text-gray-300">
                <div className="flex items-center mb-3">
                    <span
                        className="mb-1 text-sm font-semibold text-gray-950 dark:text-white">
                        Build Completed
                    </span>
                    <button type="button"
                            onClick={onClose}
                            className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-950 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                            data-dismiss-target="#toast-notification" aria-label="Close">
                        <span className="sr-only">Close</span>
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                  clipRule="evenodd"></path>
                        </svg>
                    </button>
                </div>
                <div className="flex items-center">

                    <div className="text-sm font-normal">
                        {/*<div*/}
                        {/*    className="text-sm font-semibold text-primary-900 dark:text-white">{stats.completed} Photos*/}
                        {/*</div>*/}
                        <div className="text-sm font-normal">
                            Build {build.key} on environment {build.environment.name} completed
                        </div>
                        <span
                            className="text-xs font-medium text-gray-600 dark:text-primary-500">a few seconds ago</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ToastBuildCompleted