import React from "react"
import {LinkPreviewBox} from "frontenvs-lib";

const LabelViaApi = ({build}: any) => {

    return (
        <>
            {build.viaApi && <div
                className={'inline-flex bg-primary-100 text-white text-xs font-medium px-1.5 py-0.5 rounded dark:bg-primary-700 dark:text-white'}>
                <small>
                    CI/CD
                </small>
            </div>}
        </>
    )
}

export {LabelViaApi}