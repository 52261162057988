import React, {createContext, useEffect, useState} from "react";
import {EnvLoaderContext} from "./context";
import {api} from "front-lib";
import {useParams} from "react-router-dom";

const VITE_APP_ENV_LOADER_URL = import.meta.env.VITE_APP_ENV_LOADER_URL;
const VITE_APP_UI_URL = import.meta.env.VITE_APP_UI_URL;

const hash = window.location.hash.replace("#", "") || "/"
const EnvLoaderContextProvider = ({children}: any) => {
    const {envKey, buildKey} = useParams();

    const [isLoading, setIsLoading] = useState(true)
    const [env, setEnv] = useState<any>();
    const [routeKey, setRouteKey] = useState<string>(hash);
    const isBuildReady = env && env.builds && env.builds[0] && env.builds[0].key;

    const iframeUrl = `${VITE_APP_ENV_LOADER_URL}${routeKey}`;
    const loginToEnv = async (loginEnvDto: any) => {
        console.log("loginToEnv", loginEnvDto)
        const response = await api.post(`/el/login`, loginEnvDto)
        return response.data;
    }
    const loadEnvAndBuild = async () => {
        // console.log("loadEnvAndBuild", envKey, buildKey)
        const response = await api.get(`/el/load-build`)
        console.log("env", response.data)
        return response.data;
    }
    const envLoaderUrl = (() => {
        let loadUrl = `${VITE_APP_UI_URL}/e/${envKey}`
        if (buildKey) {
            loadUrl = `${loadUrl}/${buildKey}`;
        }
        if (routeKey && routeKey !== "/") {
            loadUrl = `${loadUrl}#${routeKey}`;
        }
        return loadUrl;
    })();

    const goToEnvRootUrl = () => {
        setRouteKey(hash)
    }
    useEffect(() => {

        (async () => {
            await loginToEnv({
                envKey,
                buildKey,
                routeKey
            })
            const env = await loadEnvAndBuild();
            setEnv(env);
            setIsLoading(false)
        })()
    }, []);

    useEffect(() => {
        window.addEventListener('message', onMessage, false);

        function onMessage(event: any) {
            console.log("onMessage:", event.data);
            if (event.data.event === "iframe-head-ready") {
                console.log("iframe-head-ready from EnvironmentLoader.tsx")
            }

            if (event.data.event === "iframe-route-changed") {
                const {url} = event.data;
                setRouteKey(url);
                console.log("iframe-route-changed from EnvironmentLoader.tsx", event)
            }
        }

        return () => {
            window.removeEventListener("message", onMessage)
        }
    }, []);
    const context = {
        isLoading,
        envLoaderUrl,
        routeKey,
        env,
        iframeUrl,
        isBuildReady,
        goToEnvRootUrl
    }
    return (
        <EnvLoaderContext.Provider value={context}>
            {children}
            {/*{!isLoading && children}*/}
            {/*{isLoading && <div className={'flex justify-center items-center  mx-auto pt-10'}>*/}
            {/*    <LoadingStripes/>*/}
            {/*</div>}*/}
        </EnvLoaderContext.Provider>
    )
}

export default EnvLoaderContextProvider
