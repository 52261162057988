import React from "react"
import {ButtonFormSubmit, FormGlobalErrorMessage, FormGlobalSuccessMessage, FormInputText, FormSwitch} from "front-lib";
import FormSelectEnvAccessibility from "../../forms/FormSelectEnvAccessibility";

const StepTriggerFirstEnvBuild = ({onCompleted, onBack}: any) => {
    const build: any = {shouldTriggerBuild: true, accessibility: "private"}
    return (
        <>
            <div className={'wizard-step-content'}>
                <div className={'grid grid-cols-12 gap-6'}>

                    <div className="col-span-7 ">
                        <FormSelectEnvAccessibility defaultValue={build.accessibility}/>
                    </div>
                    <div className="col-span-7">
                        <FormSwitch label={"Trigger first build"}
                                    name={"shouldTriggerBuild"}
                                    defaultValue={build.shouldTriggerBuild}
                        />

                    </div>
                    <div className="col-span-7">
                        {/*<FormInputText label={"Root URL"} name={"rootUrl"}*/}
                        {/*    // placeholder={"You application root URL"}*/}
                        {/*               rules={{required: true}}*/}
                        {/*               defaultValue={build.rootUrl || ''}/>*/}
                    </div>
                    <div className="col-span-7">
                        <FormGlobalErrorMessage/>
                        <FormGlobalSuccessMessage/>
                    </div>
                </div>
            </div>
            <div
                className="col-span-12 flex items-center justify-between p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                <div className={'flex items-center space-x-2'}>
                    <ButtonFormSubmit text={"Create Environment"} onSubmit={onCompleted}/>
                    <button type="button"
                            onClick={onBack}
                            className="text-gray-500 min-h-[50px] bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg border border-gray-200 text-lg font-medium px-5 py-2.5 hover:text-gray-950 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                        Back
                    </button>
                </div>
            </div>
        </>
    )
}

export {StepTriggerFirstEnvBuild}