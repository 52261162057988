import React from "react";
import {
    Avatar, DialogConfirmation,
    FormGlobalErrorMessage,
    FormGlobalSuccessMessage,
    LabelDateTime,
    Tabs,
    useForm, useProfile, useUser, withForm
} from "front-lib";
import {LabelBuildStatus} from "../../app/workspace/environments/labels/LabelBuildStatus";
import {RowNameValue} from "../../app/workspace/environments/forms/BuildForm";
import {BuildAssetsForm} from "../../app/workspace/environments/forms/BuildAssetsForm";


interface IProps {
    build: any;
    env: any;
    onSubmit?: (formData?: any) => Promise<void>;
    onCancel?: () => void;
    onRemove?: (env: any) => Promise<void>
}

const EnvManagerForm = ({build, env, onSubmit, onCancel, onRemove}: IProps) => {
    // const {handleSubmit, watch} = useForm();
    const {profile} = useProfile();
    const assets = build.snapshot && build.snapshot.urls ? build.snapshot.urls : [];

    return (
        <>
            <div className={'min-h-[470px]'}>
                <Tabs tabs={[
                    {
                        label: <span>Details</span>,
                        content: <>
                            <div className="grid grid-cols-6 gap-6  px-4 py-5 sm:p-6">
                                <div className="col-span-6">
                                    <table
                                        className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        <thead
                                            className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">
                                                Key
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Value
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {/*{profile &&*/}
                                        {/*    <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">*/}
                                        {/*        <th scope="row"*/}
                                        {/*            className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap dark:text-gray-400">*/}
                                        {/*            Owner*/}
                                        {/*        </th>*/}
                                        {/*        <td className="px-6 py-4">*/}
                                        {/*            <div className={'flex items-center'}>*/}
                                        {/*                <div className={'h-11 w-11'}>*/}
                                        {/*                    <Avatar url={profile.avatar}/>*/}
                                        {/*                </div>*/}
                                        {/*                <span*/}
                                        {/*                    className={'ml-2'}>{profile.firstname} {profile.lastname} </span>*/}

                                        {/*            </div>*/}
                                        {/*        </td>*/}
                                        {/*    </tr>}*/}
                                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                                            <th scope="row"
                                                className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap dark:text-gray-400">
                                                Status
                                            </th>
                                            <td className="px-6 py-4">
                                                <LabelBuildStatus build={build}/>
                                            </td>
                                        </tr>
                                        <RowNameValue name={"Key"} value={build.key}/>
                                        <RowNameValue name={"Size"} value={`${build.size}MB`}/>
                                        <RowNameValue name={"CI/CD"} value={`${build.viaApi ? "Yes" : "No"}`}/>
                                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                                            <th scope="row"
                                                className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap dark:text-gray-400">
                                                Triggered By
                                            </th>
                                            <td className="px-6 py-4">
                                                <div className={'flex items-center'}>
                                                    <div className={'h-11 w-11'}>
                                                        <Avatar url={build.profile.avatar}/>
                                                    </div>
                                                    <span
                                                        className={'ml-2'}>{build.profile.firstname} {build.profile.lastname} </span>

                                                </div>
                                            </td>
                                        </tr>
                                        <RowNameValue name={"Completed At"}
                                                      value={<LabelDateTime timestamp={build.completedAt}/>}/>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-span-6">
                                    <FormGlobalErrorMessage/>
                                    <FormGlobalSuccessMessage/>
                                </div>
                            </div>

                        </>
                    },
                    {
                        label: <span>Assets</span>,
                        content: <div className="grid grid-cols-6 gap-6  px-4 py-5 sm:p-6">
                            <div className="col-span-6">
                                <BuildAssetsForm build={build} assets={assets}/>
                            </div>
                        </div>
                    },
                    {
                        label: <span>JSON</span>,
                        content: <div className="grid grid-cols-6 gap-6  px-4 py-5 sm:p-6">
                            <div className="col-span-6 sm:col-span-4">
                                <div className={'min-h-[600px] mt-5 '}>
                                    <pre>{JSON.stringify({env}, null, 2)}</pre>
                                </div>
                            </div>
                        </div>
                    },


                ]}/>
            </div>

        </>);

};


export default withForm(EnvManagerForm)
