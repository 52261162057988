import React from "react";
import {Helmet} from "react-helmet-async";
import FeatureFlagTable from "../../../../modules/admin/crud/entities/feature-flag/FeatureFlagTable";

const FeatureFlagsRoute = ({}) => {
    return (
        <>
            <Helmet>
                <title>Feature Flags</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl'}>
                {/*<div className={''}>*/}
                {/*    <Breadcrumb/>*/}
                {/*</div>*/}
                <FeatureFlagTable/>
            </div>
        </>
    )
}

export default FeatureFlagsRoute