import React from 'react';
import {LoadingStripes} from "../stripes/LoadingStripes";

const LoadingCover = ({isLoading, children, className = "", text}: any) => {
    return (
        <>
            {!isLoading && children && children()}

            {isLoading && <div className={`${className}`}>
                <div className={'flex items-center flex-col w-full'}>
                    <LoadingStripes text={text}/>
                </div>
            </div>}
        </>
    )
}

export {LoadingCover}