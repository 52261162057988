import React, {useEffect, useState} from "react";
 import {EnvironmentLoader} from "../../../modules/env-loader/EnvironmentLoader";
import EnvironmentEditor from "../../../modules/env-loader/EnvironmentEditor";

const EnvironmentEditorRoute = ({}) => {
    return (
        <>
            <EnvironmentLoader render={({env}: any) => <EnvironmentEditor env={env} build={env.builds[0]}/>}/>
        </>
    )
}

export default EnvironmentEditorRoute
