import React from 'react';
import {workspaceFilters} from "./WorkspaceFilters";
import {workspaceColumns} from "./WorkspaceColumns";
import GenericTable from "../../table/GenericTable";
import {useWorkspaceCrudApi} from "./api/useWorkspaceCrudApi";

const WorkspaceTable = () => {
    const crudApi = useWorkspaceCrudApi();

    return (
        <>
            <GenericTable renderHeader={({loadRows}) => <>
                <div className={'flex items-center'}>
                    <h3 className="font-semibold text-lg">Workspaces</h3>
                    {/*<button id="toggleSidebar" aria-expanded="true" aria-controls="sidebar"*/}
                    {/*        onClick={() => {*/}
                    {/*        }}*/}
                    {/*        className="p-2 ml-1 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-950 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700">*/}
                    {/*    <RiFilter3Line size={22}/>*/}
                    {/*</button>*/}
                </div>

            </>}
                          crudApi={crudApi}
                          filters={workspaceFilters}
                          columns={workspaceColumns}
            />
        </>
    );
};

export default WorkspaceTable;
