import React from "react"
import {DialogBasic} from "front-lib";
import {useGenericTable} from "../../../table/context/GenericTableContext";
import NotificationForm from "../forms/NotificationForm";
import {useNotificationCrudApi} from "../api/useNotificationCrudApi";

const DialogEditNotification = ({notification}: any) => {
    const crudApi = useNotificationCrudApi();
    const {loadRows} = useGenericTable();

    return (
        <>
            <DialogBasic
                title={"Edit Notification"}
                renderTrigger={<button
                    className="rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    Edit
                </button>}
                renderContent={({toggleOpen}: any) => {
                    return <>
                        <NotificationForm notification={notification} onSubmit={async ({dirtyValues}: any) => {

                            console.log("dirtyValues", dirtyValues)
                            await crudApi.edit({id: notification.id, ...dirtyValues});
                            await loadRows();
                            toggleOpen()
                        }} onCancel={toggleOpen}/>
                    </>
                }}
            />
        </>
    )
}

export default DialogEditNotification