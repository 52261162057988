import {api} from "front-lib";

export const useSystemConfigsApi = () => {
    const fetchSystemConfigs = async () => {
        const response = await api.get('/admin/system-configs');
        return response.data;
    }

    const saveSystemConfigs = async (systemConfigsDto: any) => {
        const response = await api.post('/admin/system-configs', systemConfigsDto);
        return response.data;
    }

    return {
        fetchSystemConfigs,
        saveSystemConfigs
    }
}