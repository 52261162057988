import React from 'react'
import {Avatar} from "./Avatar";

const AvatarList = ({profiles, max = 4}: any) => {
    const extraCount = Math.max(0, profiles.length - max + 1);
    const gap = extraCount === 1 ? 0 : 1;
    return (
        <>
            {profiles && <div className="flex">
                {[...profiles].splice(0, max - gap).map((p: any, index: number) => {
                    return <Avatar key={`profile_avatar_${p.id}`}
                                   url={p.avatar}
                                   className={`w-10 h-10 rounded-full border-2 border-primary-50 shadow ${index > 0 ? '-ml-4' : ''}`}/>
                })}
                {Boolean(extraCount > 1) && <div
                    className={'w-10 h-10 rounded-full border-2 border-primary-50 shadow bg-gray-100 text-black flex items-center justify-center -ml-4 text-lg '}>
                    <span> +{extraCount}</span>
                </div>}
            </div>}
        </>
    )
}

export {AvatarList}