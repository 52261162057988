import React, {useState} from "react";
import {MdErrorOutline, MdOutlineClose} from "react-icons/md";

const AlertErrorList = ({description = "Make sure that these conditions are met:", errors}: any) => {
    const [isVisible, setIsVisible] = useState(true);

    return (
        <>
            {isVisible && <div
                className="p-4 mb-4 text-sm text-error-500 bg-error-100 border-t-4 border-error-500 dark:bg-error-200 dark:text-error-800 shadow-md"
                role="alert">
                <div className="flex justify-between items-start">
                    <div className="flex ">
                        <MdErrorOutline className="fill-current h-6 w-6 text-error-500 mr-4"/>
                        <div>
                            <span className="font-semibold">{description}</span>
                            <ul className="mt-1.5 ml-4 text-error-500 list-disc list-inside">
                                {errors.map((err: any) => {
                                    return <li key={err}>{err}</li>
                                })}

                            </ul>
                        </div>
                    </div>
                    <button type="button"
                            onClick={(e) => {
                                setIsVisible(false)
                            }}
                            className="cursor-pointer p-2 my-[2px] -mr-[9px] text-gray-500 rounded-lg hover:text-gray-950 hover:bg-error-200 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-0 dark:focus:ring-gray-600">
                        <MdOutlineClose className={'w-5 h-5'}/>
                    </button>
                </div>
            </div>}
        </>
    )
}

export {AlertErrorList}