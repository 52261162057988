import React from "react";
import {auditFilters} from "./AuditFilters";
import {auditColumns} from "./AuditColumns";
import GenericTable from "../../table/GenericTable";
import {useAuditCrudApi} from "./api/useAuditCrudApi";

const AuditTable = ({withHeader = true}: any) => {
    const crudApi = useAuditCrudApi();

    return (
        <>
            <GenericTable renderHeader={withHeader && <>
                <div className={'flex items-center'}>
                    <h3 className="font-semibold text-lg dark:text-white">Audit Log</h3>

                </div>
            </>}
                          crudApi={crudApi}
                          filters={auditFilters}
                          columns={auditColumns}
            />
        </>
    );
};

export default AuditTable;
