import {useState, useEffect} from 'react'
// import resolveConfig from 'tailwindcss/resolveConfig'
// import tailwindConfig from '../../../../tailwind.config'
import tailwindScreens from '../tailwind.screens.json'

const screens: any = tailwindScreens;

const findKeyByValue = (object: any, value: any): string =>
    Object.keys(object).find((key) => object[key] === value) as string

const getDeviceConfig = (width: number) => {
    const bpSizes = Object.keys(screens).map((screenSize) => {
            return parseInt(screens[screenSize])
        }
    )

    const bpShapes = bpSizes.map((size, index) => ({
        min: !index ? 0 : bpSizes[index - 1],
        max: size,
        key: findKeyByValue(screens, `${size}px`),
    }))

    let breakpoint = "2xl"


    bpShapes.forEach((shape) => {
        if (!shape.min && width < shape.max) {
            breakpoint = shape.key
        } else if (width >= shape.min && width < shape.max) {
            breakpoint = shape.key
        } else if (!shape.max && width >= shape.max) {
            breakpoint = shape.key
        }
    })
    return breakpoint
}

const useTailwindBreakpoint = () => {
    const width = typeof window !== 'undefined' ? window.innerWidth : 0

    const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(width))

    useEffect(() => {
        const calcInnerWidth = function () {
            const width = typeof window !== 'undefined' ? window.innerWidth : 0
            // console.log("width", width)
            setBrkPnt(getDeviceConfig(width))
        }
        window.addEventListener('resize', calcInnerWidth)
        return () => window.removeEventListener('resize', calcInnerWidth)
    }, [])

    return brkPnt
}

export {useTailwindBreakpoint}


