import React from "react"
import {CreateEnvironmentWizard} from "../workspace/environments/wizards/CreateEnvironmentWizard";
import {useNavigate} from "react-router-dom";

const GettingStartedView = () => {
    const navigate = useNavigate()
    return (
        <>
            <section className="">
                <div className="">
                    <div className="max-w-screen-md">
                        <header className="">
                            <div className="mx-auto py-6">
                                <h1 className="text-3xl font-bold tracking-tight text-gray-950 dark:text-white">
                                    Getting Started</h1>
                            </div>
                        </header>
                        <div className={'-mx-6'}>
                            <CreateEnvironmentWizard onCompleted={async () => {
                                navigate("/app")
                            }}/>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export {GettingStartedView}