import React from "react";
import {Controller} from "react-hook-form";
import {FormFieldErrorMessage} from "../messages/error/FormFieldErrorMessage";
import {useForm} from "../context/useForm";

const FormSwitch = ({name, rules, defaultValue, label, disabled, onChange}: any) => {
    const {control} = useForm();

    return (
        <>
            <Controller
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                name={name}
                render={({field}: any) => (
                    <>
                        <label
                            className={`inline-flex relative items-center ${disabled ? "" : "cursor-pointer"}`}>
                            <input type="checkbox" {...field} checked={field.value} onChange={(e) => {
                                field.onChange(e);
                                onChange && onChange(!field.value)
                            }} className="sr-only peer"
                                   disabled={disabled}/>
                            <div
                                className={`${disabled ? "opacity-25" : ""} w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-primary-300 dark:peer-focus:ring-primary-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary-600`}>
                            </div>
                            <span
                                className="ml-3 text-sm font-medium text-gray-950 dark:text-gray-300">{label || name}</span>
                        </label>
                    </>
                )}
            />
            <FormFieldErrorMessage name={name}/>
        </>
    )
}

export {FormSwitch}