const VITE_APP_DISCORD_REDIRECT_URL = import.meta.env.VITE_APP_DISCORD_REDIRECT_URL;
const VITE_APP_DISCORD_CLIENT_ID = import.meta.env.VITE_APP_DISCORD_CLIENT_ID;

export const useDiscord = () => {

    const url = `https://discord.com/api/oauth2/authorize?client_id=${VITE_APP_DISCORD_CLIENT_ID}&redirect_uri=${VITE_APP_DISCORD_REDIRECT_URL}&response_type=code&scope=identify%20email`
    // const url = `https://discord.com/api/oauth2/authorize?client_id=1063576987811205223&redirect_uri=http%3A%2F%2Flocalhost%3A6060%2Fapi%2Fv1%2Fauth%2Fdiscord%2Fcallback&response_type=code&scope=identify`
    // console.log("url", url)
    const connectWithDiscord = () => {
        window.open(
            url,
            '_self',
        );
    }

    return {
        connectWithDiscord
    }
}
