import React, {useEffect, useRef} from "react";
import {useTheme} from "../hooks/useTheme";
import {useUser} from "front-lib";


const WhiteLabelThemeProvider = ({children, defaultColor, defaultDarkMode, className}: any) => {
    // console.log("WhiteLabelThemeProvider")
    const ref = useRef<any>(null);

    const {user} = useUser();
    const {setThemeColor, setDarkMode} = useTheme();

    useEffect(() => {
        // console.log("theme changed", theme.palette.primary.shades)
        const color = (() => {
            if (defaultColor) return defaultColor;
            if (user && user.profile) return user.profile.tpc
            // return  theme.palette.primary.main
        })();

        if (color) {
            setThemeColor("primary", color)
        }

        const darkMode = (() => {
            if (defaultDarkMode) return defaultDarkMode;
            if (user && user.profile) return user.profile.tdm;
            return false;
        })();
        if (darkMode) {
            setDarkMode(darkMode)
        }
    }, [])


    return (
        <div className={`tp-137 ${className}`} ref={ref}>
            {children}
        </div>

    )
}

export {WhiteLabelThemeProvider}