import React from "react";
import {Avatar, LazyLabelDateTime, LoadingCover} from "front-lib";
import DialogEditEnvironment from "./dialogs/DialogEditEnvironment";
import {useWorkspace} from "../context/useWorkspace";
import Pagination from "../../../admin/crud/table/pagination/Pagination";
import LabelBuildScreenshot from "./labels/LabelBuildScreenshot";
import DialogCreateEnvironment from "./dialogs/DialogCreateEnvironment";
import {LabelEnvAccessibility} from "./labels/LabelEnvAccessibility";
import {LabelEnvironmentDomain} from "./labels/LabelEnvironmentDomain";
import {ImEarth} from "react-icons/all";

const EnvironmentsTable = ({}: any) => {
    const {workspaceEnvironments} = useWorkspace();
    const isLoading = false;
    return (
        <>

            {isLoading && <div className={'py-10'}>
                <LoadingCover isLoading={true}/>
            </div>}
            {!isLoading && <>

                <div className="block w-full scrollable-table">
                    <div
                        className="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded bg-white dark:bg-gray-950">
                        <div className="block w-full overflow-x-auto px-4 pt-4 ">
                            <div className={'flex items-center mb-4 justify-between'}>
                                <h3 className="font-semibold text-lg py-1.5">Environments</h3>
                                <DialogCreateEnvironment onCreated={async () => {
                                }}/>
                            </div>

                            <div className={'table-container relative block w-full overflow-x-auto overflow-auto'}>

                                <table
                                    className="items-center w-full bg-transparent border-separate border-spacing-0">
                                    {/*<thead className={'sticky top-0 z-10'}>*/}
                                    {/*<tr>*/}
                                    {/*    <th className="px-6 th-primary ">Action</th>*/}
                                    {/*    <th className="px-6 th-primary">Preview</th>*/}
                                    {/*    /!*<th className="px-6 th-primary">Build</th>*!/*/}
                                    {/*    /!*<th className="px-6 th-primary">Owner</th>*!/*/}
                                    {/*    <th className="px-6 th-primary">Accessibility</th>*/}
                                    {/*    <th className="px-6 th-primary">Date</th>*/}
                                    {/*</tr>*/}
                                    {/*</thead>*/}
                                    <tbody>

                                    {workspaceEnvironments && workspaceEnvironments.map((env: any, index: number) => {
                                        return <tr key={`ws-env-${env.id}`}
                                                   className={'hover:bg-gray-50 dark:hover:bg-gray-800 dark:text-gray-300'}>
                                            <td className="border-t-0 pl-2 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap ">
                                                <div className={'pl-4'}>
                                                    <DialogEditEnvironment env={env}/>
                                                </div>
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                <LabelBuildScreenshot env={env} build={env.builds[0]}/>
                                            </td>
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                <div className={'text-lg '}>
                                                    <LabelEnvironmentDomain env={env}/>
                                                </div>
                                            </td>
                                            {/*<td className="border-t-0 pl-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">*/}
                                            {/*    <div className={'flex items-center'}>*/}
                                            {/*        <div className={'h-11 w-11'}>*/}
                                            {/*            <Avatar url={env.profile.avatar}/>*/}
                                            {/*        </div>*/}
                                            {/*        <div className={'ml-2 '}>*/}
                                            {/*            /!*{row.profile.firstname} {row.profile.lastname}*!/*/}
                                            {/*            <div>*/}
                                            {/*                {env.profile.firstname} {env.profile.lastname}*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}

                                            {/*    </div>*/}
                                            {/*</td>*/}
                                            <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                <LabelEnvAccessibility env={env}/>
                                            </td>
                                            <td className="border-t-0 pl-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                                <LazyLabelDateTime timestamp={env.createdAt}/>
                                            </td>
                                        </tr>
                                    })}

                                    </tbody>
                                </table>
                                {
                                    (!workspaceEnvironments || !workspaceEnvironments.length) && <>
                                        <NoEnvironmentsMessage/>
                                    </>
                                }

                            </div>

                            {
                                workspaceEnvironments && workspaceEnvironments.length > 0 &&
                                <Pagination className={'sticky bottom-0 left-0 w-full'}
                                            count={workspaceEnvironments.length}/>
                            }
                        </div>
                    </div>
                </div>

            </>
            }
        </>
    )

};

export const NoEnvironmentsMessage = () => {
    return <div className={'noData'}>
        <div>
            <div
                className="py-8 px-4 mx-auto max-w-screen-md text-center lg:py-16 lg:px-12 flex justify-center items-center flex-col">
                <ImEarth className="w-[80px] h-[80px] p-[5px] mb-2 fill-gray-500 opacity-50"/>
                <h3 className="mb-3 text-3xl  opacity-50 font-bold tracking-tight leading-none text-gray-950    dark:text-white">
                    No Environments
                </h3>
                <p className="font-light text-gray-500 text-md dark:text-gray-400">
                    No environments in your workspace</p>

                {/*<DialogCreatePuppetInWorkspace onCreated={() => {*/}
                {/*}} renderTrigger={<button*/}
                {/*    className="mt-3 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">*/}
                {/*    Create Puppet*/}
                {/*</button>}/>*/}
            </div>
        </div>
    </div>;

};
export default EnvironmentsTable;
