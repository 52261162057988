const VITE_APP_BACKEND_BASE_URL = import.meta.env.VITE_APP_BACKEND_BASE_URL;

export const useTwitch = () => {

    // const socialCallBackUrl = `${window.location.protocol}//${window.location.host}/auth/social/cb`;
    const url = `${VITE_APP_BACKEND_BASE_URL}/auth/twitch`;
    // console.log("url", url)
    const connectWithTwitch = () => {
        window.open(
            url,
            '_self',
        );
    }

    return {
        connectWithTwitch
    }
}