import axios from 'axios';
import {CaptchaProtectedAction, executeCaptcha} from "../auth/captcha/recaptcha";
import lzString from "lz-string";
// console.log("import.meta.env",import.meta.env);

//@ts-ignore
const baseURL = import.meta.env.VITE_APP_BACKEND_BASE_URL;
const api = axios.create({
    baseURL: baseURL,
    withCredentials: true,
    timeout: 2 * 60 * 1000,
    headers: {
        'Content-Type': 'application/json',
    },
});
const VITE_APP_LZ_API = import.meta.env.VITE_APP_LZ_API;
// console.log("VITE_APP_LZ_API",VITE_APP_LZ_API)
export const setupInterceptors = (onForbidden: (error: any) => void) => {
    api.interceptors.request.use((config: any) => {
        // console.log("http request :", config)

        // const token = localStorage.getItem("ztoken");
        // config.headers.Authorization =  `bearer ${token}`;
        if (VITE_APP_LZ_API === "true" && config.data && config.headers["Content-Type"] === "application/json") {
            config.data = {
                r: lzString.compressToEncodedURIComponent(JSON.stringify(config.data))
            }
        }
        return config;
    }, (error: any) => {
        return Promise.reject(error);
    });
    api.interceptors.response.use(
        (response: any) => {
            // if (import.meta.env.MODE !== "development") {
            if (response.data.r) {
                response.data = JSON.parse(lzString.decompressFromEncodedURIComponent(response.data.r) || "")
            }
            // }
            return response;
        },
        (ex: any) => {
            const exception = ex && ex.response && ex.response.data ? ex.response.data : ex;
            if (ex && ex.response && ex.response.status === 403 && ex.config) {
                // console.log('Forbidden 403', ex.response);
                localStorage.clear();

                if (ex.config.url !== "/logout" && ex.response.data && (ex.response.data.errorCode === 'BAD_AUTH_TOKEN')) {
                    // console.log("doing redirect to the login page")
                    window.location.href = "/login"
                }

                onForbidden && onForbidden(ex);
            }
            // console.log("bla")
            console.error(exception);
            throw exception;
        },
    );
};
export {api};

export const postWithCaptchaToken = async (url: string, data: any, action: CaptchaProtectedAction) => {
    let captchaToken;
    try {
        captchaToken = await executeCaptcha(action);
    } catch (err) {
        console.error(err)
        throw new Error("BAD_CAPTCHA")
    }

    const response = await api.post(url, data, {
        headers: {
            "Captcha-Token": captchaToken
        }
    });
    return response;

}

export const getWithCaptchaToken = async (url: string, action: CaptchaProtectedAction) => {
    // console.log("getWithCaptchaToken")
    const captchaToken = await executeCaptcha(action);
    // console.log("getWithCaptchaToken",captchaToken)

    const response = await api.get(url, {
        headers: {
            "Captcha-Token": captchaToken
        }
    });
    return response;
}