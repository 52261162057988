import React from "react";
import {
    api,
    ButtonFormSubmit,
    FormGlobalErrorMessage,
    FormGlobalSuccessMessage,
    FormInputText,
    FormTextarea,
    useForm, withForm
} from "front-lib";

import {ProtectedByReCaptchaText} from "front-lib";
const ContactUsForm = ({onSubmit, source}: any) => {

    const {handleSubmit} = useForm();


    const sendMessage = () => {

        handleSubmit(async ({fieldValues}: any) => {
            //send fieldValues to backend
            const response = await api.post("ticket/create-ticket", {...fieldValues,source})
            console.log("response from backend", response)
            //show message to user (if success or failed)
            onSubmit && onSubmit();
            return "We got your message, we will stay in touch ASAP."

        });
    };

    return (
        <>
            <div>
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-950 dark:text-white">
                    Contact Us
                </h2>
                <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
                    Have a technical problem? Would you like to provide feedback on a feature? Want to learn more
                    about our
                    plans? Let us know.
                </p>


                <div className="space-y-6">

                    <div>
                        <FormInputText label={"Your email"} name={"email"}
                                       placeholder={"name@company.com"}
                                       rules={{
                                           required: true,
                                       }}
                        />
                    </div>

                    <div>
                        <FormInputText label={"Subject"} name={"subject"}
                                       placeholder={"Let us know how we can help you"}
                                       rules={{
                                           required: true,
                                       }}
                        />
                    </div>

                    <div >
                        <FormTextarea label={"Your message"} name={"message"}
                                      placeholder={"Leave a comment..."}
                                      rules={{
                                          required: true,
                                      }}
                        />
                        <ProtectedByReCaptchaText className={'text-left !p-0'}/>
                    </div>
                    <div >
                        <ButtonFormSubmit onSubmit={sendMessage} text={"Send message"}/>

                    </div>
                    <div>
                        <FormGlobalErrorMessage/>
                        <FormGlobalSuccessMessage/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withForm(ContactUsForm)