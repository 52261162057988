import {useContext} from "react";
import {AuthContext} from "./AuthContext";
import {IProfile, IUser} from "../interfaces";

export function useAuthContext(): IAuthContext {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error("useAuthContext must be used within AuthContext");
    }
    return context;
}

interface IAuthContext {
    user: IUser,
    loginAndLoadMyProfile: (credentials: any) => Promise<IUser>
    registerAndLoadMyProfile: (user: any, referralId?: string | null) => Promise<IUser>
    logoutAndRemoveMyProfile: () => Promise<void>
    updateMyProfileAndContextState: (profile: IProfile) => Promise<void>
    loadMyProfileAndSaveToContextState: () => Promise<void>


}
