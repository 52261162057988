import {useEffect} from "react";
import {useWatch} from "react-hook-form";
import {useForm} from "./useForm";


export const useFormChange = (onChange?: any) => {
    const {control} = useForm();
    const formValues = useWatch({control})

    useEffect(() => {
        // console.log("formChanged", formValues)
        if (!Object.keys(formValues).length) return;
        onChange && onChange(formValues);
    }, [formValues]);

    return formValues;
}