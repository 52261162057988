import React, {useEffect, useState} from "react";
import {useWorkspace} from "../context/useWorkspace";
import {Avatar, LabelDateTime} from "front-lib";
import Pagination from "../../../admin/crud/table/pagination/Pagination";
import {LabelEnvironmentDomain} from "./labels/LabelEnvironmentDomain";
import {MdOutlineBuildCircle, MdSearchOff} from "react-icons/all";
import DialogReviewBuild from "./dialogs/DialogReviewBuild";
import {LabelBuildStatus} from "./labels/LabelBuildStatus";
import {LabelViaApi} from "./labels/LabelViaApi";

const WorkspaceLatestBuilds = ({}: any) => {

    const {workspaceBuilds} = useWorkspace();
    return (<>
        <div
            className="w-full h-full p-4 pb-0 bg-white dark:bg-gray-950 shadow-lg rounded">
            <div className="flex items-center justify-between mb-4">
                <h3 className="font-semibold text-lg py-1.5">Latest Builds</h3>
            </div>
            <div className="flow-root">
                {!workspaceBuilds.length && <NoBuildsMessage/>}
                <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700 overflow-x-auto max-h-[510px]">
                    {workspaceBuilds.map((b: any) => {
                        return <DialogReviewBuild key={`build-latest-${b.id}`} build={b} environment={b.environment}
                                                  renderTrigger={() => {
                                                      return <li
                                                          className="py-3 sm:py-4 hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                                                          key={b.id}>
                                                          <div className="flex items-center">
                                                              <div className="flex-shrink-0">
                                                                  <Avatar url={b.profile.avatar}/>
                                                                  {/*<img className="w-8 h-8 rounded-full" src="/docs/images/people/profile-picture-1.jpg"*/}
                                                                  {/*     alt="Neil image"/>*/}
                                                              </div>
                                                              <div
                                                                  className="flex-1 min-w-0 ml-4">
                                                                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                                      <LabelEnvironmentDomain
                                                                          env={b.environment}/>
                                                                  </p>
                                                                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                                      {/*{b.profile.firstname} {b.profile.lastname}*/}
                                                                      <LabelDateTime
                                                                          timestamp={b.createdAt}/>
                                                                  </p>
                                                              </div>
                                                              <div
                                                                  className="inline-flex flex-col justify-end items-end pr-2  text-base font-semibold text-gray-900 dark:text-white">
                                                                  {/*{b.key}*/}
                                                                  <LabelBuildStatus build={b}/>
                                                                  <div>
                                                                      <LabelViaApi build={b}/>
                                                                  </div>

                                                              </div>
                                                          </div>
                                                      </li>
                                                  }}/>
                    })}

                </ul>
                <div>
                    {workspaceBuilds.length > 0 && <Pagination className={'sticky bottom-0 left-0 w-full'}
                                                               count={workspaceBuilds.length}/>}
                </div>
            </div>
        </div>

    </>)
}

const NoBuildsMessage = () => {
    return <div className={'noData'}>
        <div>
            <div
                className="py-8 px-4 mx-auto max-w-screen-md  text-center lg:py-16 lg:px-12 flex justify-center items-center flex-col">
                <MdOutlineBuildCircle className={'w-[80px] h-[80px] mb-2 fill-gray-500 opacity-50'}/>

                <h3 className="mb-3 text-3xl opacity-50 font-bold tracking-tight leading-none text-gray-950    dark:text-white">
                    No builds
                </h3>
                <p className="font-light text-gray-500 text-md dark:text-gray-400">
                    No builds in your workspace</p>
            </div>
        </div>
    </div>;

};

export default WorkspaceLatestBuilds
