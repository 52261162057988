import React from 'react';
import {Role} from '../enums';
import {useUser} from '../hooks';

interface IProps {
    children: JSX.Element;
    role: Role;
    isSpecific?: boolean
}

const VisibleByRole = ({children, role, isSpecific = false}: IProps) => {
    const {user} = useUser();

    const isInRole = () => {

        if (isSpecific)
            return user.role === role

        return user.role >= role
    }
    return <>{user && isInRole() && children}</>;
};

export {VisibleByRole};
