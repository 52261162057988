import React from "react";
import {FormInputPassword, useForm, useFormChange, withForm , IProfile} from "front-lib";
interface IProps {
    profile: IProfile,
    onProfileChanged: (profile: IProfile) => Promise<void>
}

const ApiKeyOptionsForm = withForm(({profile, onProfileChanged}: IProps) => {

    const {handleSubmit, watch} = useForm();

    const openAiToken = watch("openAiToken", profile.openAiToken);
    const isValid = Boolean(openAiToken)

    useFormChange(() => {
        console.log("form changed")
        handleSubmit(async ({dirtyValues}: any) => {
            console.log("dirtyValues", dirtyValues)
            await onProfileChanged(dirtyValues);
        });
    });
    return (
        <>
            <div className="bg-white dark:bg-gray-950 px-6">

                <div className="grid grid-cols-6 gap-6">

                    <div className="col-span-6 mb-6 dark:text-gray-300">
                        <div className=" ">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-4">
                                    <FormInputPassword label={"API Token"} name={"apiToken"}
                                        // rules={{
                                        //     required: true
                                        // }}
                                                       readOnly={true}
                                                       defaultValue={"just_fake_token_goes_here_1234!!!"}/>
                                    {/*<small className={'description-under mr-2'}>*/}
                                    {/*    More information about key generation can be found <a*/}
                                    {/*    href={"https://platform.openai.com/account/api-keys"} target={"_blank"}*/}
                                    {/*    className={'font-bold text-primary-500'}>here</a>.<br/>*/}
                                    {/*</small>*/}


                                </div>


                                {/*{(!isValid) && <div className="col-span-6">*/}
                                {/*    <AlertWarning showClose={false}>*/}
                                {/*        You must set your OpenAI token.*/}
                                {/*    </AlertWarning>*/}
                                {/*</div>}*/}

                            </div>


                        </div>
                    </div>
                </div>


            </div>

        </>
    )
})

export {ApiKeyOptionsForm}