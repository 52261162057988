import React from "react";
import {DialogBasic} from "front-lib";
import {FormThemeColorBuilder} from "../forms/FormThemeColorBuilder";
import {Link} from "react-router-dom";
import {AiFillTool} from "react-icons/ai";

const DevToolDialog = ({}) => {
    return (
        <div className={'fixed right-[5px] bottom-[35px] z-50'} onClick={() => {
            // navigate("/dev")
        }}>
            <DialogBasic
                title={"Developer Tools"}
                renderTrigger={
                    <div className={'bg-white p-2 hover:bg-gray-200  cursor-pointer'}>
                        <AiFillTool
                            className="flex-shrink-0 w-6 h-6 transition duration-75  group-hover:text-gray-950 text-[#5856d6]"/>
                    </div>
                }
                renderContent={({toggleOpen}: any) => {
                    return <div className={'min-h-[500px]'}>
                        <div className=" px-4 py-5 sm:p-6">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-4">
                                    <FormThemeColorBuilder/>
                                </div>
                                <div className="col-span-2 space-y-4">


                                    <Link to={"/app/dev/colors"} className={'text-black'}>Colors Page</Link>
                                    <div>
                                        <Link to={"/app/admin/dashboard"} onClick={toggleOpen}
                                              className={`text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}>Admin</Link>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/*<div*/}
                        {/*    className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">*/}
                        {/*    <button type="button"*/}
                        {/*            className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">*/}
                        {/*        Save*/}
                        {/*    </button>*/}
                        {/*    <button type="button"*/}
                        {/*            className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-950 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">*/}
                        {/*        Cancel*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    </div>
                }}
            />
        </div>
    )
}

export {DevToolDialog}
