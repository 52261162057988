import React from "react";
import {DialogBasic} from "front-lib"
import EnvironmentForm from "../forms/EnvironmentForm";
import {useWorkspace} from "../../context/useWorkspace";
import {FaRegEdit} from "react-icons/fa";
import {MdEditNote } from "react-icons/all";

const DialogEditEnvironment = ({env, onEdit}: any) => {

    const {editEnvironment} = useWorkspace()
    return (
        <>
            <DialogBasic
                // size={"xl"}
                title={"Edit Environment"}
                className={'min-w-md'}
                renderTrigger={<button type="button"
                                       className="p-1 text-gray-500 rounded-lg hover:text-gray-950 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600">
                    <MdEditNote  className={'w-9 h-9 text-primary-600 dark:text-primary-400'}/>
                </button>}
                renderContent={({toggleOpen}: any) => {
                    return <>
                        <EnvironmentForm env={env} onSubmit={async ({dirtyValues}: any) => {
                            console.log("DialogEditEnvironment: dirtyValues", dirtyValues)
                            // await crudApi.edit({id: puppet.id, ...dirtyValues});
                            // await loadRows();
                            const entity = {id: env.id, ...dirtyValues};
                            await editEnvironment(entity)
                            onEdit && await onEdit(entity);
                            toggleOpen()
                        }} onCancel={toggleOpen}/>
                    </>
                }}
            />
        </>
    )
}

export default DialogEditEnvironment