import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useReturnUrl} from "../hooks";
import {
    ButtonFormSubmit,
    FormGlobalErrorMessage,
    FormInputPassword,
    FormInputText,
    ProtectedByReCaptchaText, withForm
} from "front-lib";
import {useAuthContext} from "../context";

const LoginForm = withForm(() => {
    const {loginAndLoadMyProfile} = useAuthContext();
    const navigate = useNavigate();
    const returnUrl = useReturnUrl();
    const doLogin = async ({fieldValues}: any) => {
        const user = await loginAndLoadMyProfile(fieldValues);
        navigate(returnUrl);
    };

    return (
        <>
            <div
                className="w-full md:mt-0 sm:max-w-md dark:bg-gray-900 dark:border-gray-700 mx-auto px-4">
                <div className="py-8 space-y-1  ">

                    <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-950 md:text-2xl dark:text-white">
                        Enter your credentials to access your account.
                    </h1>
                    <div className="space-y-4 md:space-y-6">
                        <div>
                            <FormInputText label={'Your email'} name={"email"}
                                // autoFocus
                                           placeholder={"name@company.com"}
                                // defaultValue={'eldar@frontenvs.com'}
                                           rules={{
                                               required: true,
                                           }}
                            />
                        </div>
                        <div>
                            <FormInputPassword
                                label={"Your password"}
                                name={"password"}
                                // defaultValue={'1q2w3e4r'}
                                rules={{
                                    required: true,
                                }}
                            />
                            <p className="mt-2 text-sm font-light text-gray-500">
                                <Link to={"/forgot-password"}>
                                    Forgot your password?
                                </Link>
                            </p>
                        </div>
                        {/*<div>*/}
                        {/*    <FormCheckbox label={"Remember me"} name={"rememberMe"}/>*/}
                        {/*</div>*/}
                        <ButtonFormSubmit onSubmit={doLogin} text={"Login"} className={'w-full'}/>
                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                            Don't have an account? <Link to={"/register"}
                                                         className="font-medium text-primary-600 hover:underline dark:text-primary-500">Register
                            here</Link>
                        </p>
                        <FormGlobalErrorMessage/>
                    </div>
                </div>
            </div>
            <div className={'mx-auto sm:max-w-md'}>
                <ProtectedByReCaptchaText/>
            </div>
        </>
    );
})

export {LoginForm};

