import React, {useEffect, useState} from "react";
import {Breadcrumbs} from "front-lib";
import EnvironmentIframe from "./EnvironmentIframe";
import DialogEditEnvironment from "../app/workspace/environments/dialogs/DialogEditEnvironment";
import {LabelEnvLoaderUrl} from "../app/workspace/environments/labels/LabelEnvLoaderUrl";
import Browser from "../site/hero/Browser";
import DialogReviewBuild from "../app/workspace/environments/dialogs/DialogReviewBuild";
import {MdEditNote} from "react-icons/all";
import {useEnvironmentLoader} from "./context/useEnvironmentLoader";
import {MyFirstEnvBanner} from "./banners/MyFirstEnvBanner";


const EnvironmentEditor = ({env, build}: any) => {

    const {envLoaderUrl} = useEnvironmentLoader();
    const breadcrumbs = [
        {text: "Environments", to: "/app"},
        {text: env.key},
        {text: build.key},
    ]

    return (<>
            <header className="">
                <div className="mx-auto pt-4 flex justify-between items-center">
                    <div>
                        <h1 className="text-3xl font-bold tracking-tight text-gray-950 dark:text-white">
                            Environment Editor
                        </h1>
                        <Breadcrumbs breadcrumbs={breadcrumbs}/>
                    </div>
                    <div className={''}>
                        {/*<DialogEditEnvironment env={env}/>*/}
                        <DialogReviewBuild environment={env}
                                           build={build} renderTrigger={() => <button type="button"
                                                                                      className="p-1 text-gray-500 rounded-lg hover:text-gray-950 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600">
                            <MdEditNote className={'w-9 h-9 text-primary-600 dark:text-primary-400'}/>
                        </button>}/>
                    </div>
                </div>
            </header>
            <div className={'mt-3 '}>
                <MyFirstEnvBanner/>
                {/*<LabelEnvLoaderUrl env={env} build={build}/>*/}
            </div>

            <div className={'mt-3 mb-20'}>
                <Browser title={'frontenvs'}
                         url={envLoaderUrl}>
                    <EnvironmentIframe env={env} build={build} className={'h-[calc(100vh-330px)]'}/>
                </Browser>
            </div>
            {/*<div className={'pb-20'}>*/}
            {/*    <div className={'dark:bg-gray-900 bg-white min-h-[600px] mt-5 '}>*/}
            {/*        <pre>{JSON.stringify({env}, null, 2)}</pre>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    )
}

export default EnvironmentEditor
