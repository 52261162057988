import React from "react";
import {Helmet} from "react-helmet-async";
import {LinkPreviewBox} from "frontenvs-lib";
import {Avatar, CardProfileList, LazyLabelDateTime, useUser} from "front-lib";
import {useMyReferrals} from "../../../modules/app/referrals/context/useMyReferrals";
import {VscReferences} from "react-icons/vsc";
import {AiOutlineTrophy} from "react-icons/ai";

const VITE_APP_UI_URL = import.meta.env.VITE_APP_UI_URL;
const ReferralsRoute = ({}) => {
    const {user} = useUser();

    const url = `${VITE_APP_UI_URL}/register/${user.referralId}`;

    const {myReferrals} = useMyReferrals();
    return (
        <>
            <Helmet>
                <title>Referrals</title>
            </Helmet>
            {myReferrals && <section className="">
                <div className="px-4 mx-auto max-w-screen-xl lg:px-5">
                    <div className="max-w-screen-md">
                        <header className="">
                            <div className="mx-auto py-6">
                                <h1 className="text-3xl font-bold tracking-tight text-gray-950 dark:text-white"> Invite,
                                    Earn, Level
                                    Up</h1>
                            </div>
                        </header>
                        <div className={''}>
                            <p className="mb-8 font-light text-gray-500 sm:text-xl dark:text-gray-400">
                                With our referral program, you'll have access to your own unique referral link that you
                                can share with your friends and easily track your progress towards earning bonuses in your account.
                            </p>
                            <div>
                                <LinkPreviewBox url={url}/>
                            </div>
                            {/*<p className="mb-8 font-light text-gray-500 sm:text-xl dark:text-gray-400">*/}
                            {/*    With our referral program, you'll have access to your own unique referral link that you*/}
                            {/*    can share with your*/}
                            {/*    fellow streamers and easily track your progress towards earning bonuses in your account.*/}
                            {/*</p>*/}
                            {/*<p className="mt-8 font-light text-gray-500 sm:text-xl dark:text-gray-400">*/}
                            {/*    share the following link with your fellow streamer to get extra exp points:*/}
                            {/*</p>*/}
                            {/*<p className="mt-8 font-light text-gray-500 sm:text-xl dark:text-gray-400">*/}
                            {/*    If you not sure what you add, you can use ChatGPT pre made text:*/}
                            {/*</p>*/}
                            {/*<pre className={"whitespace-pre-wrap bg-gray-100 p-4 "}>*/}
                            {/*    "Hey fellow streamers, I've recently started using frontenvs for my streams and I've*/}
                            {/*    been blown away by the level of interactivity it offers. it allows my viewers to engage with me in real-time through donations and messages.*/}
                            {/*    Plus, with the ability to moderate content, I have peace of mind knowing that my streams*/}
                            {/*    are always safe. Give it a try, I'm sure you'll love it as much as I do!"*/}
                            {/*</pre>*/}
                            <div>
                                <div className="flex flex-wrap -mx-3 my-10">

                                    <div className="w-1/2 px-3">
                                        <div
                                            className="w-full bg-white dark:bg-gray-950 dark:text-gray-300 border dark:border-gray-950 text-blue-400 rounded-lg flex items-center p-6 mb-6 xl:mb-0">
                                            <VscReferences className="w-14 h-14 fill-current mr-4 hidden lg:block"/>
                                            <div className="text-gray-700 dark:text-gray-300">
                                                <p className="font-semibold text-3xl">{myReferrals.count}</p>
                                                <p>Referrals</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-1/2  px-3">
                                        <div
                                            className="w-full bg-white dark:bg-gray-950 dark:text-gray-300 border dark:border-gray-950 text-blue-400 rounded-lg flex items-center p-6">

                                            <AiOutlineTrophy className="w-14 h-14 fill-current mr-4 hidden lg:block"/>
                                            <div className="text-gray-700 dark:text-gray-300">
                                                <p className="font-semibold text-3xl">{myReferrals.exp.toLocaleString("en-US")}</p>
                                                <p>Exp points</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div>
                                <h1 className="mb-4 text-xl font-bold text-gray-700 dark:text-gray-300">My
                                    referrals</h1>
                                {myReferrals.referrals && myReferrals.referrals.length > 0 &&
                                    <CardProfileList options={myReferrals.referrals} renderOption={(referral: any) => {
                                        return <li className="flex items-center justify-between my-4 mx-2"
                                                   key={`profile_${referral.id}`}>
                                            <div className={'flex items-center'}>
                                                <div className={'h-11 w-11'}>
                                                    <Avatar url={referral.avatar}/>
                                                </div>
                                                <span className={'ml-2 mr-2'}>
                                                   {referral.firstname} {referral.lastname}
                                        </span>


                                            </div>
                                            <span className={'text-gray-400'}>
                                                    <LazyLabelDateTime timestamp={referral.createdAt}/>
                                            </span>
                                        </li>
                                    }}/>}
                                {!myReferrals || !myReferrals.referrals.length && <p className={"text-gray-500"}>
                                    No referrals yet.<br/>
                                    You can invite friends using your referral link.
                                </p>}
                            </div>
                        </div>
                    </div>

                </div>
            </section>}


        </>
    )
}

export default ReferralsRoute