import React, {ReactNode} from "react";

interface ITabProps {
    label: ReactNode | string;
    onClick: any;
    isActive: boolean;

    [x: string]: any;
}

// Each individual Tab.
const Tab = ({label, onClick, isActive}: ITabProps) => {

    const tabStyle = isActive ? 'inline-block p-4 text-primary-600 border-primary-600 rounded-t-lg   dark:text-primary-500 dark:border-primary-500' : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
    return <li className="mr-2">
        <a onClick={onClick}
           className={`inline-block p-4 border-b-2 cursor-pointer rounded-t-lg  ${tabStyle} `}>
            {label}
        </a>
    </li>

};

export {Tab}
