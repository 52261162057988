import React from "react";
// import DialogContactUs from "../../../site/contact-us/dialogs/DialogContactUs";
import {
    ButtonFormSubmit, FormGlobalErrorMessage,
    FormGlobalSuccessMessage, FormInputText, ProtectedByReCaptchaText, ValidationError, isValidEmail, withForm
} from "front-lib";
import {useAuthApi} from "../api";

const ForgotPasswordForm = withForm(({}) => {

    const {requestNewPassword} = useAuthApi();
    const onSubmit = async ({fieldValues}: any) => {
        await requestNewPassword(fieldValues.email);
        return "Please check your email"
    };

    return (
        <>
            <div className="flex flex-col items-center justify-center px-6 py-8">
                <div
                    className="w-full p-6 bg-white md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
                    <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-950 md:text-2xl dark:text-white">
                        Change Password
                    </h2>
                    <div className="mt-4 space-y-4 lg:mt-5 md:space-y-5">

                        <FormInputText label={'Your email'} name={"email"}
                            // autoFocus
                                       placeholder={"name@company.com"}
                                       rules={{
                                           required: true,
                                           validate: (value: string) => {
                                               const isValid = isValidEmail(value)
                                               return isValid || ValidationError.ERROR_NOT_VALID_EMAIL
                                           }
                                       }}
                        />
                        <ButtonFormSubmit onSubmit={onSubmit} text={" Request password"}
                                          className={'w-full'}/>
                        {/*<p className="text-sm font-light text-gray-500 dark:text-gray-400">*/}
                        {/*    Need help? <DialogContactUs source={"auth-forgot-password"}/>*/}
                        {/*</p>*/}
                        <div>
                            <FormGlobalErrorMessage/>
                            <FormGlobalSuccessMessage/>
                        </div>
                    </div>


                </div>
                <div className={'mx-auto sm:max-w-md'}>
                    <ProtectedByReCaptchaText/>
                </div>
            </div>

        </>
    )
})

export {ForgotPasswordForm}