import React from "react";
import {Helmet} from "react-helmet-async";
import TranslationTable from "../../../../modules/admin/crud/entities/translation/TranslationTable";

const TranslationsRoute = ({}) => {
    return (
        <>
            <Helmet>
                <title>Translations</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl'}>
                <TranslationTable/>
            </div>
        </>
    )
}

export default TranslationsRoute