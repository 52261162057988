import React, {useState} from "react"
import {LoadingCover, withForm} from "front-lib";
import {StepCreateEnvironment} from "./steps/StepCreateEnvironment";
import {StepTriggerFirstEnvBuild} from "./steps/StepTriggerFirstEnvBuild";
import "./style/wizard-create-env.scss";
import {useWorkspace} from "../../context/useWorkspace";

const CreateEnvironmentWizard = withForm(({onCompleted}: any) => {
    const {goNext, goBack, step} = useWizard();

    const {createEnvironment, buildEnv, loadEnvsAndBuildsData} = useWorkspace()
    const [isLoading, setIsLoading] = useState(false);

    const finishWizard = async ({fieldValues}: any) => {
        console.log("finishWizard", fieldValues);
        setIsLoading(true);
        try {
            // await sleep(2000);
            const {key} = await createEnvironment(fieldValues)
            if (fieldValues.shouldTriggerBuild) {
                await buildEnv(key);
            }
            await loadEnvsAndBuildsData();
            await onCompleted();

        } catch (err) {
            setIsLoading(false);
            throw err;
        }
    }
    return (
        <>

            {/*<div className={'grid grid-cols-12 gap-6 px-6'}>*/}
            {/*    <div className={'col-span-12'}>*/}
            {/*        <Stepper step={step}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {step == 1 && <StepCreateEnvironment onSubmit={goNext}/>}
            {step == 2 && <StepTriggerFirstEnvBuild onCompleted={finishWizard} onBack={goBack}/>}
            {/*{step == 3 && <StepWizardSummary onCompleted={onCompleted} onBack={goBack}/>}*/}
            <LoadingCover isLoading={isLoading} text={"Creating environment..."}
                          className={'absolute flex z-50 items-center w-full top-[70px] left-0 bg-white dark:bg-gray-950 min-h-[calc(100%-70px)]'}/>

        </>
    )
})


const useWizard = () => {
    const [step, setStep] = useState(1)
    const goNext = async () => {
        // await createEnvironment(fieldValues);
        setStep(s => s + 1);
    }
    const goBack = async () => {
        console.log("onBack");
        setStep(s => s - 1);
    }

    return {step, goNext, goBack}
}
const Stepper = ({step}: any) => {

    return <>

        <ol className="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4 rtl:space-x-reverse">
            <Step index={1} text={"Environment"} isActive={step >= 1}/>
            <Step index={2} text={"Configs"} isActive={step >= 2}/>
            <Step index={3} text={"Summary"} isActive={step >= 3} chevron={false}/>
        </ol>
    </>
}

const Step = ({isActive, index, text, chevron = true}: any) => {
    return <li className={`flex items-center ${isActive ? 'wizard-step-header-active' : ''}`}>
                <span
                    className="flex items-center justify-center w-5 h-5 mr-2 text-xs border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
                    {index}
                </span>
        {text}
        {chevron && <svg className="w-3 h-3 ml-2 sm:ml-4 rtl:rotate-180" aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="m7 9 4-4-4-4M1 9l4-4-4-4"/>
        </svg>}
    </li>
}

// <li className="flex items-center text-primary-600 dark:text-primary-500">
//                 <span
//                     className="flex items-center justify-center w-5 h-5 mr-2 text-xs border border-primary-600 rounded-full shrink-0 dark:border-primary-500">
//                     1
//                 </span>
//     Environment&nbsp;<span className="hidden sm:inline-flex">Info</span>
//     <svg className="w-3 h-3 ml-2 sm:ml-4 rtl:rotate-180" aria-hidden="true"
//          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
//         <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
//               d="m7 9 4-4-4-4M1 9l4-4-4-4"/>
//     </svg>
// </li>
export {CreateEnvironmentWizard}