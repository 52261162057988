import React from "react";
import SocialIcons from "./SocialIcons";

const AdminFooter = ({}) => {
    return (
        <>
            <footer
                className="shadow  flex  items-center justify-center sm:justify-between p-4  bg-gray-200 dark:bg-gray-900 h-[60px]">
                <p className=" text-sm text-center text-gray-500 dark:text-gray-400 mb-0">
                    &copy; {new Date().getFullYear()} <a href="https://frontenvs.com/" className="hover:underline"
                                                         target="_blank">frontenvs</a>. All rights reserved.
                </p>
                <SocialIcons className={'hidden sm:flex '}/>
            </footer>
        </>
    )
}

export default AdminFooter