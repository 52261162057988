import {api} from "front-lib"
export const useCacheApi = () => {

    const getAllCacheKeys = async (): Promise<any> => {
        console.log("getAllCacheKeys");
        const response = await api.get(`admin/cache`,)
        return response.data;
    }

    const crawlMainCacheEndpoints = async () => {
        console.log("crawlMainCacheEndpoints");
        await clearCache();
        return Promise.all([
            await api.get("/test-cache"),
        ])

    }

    const clearCache = async (): Promise<any> => {
        console.log("clearCache");
        await api.post(`admin/cache/clear`,)
    }

    return {
        crawlMainCacheEndpoints,
        getAllCacheKeys,
        clearCache
    }
}