import React from "react";
import {Avatar, useForm} from "front-lib";
import {Controller} from "react-hook-form";
import {AvatarFileUpload} from "../views/AvatarFileUpload";

const FormUploadAvatar = ({defaultValue, name = "avatar", rules}: any) => {

    const {control, setValue} = useForm();

    return (
        <>
            <Controller
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                name={name}
                render={({field}: any) => (
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Avatar</label>
                        <AvatarFileUpload onChange={field.onChange}>
                            <div className="mt-1 inline-flex items-center cursor-pointer ">
                            <span className="inline-block ">
                                    {/*<Avatar />*/}
                                <Avatar url={field.value} className={'h-12 w-12'}/>
                            </span>
                                <button type="button"
                                        className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Change
                                </button>

                            </div>
                        </AvatarFileUpload>


                    </div>
                )}/>


        </>
    )
}

export {FormUploadAvatar}