import React, {useEffect} from "react";
import {translationFilters} from "./TranslationFilters";
import {translationColumns} from "./TranslationColumns";
import GenericTable from "../../table/GenericTable";
import DialogCreateTranslation from "./dialogs/DialogCreateTranslation";
import {useTranslationCrudApi} from "./api/useTranslationCrudApi";

const TranslationTable = () => {
    const crudApi = useTranslationCrudApi();
    return (
        <>
            <GenericTable renderHeader={<>
                <div className={'flex items-center'}>
                    <h3 className="font-semibold text-lg">Translations</h3>

                </div>
                <DialogCreateTranslation/>
            </>}
                          crudApi={crudApi}
                          filters={translationFilters}
                          columns={translationColumns}
            />
        </>
    );
};

export default TranslationTable;
