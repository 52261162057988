import React from 'react';
import image from "./logo.png";

const IconLogo = ({className}: any) => {
    return (

        // https://www.flaticon.com/free-icon/code_9675143
        <svg id="a58e8eab-ece7-451e-ace4-d5d0e53fdb33" className={className} height="512" viewBox="0 0 64 64" width="512"
             xmlns="http://www.w3.org/2000/svg" data-name="Layer 1">
            <path
                d="m42.5214 8.3684c-2.4854-.6655-4.3414 2.179-5.077 4.074q-3.6453 9.39-6.7817 18.9667c-1.7743 4.9231-2.8919 8.8625-4.5537 13.8133-.5928 1.6641-1.6309 4.3495-2.0616 6.0727-1.2022 4.57 2.9087 5.8717 5.7078 2.6621a12.9222 12.9222 0 0 0 1.7438-3.4507c4.8463-12.1238 7.556-24.9522 12.2963-37.11.6037-1.5484.814-4.469-1.2739-5.0281zm13.7934 22.4994c-1.4465-1.9176-7.0679-8.8489-8.9787-9.6991-.5826-.2593-2.1678-1.09-3.1715.471-1.2684 1.9726-.75 4.1327.9422 6.4292 1.405 1.9069 2.9541 3.7021 4.4917 5.4909.5754.6694.5622.7776.0262 1.4633-2.2157 2.8341-5.4969 4.2866-7.9938 6.9579a2.5571 2.5571 0 0 0 1.8691 4.239c2.2877.133 3.67-.8868 5.5547-2.1615 2.0079-1.3584 7.5493-5.581 8.4242-6.7063 2.0058-2.4298.7939-4.121-1.1641-6.4844zm-33.9068 9.034c-11.5688-8.9435-10.0251-4.3632.2771-14.9643 1.21-1.3113 1.5335-3.075-.2487-4.1329a3.2847 3.2847 0 0 0 -4.0867.0869 99.0529 99.0529 0 0 0 -9.9763 8.7015c-.9329 1.0459-2.9278 2.8918-2.882 4.2676.0458 2.0637 1.523 3.3937 3.9905 5.099 2.756 1.9047 5.5284 3.4032 8.4244 5.099 1.8389 1.0767 4.514 2.48 5.6765.0583 1.1006-2.293-.1546-3.3412-1.1748-4.2151z"/>
        </svg>
        // <svg fill="none" height="512" className={`${className}`} viewBox="0 0 24 24" width="512"
        //      xmlns="http://www.w3.org/2000/svg">
        //     <path clipRule="evenodd"
        //           d="m14.4578 7.28735c.1587-.52899-.1415-1.08647-.6705-1.24517-.5289-.1587-1.0864.14148-1.2451.67048l-3.00003 10.00004c-.15869.5289.14149 1.0864.67053 1.2451.5289.1587 1.0864-.1415 1.2451-.6704zm-6.75069.00555c.39052.39052.39052 1.02369 0 1.41421l-3.2929 3.29289 3.2929 3.2929c.39052.3905.39052 1.0237 0 1.4142-.39053.3905-1.02369.3905-1.41422 0l-4-4c-.39052-.3905-.39052-1.0237 0-1.4142l4-4c.39053-.39053 1.02369-.39053 1.41422 0zm8.58579 0c-.3905.39052-.3905 1.02369 0 1.41421l3.2929 3.29289-3.2929 3.2929c-.3905.3905-.3905 1.0237 0 1.4142s1.0237.3905 1.4142 0l4-4c.3905-.3905.3905-1.0237 0-1.4142l-4-4c-.3905-.39053-1.0237-.39053-1.4142 0z"
        //           fillRule="evenodd"/>
        // </svg>
        // <img src={image} className={className}/>
    )
}

export {IconLogo}