import React from "react"
import {LinkPreviewBox} from "frontenvs-lib";

const LabelEnvLoaderUrl = ({env, build}: any) => {
    const envLoaderUrl = (() => {
        if (build) {
            return `http://localhost:9090/e/${env.key}/${build.key}`;
        }
        return `http://localhost:9090/e/${env.key}`;
    })()

    return (
        <LinkPreviewBox url={envLoaderUrl}/>
    )
}

export {LabelEnvLoaderUrl}