import React from "react"
import {DialogBasic, IconLogo, VisibleIfAuthenticated, VisibleIfNotAuthenticated} from "front-lib";
import EnvManagerForm from "../forms/EnvManagerForm";
import BrandName from "../../layout/brand/BrandName";
import {WelcomeToEnvLoaderForm} from "../forms/WelcomeToEnvLoaderForm";

const DialogEnvLoaderManager = ({env, build}: any) => {
    console.log("DialogEnvLoaderManager")
    return (
        <>
            <DialogBasic
                title={<BrandName to={"/app"}/>}
                renderTrigger={<div className={'absolute top-[120px] left-[0px] z-50'}>
                    <button type="button"
                            className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium  text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        <IconLogo className="w-8 h-8 text-white fill-[#fff]"/>
                    </button>
                </div>}
                renderContent={({toggleOpen}: any) => {
                    return <>
                        <VisibleIfAuthenticated>
                            <EnvManagerForm env={env} build={build}/>
                        </VisibleIfAuthenticated>
                        <VisibleIfNotAuthenticated>
                            <WelcomeToEnvLoaderForm/>
                        </VisibleIfNotAuthenticated>
                    </>
                }}
            />
        </>
    )
}

export {DialogEnvLoaderManager}