import React, {createContext, ReactNode, useContext, useEffect, useRef, useState} from "react";
import {ICellComponent, IGenericTableProps} from "../GenericTable";

const GenericTableContext = createContext<any | undefined>(undefined);


interface IContextProps extends IGenericTableProps {
    children: ReactNode;
}

export interface ICrudFilters {
    orderDirection: "asc" | "desc";
    orderBy: string;

    [filterProperty: string]: any;
}

export const GenericTableProvider = ({
                                         children,
                                         filters,
                                         crudApi,
                                         columns: defaultColumns,
                                         renderHeader,
                                         pageSize: defaultPageSize = 50,
                                         onRowClick,
                                     }: IContextProps) => {

    const [rows, setRows] = useState(undefined);
    const [columns, setColumns] = useState(defaultColumns);
    const [selectedRows, setSelectedRows] = useState([]);
    const [totalCount, setTotalCount] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const visibleColumns = columns.filter((c: IColumn) => c.isVisible);
    const [shouldShowFilters, setShouldShowFilters] = useState(false);
    const {rowsCreatedCount, rowsDeletedCount} = crudApi;
    const [crudFilters, setCrudFilters] = useState<any>({
        orderBy: "createdAt",
        orderDirection: "desc",
        page: 0,
        pageSize: defaultPageSize,
        where: {}
    });
    // console.log("crudFilters", crudFilters)
    const {page, pageSize, orderBy, orderDirection} = crudFilters;
    const dense = false;
    const isLoadingFirstTime = (rows === undefined) && isLoading;
    // @ts-ignore
    const isTableEmpty = rows && rows.length === 0;
    const loadRows = async () => {
        // console.log("loadRows", crudFilters);
        setIsLoading(true);
        setIsError(false);
        try {
            const {rows, count: totalCount}: any = await crudApi.getMany(crudFilters);
            setRows(rows);
            setTotalCount(totalCount);

        } catch (err) {
            setIsError(true);
            console.error(err);
        } finally {
            setIsLoading(false);
            clearRowsSelection();
        }

    };

    const deleteRows = async (ids: string[]) => {
        setIsLoading(true);
        await crudApi.deleteMany(ids);
        // await loadRows();
        setIsLoading(false);
    };

    const editRow = async (newRow: any) => {
        const updatedRow = await crudApi.edit(newRow);
        // @ts-ignore
        const newRows = rows.map((r: any) => {
            if (r.id === updatedRow.id) {
                return {
                    ...r,
                    ...updatedRow
                };
            }
            return r;
        });

        setRows(newRows);
    };

    const selectRow = (row: any) => {
        const {id} = row;
        // @ts-ignore
        const selectedIndex = selectedRows.indexOf(id);
        let newSelected: any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }
        // @ts-ignore
        setSelectedRows(newSelected);
    };

    const selectAllRows = () => {
        // const newSelectedRows = rows.map((n: any) => n.id);
        // @ts-ignore
        const newSelectedRows = rows.filter((r: any) => !r.isDeleted).map((n: any) => n.id);
        setSelectedRows(newSelectedRows);
    };

    const clearRowsSelection = () => {
        setSelectedRows([]);
    };

    const onFiltersChange = (filter: IFilter) => {
        // console.log("onFiltersChange", filter);
        setCrudFilters((filters: ICrudFilters) => {
            const newFilters = {
                ...filters,
                page: 0,
                where: {
                    ...filters.where,
                    [filter.property]: {
                        value: filter.value,
                        type: filter.whereType
                    }
                }
            };

            if (filter.value === undefined || filter.value === null || filter.value === "" || (Array.isArray(filter.value) && filter.value.length === 0)) {
                delete newFilters.where[filter.property];
            }
            return newFilters;
        });
    };
    const clearFilters = () => {
        setCrudFilters((filters: ICrudFilters) => {
            return {...filters, where: {}};
        });
    };
    const onSortChange = (property: string) => {
        // console.log("onSortChange");
        let newOrderDirection: "asc" | "desc" = "asc";
        if (orderBy === property) {
            newOrderDirection = orderDirection === "asc" ? "desc" : "asc";
        }

        setCrudFilters((filters: ICrudFilters) => {
            return {...filters, orderBy: property, orderDirection: newOrderDirection};
        });

    };
    const onPageChange = (page: number) => {
        // setPage(page);
        setCrudFilters((filters: ICrudFilters) => {
            return {...filters, page};
        });
    };
    const onPageSizeChange = (pageSize: number) => {
        // setPageSize(pageSize);
        setCrudFilters((filters: ICrudFilters) => {
            return {...filters, pageSize};
        });
    };
    // const updateVisibleColumns = (selectedColumns: any) => {
    //
    //     const newColumns = columns.map((c: IColumn) => {
    //         c.isVisible = Boolean(selectedColumns[`column_${c.property}`]);
    //         return c;
    //     });
    //     setColumns(newColumns);
    //     localStorage.setItem(`gt-${pluralName}-columns`, JSON.stringify(selectedColumns));
    // };
    //
    // function getVisibleDefaultColumns(defaultColumns: IColumn[]) {
    //
    //     const selectedColumns = JSON.parse(localStorage.getItem(`gt-${pluralName}-columns`) as string);
    //
    //     if (selectedColumns) {
    //         return defaultColumns.map((c: IColumn) => {
    //             c.isVisible = Boolean(selectedColumns[`column_${c.property}`]);
    //             return c;
    //         });
    //     }
    //     return defaultColumns;
    // }


    useEffect(() => {
        // console.log("useEffect - start loadRows");
        loadRows();
    }, [crudFilters, rowsCreatedCount, rowsDeletedCount]);

    const context = {
        loadRows,
        rows,
        crudApi,
        isError,
        isTableEmpty,
        totalCount,
        isLoading,
        filters,
        crudFilters,
        columns,
        visibleColumns,
        // pluralName,
        orderDirection,
        shouldShowFilters,
        setShouldShowFilters,
        orderBy,
        onSortChange,
        editRow,
        selectedRows,
        selectRow,
        selectAllRows,
        clearRowsSelection,
        page,
        onPageChange,
        deleteRows,
        pageSize,
        onPageSizeChange,
        onFiltersChange,
        clearFilters,
        // updateVisibleColumns,
        isLoadingFirstTime,
        onRowClick,
        dense,
        renderHeader
        // withToolBars
    };
    return <GenericTableContext.Provider value={context}> {children} </GenericTableContext.Provider>;
};

// function useCrudFiltersFromLocalStorage(pluralName: string, defaultCrudFilters: ICrudFilters) {
//
//     const key = `gt-${pluralName}-crud-filters`;
//     const [crudFilters, setCrudFilters] = useState<ICrudFilters>(getDefaultCrudFiltersFromLocalStorage());
//
//
//     function getDefaultCrudFiltersFromLocalStorage(): ICrudFilters {
//         // const valueFromStorage = JSON.parse(localStorage.getItem(key)) as ICrudFilters;
//         return {
//             ...defaultCrudFilters,
//             // ...valueFromStorage
//         };
//     }
//
//     useEffect(() => {
//         // localStorage.setItem(key, JSON.stringify(crudFilters));
//     }, [crudFilters]);
//
//     return {crudFilters, setCrudFilters};
// }


export const useGenericTable = () => {
    const context = useContext(GenericTableContext);

    if (context === undefined) {
        throw new Error("useGenericTable must be used within GenericTableProvider");
    }
    return context;
};

export interface IFilter {
    property: string,
    label: string,
    componentProps?: any,
    value?: any,
    componentType: "text" | "select",
    whereType?: "in" | "like" | "range" | undefined | null,
    getOptions?: () => any[]
}

export interface IColumn {
    property: string,
    label: string,
    // disablePadding?: boolean,
    disableSorting?: boolean,
    component: React.FunctionComponent<ICellComponent>,
    isVisible?: boolean
}
