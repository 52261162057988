import React, {createContext, useEffect, useState} from "react";
import {Theme, ThemeService} from "../services/ThemeService";


export const ThemeContext = createContext<any | undefined>(undefined);

const ThemeProvider = ({children}: any) => {
    // console.log("ThemeProvider")

    const [theme, setTheme] = useState<Theme>(ThemeService.getDefaultTheme())
    const [isDarkMode, setIsDarkMode] = useState(false)

    const setDarkMode = (isDarkMode: any) => {
        setIsDarkMode(isDarkMode)
        ThemeService.setDarkMode(isDarkMode)
        // if (isDarkMode) {
        //     document.documentElement.classList.add('dark');
        // } else {
        //     document.documentElement.classList.remove('dark');
        // }
    }
    const setThemeColor = (colorName: string, hex: string) => {
        // console.log("setThemeColor", theme)
        const shades = ThemeService.getShades(hex);
        ThemeService.setThemeColor(colorName, shades)

        setTheme(theme => {
            return {
                ...theme,
                palette: {
                    ...theme.palette,
                    [colorName]: {
                        main: shades[5],
                        shades: shades,
                    }
                }
            }
        })
    }


    const value = {
        theme,
        setThemeColor,
        isDarkMode,
        setDarkMode
    }
    return (
        <ThemeContext.Provider value={value}>
            <div>
                {children}
            </div>
        </ThemeContext.Provider>

    )
}

export {ThemeProvider}