import React from "react"
import {Role} from "front-lib";

interface IProps {
    role: Role;
}

const LabelRole = ({role}: IProps) => {
    return (
        <>
            {Role[role]}
        </>
    );
};

export default LabelRole;
