import React from "react";
import {FormInputText} from "./FormInputText";

const FormInputPassword = (props: any) => {

    return (
        <FormInputText placeholder={"••••••••"} type={"password"} {...props}/>
    );
};

export {FormInputPassword};
