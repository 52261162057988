import React from 'react';
import {Helmet} from 'react-helmet-async';
import {Link} from 'react-router-dom';
import {FaIcons} from "react-icons/fa";
import {LinkPreviewBox} from "frontenvs-lib";
import {VscReferences} from "react-icons/vsc";
import {AiOutlineTrophy} from "react-icons/ai";
import {Avatar, CardProfileList, LazyLabelDateTime} from "front-lib";
import {Dashboard} from "../../../modules/app/dashboard/Dashboard";
import {GettingStartedView} from "../../../modules/app/dashboard/GettingStartedView";
import {useWorkspace} from "../../../modules/app/workspace/context/useWorkspace";

function AppHomeRoute() {

    // const {stats} = useWorkspace();
    // const {environmentsCount} = stats;
    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <section className="">
                <Dashboard/>
            </section>
        </>
    );
}

export default AppHomeRoute;
