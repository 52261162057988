import React from "react";
import {IColumn} from "../../table/context/GenericTableContext";
import DialogEditNotification from "./dialogs/DialogEditNotification";
import {Avatar, LazyLabelDateTime} from "front-lib";
export const notificationColumns: IColumn[] = [
    {
        property: "actions",
        label: "Actions",
        isVisible: true,
        disableSorting: true,
        component: ({row, column}) => <div className={'flex items-center'} onClick={(event) => event.stopPropagation()}>
            <DialogEditNotification notification={row}/>
        </div>
    },
    {
        property: "id",
        label: "ID",
        isVisible: false,
        component: ({row, column}) => <>
            {row.id}
        </>
    },
    {
        property: "user",
        label: "User",
        isVisible: true,
        component: ({row, column}) => <div className={'flex items-center'}>
            <div className={'h-11 w-11'}>
                <Avatar url={row.user.profile.avatar}/>
            </div>
            <span className={'ml-2'}>{row.user.profile.firstname} {row.user.profile.lastname}</span>

        </div>
    },

    {
        property: "type",
        label: "Type",
        isVisible: true,
        component: ({row, column}) => <>
            {row.type}
        </>
    },
    {
        property: "createdAt",
        label: "Created",
        isVisible: true,
        component: ({row, column}) => <>
            <LazyLabelDateTime timestamp={row.createdAt}/>
        </>
    },

];
