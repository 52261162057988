import React, {useState} from "react";
import {Link} from "react-router-dom";
import BrandName from "../brand/BrandName";

const SiteHeader = ({}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <header className={'fixed w-full z-40 bg-gray-100 dark:bg-black h-[60px] '}>
                <nav className="border-gray-200 px-6 py-2.5 max-w-screen-xl mx-auto">
                    <div className="flex flex-wrap justify-between items-center  ">
                        <BrandName/>
                        <div className={'flex '}>
                            <div className="flex items-center lg:order-2">
                                {/*<Link to={"/login"}*/}
                                {/*      className="text-gray-800 dark:text-white hover:bg-gray-50 hover:underline focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">*/}
                                {/*    Log in*/}
                                {/*</Link>*/}

                                <Link to={"/register"}
                                      className="text-white sm:ml-5 mr-0 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5  dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                                    Register
                                </Link>

                                <button type="button"
                                        onClick={() => {
                                            setIsOpen(!isOpen)
                                        }}
                                        className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                                        aria-controls="mobile-menu-2" aria-expanded="false">
                                    <span className="sr-only">Open main menu</span>
                                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                              clipRule="evenodd"/>
                                    </svg>
                                    <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                              clipRule="evenodd"/>
                                    </svg>
                                </button>
                            </div>
                            <div
                                className={`${isOpen ? '' : 'hidden'}  bg-white dark:bg-gray-800 lg:bg-transparent lg:!bg-transparent absolute left-6 right-6 top-[60px] lg:static   justify-between items-center  lg:flex lg:w-auto lg:order-1`}>
                                <ul className="flex flex-col font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                                    {/*<li className={'mb-0'}>*/}
                                    {/*    <a href="https://facebook.com/frontenvs"*/}
                                    {/*       target={"_blank"}*/}
                                    {/*       className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 hover:underline lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"*/}
                                    {/*       aria-current="page">Reports</a>*/}
                                    {/*</li>*/}
                                    {/*<li className={'mb-0'}>*/}
                                    {/*    <a href="https://join.slack.com/t/frontenvs/shared_invite/zt-1vd3nj0c7-PCCsXIjWdTIV~kw7mHa7Gg"*/}
                                    {/*       target={"_blank"}*/}
                                    {/*       className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 hover:underline lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"*/}
                                    {/*       aria-current="page">Community</a>*/}
                                    {/*</li>*/}


                                    <li className={'mb-0'}>
                                        <Link to={"/login"}
                                              className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 hover:underline lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">
                                            Login
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default SiteHeader