import React from "react";
import {useForm} from "../context/useForm";
import {FormFieldErrorMessage} from "../messages/error/FormFieldErrorMessage";
import {Controller} from "react-hook-form";


const FormTextarea = ({
                          name,
                          defaultValue,
                          rules,
                          label,
                          placeholder,
                          rows = "6",
                          autoFocus,
                          disabled,
                          className,
                          ...props
                      }: any) => {
    const {control} = useForm();

    return (

        <div>
            <Controller
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                name={name}
                render={({field}: any) => (
                    <>
                        {label && <label htmlFor={name}
                                         className="block mb-2 text-sm font-medium text-gray-950 dark:text-white">
                            {label}
                        </label>}
                        <textarea id={name}
                                  rows={rows}
                                  {...field}
                                  disabled={disabled}
                                  autoFocus={autoFocus}
                                  className={`${className} bg-gray-50 border border-gray-300 text-gray-950 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-800 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500`}
                                  placeholder={placeholder}
                                  {...props}
                        />
                    </>
                )}
            />

            <FormFieldErrorMessage name={name}/>
        </div>
    );
};

export {FormTextarea};
