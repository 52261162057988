import React from 'react';
import {ConnectWithTwitch, ConnectWithDiscord} from "../buttons";
import {useReferral} from "../hooks";
import {withForm, AlertModern, FormGlobalErrorMessage} from "front-lib";

const ConnectForm = withForm(() => {

    const {referralId, referralProfile} = useReferral();

    return (
        <>
            {referralProfile && <div>
                <div className={'py-2'}>
                    <AlertModern>
                        You've been invited to join frontenvs by <span>{referralProfile.username}</span>.
                    </AlertModern>
                </div>
            </div>}
            <div
                className="w-full md:mt-[75px] sm:max-w-md bg-gray-100 dark:bg-gray-950 p-6 dark:border-gray-700 mx-auto ">
                <div className="py-8 space-y-1  ">

                    <h1 className="text-xl font-bold leading-tight tracking-tight text-center text-gray-950 mb-5 md:text-2xl dark:text-white">
                        Sign in to your account
                    </h1>
                    <div className="space-y-4 md:space-y-4">
                        <ConnectWithTwitch/>
                        <ConnectWithDiscord/>

                        <FormGlobalErrorMessage/>
                    </div>
                </div>
            </div>
            <div className={'sm:max-w-md  mx-auto'}>
                <div className={`text-sm text-gray-400 px-6 mt-2 text-center`}>
                    By connecting your account, you are acknowledging and accepting our&nbsp;<a href="/privacy"
                                                                                                target={"_blank"}
                                                                                                className={'text-gray-500'}>Privacy
                    Policy</a> and&nbsp;<a href="/terms" target={"_blank"}
                                           className={'text-gray-500'}>Terms & Conditions</a>.
                </div>
            </div>
        </>
    );
})

export {ConnectForm}

