import {useParams, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {api, getWithCaptchaToken} from "front-lib";

export const useReferral = () => {
    const [referralProfile, setReferralProfile] = useState<any>(null);

    // const [searchParams] = useSearchParams();
    // const referralId = searchParams.get("r");
    const {referralId} = useParams();
    // console.log("referralId", referralId)

    useEffect(() => {

        (async () => {
            if (referralId) {
                localStorage.setItem("r", referralId);
                const response = await getWithCaptchaToken(`/profile/referral/${referralId}`, "get_referral_profile")
                console.log("response", response)
                setReferralProfile(response.data)
            }
        })()
    }, [referralId]);

    const markAsReferred = async (referralId: string) => {

        await api.put(`referrals/${referralId}/mark-as-referred`,)
    }
    return {referralId, referralProfile, markAsReferred}
}