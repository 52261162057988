import React from 'react';
import {Helmet} from 'react-helmet-async';
import {SocketClientsTable, useSocket, useSocketAdmin, withForm} from "front-lib";

function SocketsRoute() {

    // const {user} = useUser();
    const {connect, isConnected, disconnect} = useSocket();
    const {clients} = useSocketAdmin();


    return (
        <>
            <Helmet>
                <title>Sockets</title>
            </Helmet>

            <div className={'mx-auto max-w-screen-xl'}>
                <header className="">
                    <div className="px-4 py-6">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-950 dark:text-white">Sockets</h1>
                    </div>
                </header>
                <div className={'p-5 '}>
                    <p>Connected: {isConnected ? "Yes" : "No"}</p>
                    <div className={'my-2'}>
                        <button
                            onClick={connect}
                            className="mr-2 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            Connect
                        </button>

                        <button
                            onClick={disconnect}
                            className="mr-2 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            Disconnect
                        </button>
                    </div>
                    <SocketClientsTable clients={clients}/>
                </div>
            </div>
        </>
    );
}

export default withForm(SocketsRoute)

