import React from "react";

interface IProps {
    ticket: any;
}

const LabelTicketStatus = ({ticket}: IProps) => {
    const {isOpen} = ticket;
    const statusClassName  = isOpen ? 'bg-warning-500' : 'bg-gray-300'
    return (
        <span className={`${statusClassName} px-2 py-1 text-white capitalize`}>
      {isOpen ? "Open" : "Closed"}
    </span>
    );
};

export default LabelTicketStatus;
