import React from "react";
import {Helmet} from "react-helmet-async";
import {SystemConfigsForm} from "front-lib";

const SystemConfigsRoute = ({}) => {
    return (
        <>
            <Helmet>
                <title>System Configs</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl'}>
                <header className="">
                    <div className="py-6 ">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-950 dark:text-white">System
                            Configs</h1>
                    </div>
                </header>
                <div className={'p-5'}>
                    <div>
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-950 dark:text-gray-300">System
                                        Configs</h3>
                                    <p className="mt-1 text-sm text-gray-500">This information will be displayed
                                        publicly so
                                        be
                                        careful what you share.</p>
                                </div>
                            </div>
                            <div className="mt-5 md:col-span-2 md:mt-0">
                                <div className="shadow sm:overflow-hidden sm:rounded-md">
                                    <SystemConfigsForm/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default SystemConfigsRoute