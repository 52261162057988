import React from "react";
import {ticketFilters} from "./TicketFilters";
import {ticketColumns} from "./TicketColumns";
import GenericTable from "../../table/GenericTable";
import {useTicketCrudApi} from "./api/useTicketCrudApi";

const TicketTable = () => {
    const crudApi = useTicketCrudApi();
    return (
        <>
            <GenericTable renderHeader={<>
                <div className={'flex items-center'}>
                    <h3 className="font-semibold text-lg">Tickets</h3>
                    {/*<button id="toggleSidebar" aria-expanded="true" aria-controls="sidebar"*/}
                    {/*        onClick={() => {*/}
                    {/*        }}*/}
                    {/*        className="p-2 ml-1 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-950 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700">*/}
                    {/*    <RiFilter3Line size={22}/>*/}
                    {/*</button>*/}
                </div>
                {/*<DialogCreateTicket/>*/}
            </>}
                          crudApi={crudApi}
                          filters={ticketFilters}
                          columns={ticketColumns}
            />
        </>
    );
};

export default TicketTable;
