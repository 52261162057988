import {
    FormGlobalErrorMessage,
    FormGlobalSuccessMessage,
    FormInputText,
    FormTextarea,
    useForm,
    withForm
} from "front-lib";
import { FormUploadAvatar } from "frontenvs-lib";
import React from "react";



interface IProps {
    workspace: any;
    onSubmit?: (formData?: any) => Promise<void>;
    onCancel?: () => void
}

const WorkspaceForm = ({workspace, onSubmit, onCancel}: IProps) => {
    const {handleSubmit} = useForm();

    return (
        <>
            <div className="space-y-6 bg-white dark:bg-gray-900 px-4 py-5 sm:p-6">
                <div>
                    <FormUploadAvatar defaultValue={workspace.avatar}/>
                </div>
                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6  sm:col-span-3">
                        <FormInputText label={"Name"} name={"name"}
                                       defaultValue={workspace.name}/>
                    </div>
                    <div className="col-span-6 sm:col-span-4">
                        <FormTextarea label={"Description"} name={"description"} defaultValue={workspace.description}/>
                    </div>

                    {/*<div className="col-span-6 ">*/}
                    {/*    <label*/}
                    {/*        className="block mb-2 text-sm font-medium text-gray-950 dark:text-gray-300">*/}
                    {/*        Profiles*/}
                    {/*    </label>*/}
                    {/*    {workspace.profiles && <AvatarList profiles={workspace.profiles} max={20}/>}*/}

                    {/*</div>*/}

                    {/*<div className="col-span-6 ">*/}
                    {/*    <label*/}
                    {/*           className="block mb-2 text-sm font-medium text-gray-950 dark:text-gray-300">*/}
                    {/*       Puppets*/}
                    {/*    </label>*/}
                    {/*    {workspace.puppets && <AvatarList profiles={workspace.puppets} max={20}/>}*/}

                    {/*</div>*/}
                    <div className="col-span-6">
                        <FormGlobalErrorMessage/>
                        <FormGlobalSuccessMessage/>
                    </div>
                </div>
            </div>

            <div className="bg-gray-50 dark:bg-gray-900 px-4 py-3 text-right sm:px-6">
                {/*<ButtonFormSubmit/>*/}
                <button type="submit"
                        onClick={() => {
                            console.log("before on submit")
                            //make sure all the form is valid
                            handleSubmit(onSubmit)

                            // console.log("after on submit")
                        }}
                        className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">Save
                </button>
            </div>
        </>);

};

export default withForm(WorkspaceForm)
