import React from "react";

const FaqOpenList = ({data}: any) => {
    return (
        <>
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
                <div className="space-y-4"><h2 className="text-4xl font-extrabold ">Frequently asked questions</h2>
                    <p className="text-gray-500 dark:text-gray-400">Can't find the answer you're looking for?
                        Don't hesitate, you can send us a message on <a target="_blank"
                                                                         href="https://join.slack.com/t/frontenvs/shared_invite/zt-1vd3nj0c7-PCCsXIjWdTIV~kw7mHa7Gg"
                                                                         rel="noreferrer"
                                                                         className={'text-[#4A154B]'}>Slack</a>.
                        {/*<a target="_blank"*/}
                        {/*   href="https://discord.gg/J96zSQX7jU"*/}
                        {/*   rel="noreferrer"*/}
                        {/*   className={'text-discord'}>Discord</a>.*/}
                    </p>

                </div>
                <div className="mt-12 lg:col-span-2 lg:mt-0">
                    <dl className="space-y-12">
                        {data.map((item: any) => {
                            return <div key={`faq-${item.title}`}>
                                <dt id="faq-quality"
                                    className="text-lg font-medium leading-6 dark:text-white text-black">
                                    {item.title}
                                </dt>
                                <dd className="mt-2 text-base text-gray-400">
                                    {item.content}
                                </dd>
                            </div>
                        })}
                        {/*<div>*/}
                        {/*    <dt id="faq-participants" className="text-lg font-medium leading-6 ">How are room*/}
                        {/*        participants counted?*/}
                        {/*    </dt>*/}
                        {/*    <dd className="mt-2 text-base text-gray-200">Every person sending audio and/or video in a*/}
                        {/*        call is considered a participant. Embeds don't count as participants.*/}
                        {/*    </dd>*/}
                        {/*</div>*/}
                        {/*<div>*/}
                        {/*    <dt id="faq-embeds" className="text-lg font-medium leading-6 ">How do embeds work?</dt>*/}
                        {/*    <dd className="mt-2 text-base text-gray-200">Each embed lets you add a video feed with one*/}
                        {/*        or more participants to your stream. Just drop the URL into OBS as a browser source and*/}
                        {/*        you're ready to go.*/}
                        {/*    </dd>*/}
                        {/*</div>*/}
                        {/*<div>*/}
                        {/*    <dt id="faq-refunds" className="text-lg font-medium leading-6 ">What's your refund policy?*/}
                        {/*    </dt>*/}
                        {/*    <dd className="mt-2 text-base text-gray-200">If you try streaming with Ping and aren't happy*/}
                        {/*        with the product reach out to us and we'll get you a refund. All we ask is that you tell*/}
                        {/*        us what didn't work for you so we can fix it!*/}
                        {/*    </dd>*/}
                        {/*</div>*/}
                    </dl>
                </div>
            </div>
        </>
    )
}

export default FaqOpenList