import React from "react";
import {DialogBasic} from "front-lib"
import {BuildAssetsForm} from "../forms/BuildAssetsForm";
import BuildForm from "../forms/BuildForm";

const DialogReviewBuild = ({renderTrigger, environment, build}: any) => {
    return (
        <>
            <DialogBasic
                size={"4xl"}
                title={"Build"}
                className={'min-w-md'}
                renderTrigger={renderTrigger}
                renderContent={({toggleOpen}: any) => {
                    return <>
                        <BuildForm build={build} environment={environment}/>
                    </>
                }}
            />
        </>
    )
}

export default DialogReviewBuild