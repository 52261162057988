import React, {useEffect, useState} from "react";
import {withForm, useForm} from "front-lib";
import {MdMemory} from "react-icons/all";
import {useCacheApi} from "../api/useCacheApi";

const CacheForm = ({}) => {
    const {getAllCacheKeys, crawlMainCacheEndpoints} = useCacheApi();

    const [cacheKeys, setCacheKeys] = useState<any[] | null>();
    const {handleSubmit} = useForm();

    const clearCache = async () => {

        handleSubmit(async () => {
            console.log("clear cache")
            await clearCache();
            setCacheKeys(null);
            return "Application cache cleared successfully"
        });
    };

    const reloadCache = async () => {

        handleSubmit(async () => {
            console.log("crawlMainCacheEndpoints")
            await crawlMainCacheEndpoints();
            const keys = await getAllCacheKeys();
            setCacheKeys(keys)
            return "Application cache reloaded successfully"
        });
    };

    useEffect(() => {

        (async () => {
            const keys = await getAllCacheKeys();
            setCacheKeys(keys)
        })();

    }, []);

    return (
        <>

            {cacheKeys && <>

                <h3 className={'text-xl'}>Cache Keys <small>({cacheKeys.length})</small></h3>

            </>}

            <div>


                <ul className="my-4   text-left text-gray-500 dark:text-gray-400">
                    {cacheKeys?.map((key: string) => {
                        return <li className="flex items-center space-x-3" key={key}>
                            <MdMemory className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"/>

                            <span>{key}</span>
                        </li>
                    })}


                </ul>

            </div>
            <div>
                {/*<button type="submit"*/}
                {/*        onClick={clearCache}*/}
                {/*        className={`text-white mr-2 bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}>*/}


                {/*    Clear Cache*/}
                {/*</button>*/}
                <button type="submit"
                        onClick={reloadCache}
                        className={`text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}>


                    Reload Cache
                </button>

            </div>
        </>
    )
}

export default withForm(CacheForm)