import React ,{ReactNode, useState} from "react";
import {Tab} from "./Tab";
import {TabPanel} from "./TabPanel";

interface ITabsProps {
    defaultTab?: number;
    tabs: { label: ReactNode | string; content: ReactNode, disabled?: boolean }[];
    centered?: boolean;
    activeTab?: number;
    setActiveTab?: (tab: number) => void;
    className?: string
}

const Tabs = ({defaultTab = 0, tabs, setActiveTab, className}: ITabsProps) => {
    const [currentTab, setCurrentTab] = useState<any>(defaultTab);

    const handleTabChange = (_: any, newTab: number) => {
        setCurrentTab(newTab);
        setActiveTab && setActiveTab(newTab);
    };

    const filteredTabs = tabs.filter(t => Boolean(t));
    return (
        <>


            <div
                // centered={centered}
                // value={activeTab || currentTab}
                // onChange={handleTabChange}
                // classes={classes}
                // aria-label="Apartment gallery, 360 view and details."
            >
                <div
                    className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
                    <ul className="flex flex-wrap px-2">
                        {filteredTabs.map((tab, i) => {
                            return <Tab key={`tab_${i}`} label={tab.label} isActive={currentTab === i} onClick={() => {
                                setCurrentTab(i)
                            }}/>;
                        })}
                    </ul>
                </div>


            </div>
            {filteredTabs.map((tab, i) => {
                return (
                    <TabPanel key={`tabContent_${i}`} value={i} isActive={currentTab === i}>
                        {tab.content}
                    </TabPanel>
                );
            })}
        </>
    );
};

// Additional a11y props passed to each tab.
const a11yTabProps = (index: number) => {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`
    };
};

export {Tabs};
