import React from "react";
import {DialogEnvLoaderManager} from "./dialogs/DialogEnvLoaderManager";
import {useEnvironmentLoader} from "./context/useEnvironmentLoader";
import {WelcomeBanner} from "./banners/WelcomeBanner";

const EnvironmentIframe = ({env, build, className = "h-screen"}: any) => {
    const {iframeUrl} = useEnvironmentLoader();
    // const pathname = build.snapshot.rootKey
    // const iframeUrl = `${VITE_APP_ENV_LOADER_URL}${pathname}`;

    return (<div className={`relative w-full ${className}`}>
        <WelcomeBanner/>
        <DialogEnvLoaderManager env={env} build={build}/>
        <iframe src={iframeUrl} className={`w-full h-full`} allow="camera; microphone"/>
    </div>)
}

export default EnvironmentIframe
