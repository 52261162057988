import React, {useEffect} from "react";
import GenericTable from "../../table/GenericTable";
import DialogCreateNotification from "./dialogs/DialogCreateNotification";
import {notificationFilters} from "./NotificationFilters";
import {notificationColumns} from "./NotificationColumns";
import {RiFilter3Line} from "react-icons/all";
import {useNotificationCrudApi} from "./api/useNotificationCrudApi";

const NotificationTable = () => {
    const crudApi = useNotificationCrudApi();
    return (
        <>
            <GenericTable renderHeader={<>
                <div className={'flex items-center'}>
                    <h3 className="font-semibold text-lg">Notifications</h3>
                    <button id="toggleSidebar" aria-expanded="true" aria-controls="sidebar"
                            onClick={() => {
                            }}
                            className="p-2 ml-1 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-950 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700">
                        <RiFilter3Line size={22}/>
                    </button>
                </div>
                <DialogCreateNotification/>
            </>}
                          crudApi={crudApi}
                          filters={notificationFilters}
                          columns={notificationColumns}
            />
        </>
    );
};

export default NotificationTable;
