import React from "react";
import {featureFlagFilters} from "./FeatureFlagFilters";
import {featureFlagColumns} from "./FeatureFlagColumns";
import GenericTable from "../../table/GenericTable";
import DialogCreateFeatureFlag from "./dialogs/DialogCreateFeatureFlag";
import {useFeatureFlagCrudApi} from "./api/useFeatureFlagCrudApi";

const FeatureFlagTable = () => {
    const crudApi = useFeatureFlagCrudApi();
    return (
        <>
            <GenericTable renderHeader={<>
                <div className={'flex items-center'}>
                    <h3 className="font-semibold text-lg">Flags</h3>

                </div>
                <DialogCreateFeatureFlag/>
            </>}
                          crudApi={crudApi}
                          filters={featureFlagFilters}
                          columns={featureFlagColumns}
            />
        </>
    );
};

export default FeatureFlagTable;
