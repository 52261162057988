import React from "react"
import {VscReferences} from "react-icons/vsc";
import {AiOutlineTrophy} from "react-icons/ai";
import {useWorkspace} from "../workspace/context/useWorkspace";
import {ImEarth, MdOutlineBuildCircle, TiCloudStorage} from "react-icons/all";

const DashboardStats = () => {

    const {stats} = useWorkspace();
    return (
        <>
            <div>
                <div className="grid grid-cols-12 gap-3">
                    <div className="col-span-12 sm:col-span-4">
                        <div
                            className="w-full bg-white dark:bg-gray-950 dark:text-gray-300 border dark:border-gray-950 text-primary-400 rounded-lg flex items-center p-6  xl:mb-0">
                            <ImEarth className="w-11 h-11 fill-current mr-4 hidden lg:block"/>
                            <div className="text-gray-700 dark:text-gray-300">
                                <p className="font-semibold text-3xl">{stats.environmentsCount}</p>
                                <p>Environments</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 sm:col-span-4">
                        <div
                            className="w-full bg-white dark:bg-gray-950 dark:text-gray-300 border dark:border-gray-950 text-primary-400 rounded-lg flex items-center p-6">
                            <MdOutlineBuildCircle className="w-14 h-14 fill-current mr-4 hidden lg:block"/>
                            <div className="text-gray-700 dark:text-gray-300">
                                <p className="font-semibold text-3xl">{stats.buildsCount}</p>
                                <p>Builds this month</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 sm:col-span-4">
                        <div
                            className="w-full bg-white dark:bg-gray-950 dark:text-gray-300 border dark:border-gray-950 text-primary-400 rounded-lg flex items-center p-6">

                            <TiCloudStorage  className="w-14 h-14 fill-current mr-4 hidden lg:block"/>
                            <div className="text-gray-700 dark:text-gray-300">
                                <p className="font-semibold text-3xl">{stats.storageCount}MB</p>
                                <p>Storage usage</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export {DashboardStats}