import React from "react";
import {Outlet} from "react-router-dom";
import AuthHeader from "./header/AuthHeader";
import SiteFooter from "./footer/SiteFooter";

const AuthLayout = ({}) => {
    // useLayoutEffect(() => {
    //     initRecaptcha();
    // }, []);
    return (
        <div className={''}>
            <AuthHeader/>
            <div className={'mx-auto max-w-screen-xl px-6 min-h-[calc(100vh_-_130px)] pt-[60px]'}>
                <Outlet/>
            </div>
            <div className={'mt-[60px]'}>
                <SiteFooter/>
            </div>
        </div>
    )
}

export default AuthLayout