import React, {createContext, useEffect, useState} from "react";
import {io} from "socket.io-client";

export const SocketContext = createContext<any | undefined>(undefined);

const VITE_APP_SOCKET_BASE_URL = import.meta.env.VITE_APP_SOCKET_BASE_URL;
// console.log("VITE_APP_SOCKET_BASE_URL", VITE_APP_SOCKET_BASE_URL)

const SocketProvider = ({name, source, children, query}: any) => {
    // console.log("SocketProvider")

    const socketQueryParams = new URLSearchParams(query).toString();
    // console.log("socketQueryParams", socketQueryParams)
    const [socket] = useState(io(VITE_APP_SOCKET_BASE_URL + `?${socketQueryParams}`, {
        transports: ["websocket"],
        autoConnect: false,
        reconnection: true,
        reconnectionDelay: 2000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: Infinity,
        forceNew: true
    }));
    const [isConnected, setIsConnected] = useState(socket.connected);

    useEffect(() => {
        // console.log("SocketProvider connect", name)
        socket.on('connect', () => {
            console.log("socket is online")
            socket.emit(`hello-${source}`, {
                name,
                source,
                version: import.meta.env.VITE_APP_VERSION,
                mode: import.meta.env.MODE,
            });
            setIsConnected(true);
        });

        socket.on('disconnect', () => {
            console.log("socket is offline")
            setIsConnected(false);
        });

        socket.on('health-check', async (args) => {
            console.log("health-check from server")
            socket.emit('alive');
        });
        socket.on('exception', function (data) {
            console.log('socket-exception', data);
        });

        socket.on('page-refresh', async (args) => {
            console.log("page-refresh")
        });
        // socket.on(userId, (args) => {
        //     console.log("my private room", args)
        // });
        connect();

        return () => {
            disconnect()
            socket.off('connect');
            socket.off('disconnect');
            socket.off('health-check');
            socket.off('exception');
            socket.off('page-refresh');
        };
    }, []);


    const connect = () => {
        console.log("connecting to socket...")
        socket.connect();
    }
    const disconnect = () => {
        console.log("disconnecting from socket...")
        socket.disconnect();
    }

    const context = {
        socket,
        isConnected,
        connect,
        disconnect,
    }
    return (
        <SocketContext.Provider value={context}>
            {children}
        </SocketContext.Provider>
    )
}

export {SocketProvider}