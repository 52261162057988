import React from "react"
import {FormInputText, FormTextarea} from "front-lib";

const EnvEditorForm = ({env}: any) => {
    return (
        <>
            <div className="col-span-6  sm:col-span-4">
                <FormTextarea label={"Triggers"} name={"triggers"}
                              defaultValue={env.triggers || ''}/>
            </div>


            <div className="col-span-6">

            </div>

            <div className="col-span-6 sm:col-span-4">

            </div>
        </>
    )
}

export {EnvEditorForm}