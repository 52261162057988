import React from "react";
import DynamicRow from "./DynamicRow";
import DynamicHead from "./DynamicHead";
import {IColumn, IFilter, useGenericTable} from "./context/GenericTableContext";
import withGenericTableContext from "./context/withGenericTableContext";
import "./style/generic-table.scss";
import {BiError, MdSearchOff} from "react-icons/all";
import Pagination from "./pagination/Pagination";
import {LoadingCover} from "front-lib";


export interface IGenericTableProps {
    // loadRows: (filters: any) => Promise<any[]>
    columns: IColumn[];
    pageSize?: number;
    filters?: IFilter[],
    crudApi: any,
    onRowClick?: (row?: any) => void,
    renderHeader?: JSX.Element | ((hookData?: any) => JSX.Element)
    // onFiltersChange?: (filters: IFilter[]) => void
}

export interface ICellComponent {
    row: any;
    column: IColumn;

    loadRows: () => Promise<void>
    crudApi: any
}


const GenericTable = withGenericTableContext(() => {

    const {
        rows,
        totalCount,
        isTableEmpty,
        isLoadingFirstTime,
        isLoading,
        page,
        loadRows,
        onPageChange,
        pageSize,
        onPageSizeChange,
        selectRow,
        selectAllRows,
        clearRowsSelection,
        onRowClick,
        isError,
        renderHeader
    } = useGenericTable();


    const handleSelectAllClick = (event: any) => {
        if (event.target.checked) {
            selectAllRows();
            return;
        }
        clearRowsSelection();
    };

    const handleRowClick = (event: any, row: any) => {
        selectRow(row);
        console.log("onRowClick")
    };

    const handleChangePage = (event: any, newPage: number) => {
        onPageChange(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        onPageSizeChange(parseInt(event.target.value, 10));
        onPageChange(0);
    };

    const renderHeadAsFunctionOrComponent = () => {

        const content = typeof (renderHeader) === 'function' ? renderHeader({loadRows}) : renderHeader;
        return content;
    }
    console.log("generic table")
    return (
        <div
            className={'relative flex flex-col min-w-0 break-words w-full mb-0 shadow-lg rounded bg-white dark:bg-gray-950'}>
            {/*{withToolBars && <ToolbarContainer/>}*/}
            {renderHeader && <div className="rounded-t mb-0 px-6 py-3 ">
                <div className="relative w-full flex items-center justify-between">
                    {renderHeadAsFunctionOrComponent()}
                </div>
            </div>}
            <div className={'block w-full overflow-x-auto overflow-auto w-100 max-h-[1500px] table-container'}>
                <table className="items-center w-full  border-separate border-spacing-0">
                    <DynamicHead
                        onSelectAllClick={handleSelectAllClick}
                    />

                    {!isLoadingFirstTime && !isError && <tbody>
                    {rows.map((row: any, index: number) => {
                        return (
                            <DynamicRow key={row.id} row={row} index={index}/>
                        );
                    })}
                    {/*<EmptyRowsToFill/>*/}
                    </tbody>}
                </table>
                {(isLoadingFirstTime || (isTableEmpty && isLoading)) && <TableLoader/>}
                {isTableEmpty && !isLoading && <NoDataMessage/>}
                {isError && !isLoading && <ErrorMessage/>}
            </div>

            <Pagination count={totalCount} page={page}/>

            {/*{totalCount !== null && <div*/}
            {/*    rowsPerPageOptions={[10, 25, 50, 100]}*/}
            {/*    component="div"*/}
            {/*    count={totalCount}*/}
            {/*    rowsPerPage={pageSize}*/}
            {/*    page={page}*/}
            {/*    onPageChange={handleChangePage}*/}
            {/*    onRowsPerPageChange={handleChangeRowsPerPage}*/}
            {/*/>}*/}
        </div>


    );
});

export default GenericTable;

// Avoid a layout jump when reaching the last page with empty rows.
const EmptyRowsToFill = () => {
    const {rows, visibleColumns, page, pageSize, dense} = useGenericTable();

    const emptyRows = pageSize - (rows || []).length;

    return <>
        {emptyRows > 0 && (
            <tr
                style={{
                    height: (dense ? 33 : 53) * emptyRows
                }}
            >
                <td/>
            </tr>
        )}
    </>;
};


const ErrorMessage = () => {
    const {clearFilters, setShouldShowFilters} = useGenericTable();

    return <div className={'noData'}>
        <div>
            <div
                className="py-8 px-4 mx-auto max-w-screen-md text-center lg:py-16 lg:px-12 flex justify-center items-center flex-col">
                <BiError size={40} className={'text-gray-500'}/>

                <h3 className="mb-3 text-3xl font-bold tracking-tight leading-none text-gray-950   dark:text-white">
                    Something went wrong
                </h3>
                <p className="font-light text-gray-500 text-md dark:text-gray-400">
                    Cannot load data from our servers..</p>
            </div>
        </div>
    </div>;
};


const NoDataMessage = () => {
    const {clearFilters, setShouldShowFilters} = useGenericTable();
    return <div className={'noData'}>
        <div>
            <div
                className="py-8 px-4 mx-auto max-w-screen-md text-center lg:py-16 lg:px-12 flex justify-center items-center flex-col">
                <MdSearchOff size={40} className={'text-gray-500'}/>

                <h3 className="mb-3 text-3xl font-bold tracking-tight leading-none text-gray-950    dark:text-white">
                    No Data
                </h3>
                <p className="font-light text-gray-500 text-md dark:text-gray-400">
                    Try to change your filters</p>
            </div>
        </div>
    </div>;

};


const TableLoader = () => {
    return <LoadingCover className={'h-[300px] flex items-center py-[100px]'} isLoading={true}/>;
};
