import React from 'react';
import {Helmet} from 'react-helmet-async';
import {GettingStartedView} from "../../../modules/app/dashboard/GettingStartedView";
import {useSearchParams} from "react-router-dom";

function GettingStartedRoute() {

    // const {stats} = useWorkspace();
    // const {environmentsCount} = stats;
    return (
        <>
            <Helmet>
                <title>Getting Started</title>
            </Helmet>
            <section className="">
                <GettingStartedView/>
            </section>
        </>
    );
}

export default GettingStartedRoute;
