import React from "react"
import {DialogBasic} from "front-lib";
import WorkspaceForm from "../forms/WorkspaceForm";


const DialogEditWorkspace = ({workspace , onEdit}: any) => {


    return (
        <>
            <DialogBasic
                title={"Edit Workspace"}
                renderTrigger={<button
                    className="rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    Edit
                </button>}
                renderContent={({toggleOpen}: any) => {
                    return <>
                        <WorkspaceForm workspace={workspace} onSubmit={async ({dirtyValues}: any) => {

                            console.log("dirtyValues", dirtyValues)
                            // await crudApi.edit({id: puppet.id, ...dirtyValues});
                            // await loadRows();
                            onEdit && await onEdit({id: workspace.id, ...dirtyValues});
                            toggleOpen()
                        }} onCancel={toggleOpen}/>
                    </>
                }}
            />
        </>
    )
}

export default DialogEditWorkspace