import {api, postWithCaptchaToken} from "front-lib";

export const useAuthApi = () => {
    const login = async (credentials: any) => {
        // const response = await postWithCaptchaToken('/auth/local/login', {...credentials}, "login");
        const response = await api.post('/auth/local/login', {...credentials});
    }

    const register = async (createUserDto: any) => {
        // const response = await postWithCaptchaToken('/auth/local/register', {...createUserDto}, "register");
        const response = await api.post('/auth/local/register', {...createUserDto});
    }

    const requestNewPassword = async (email: string) => {
        console.log("requestNewPassword", email)
        const response = await postWithCaptchaToken('/auth/local/request-password', {email}, "request_password");
        return response.data;
    }

    const setPassword = async (password: string, token: string) => {
        const response = await postWithCaptchaToken('/auth/local/set-password', {password, token}, "set_password");
        return response.data;
    }

    const logout = async () => {
        localStorage.clear();
        const response = await api.post('/auth/local/logout');
    }

    return {
        login,
        register,
        requestNewPassword,
        setPassword,
        logout
    }
}