import React from "react";
import LabelTicketStatus from "./labels/LabelTicketStatus";
import {IColumn} from "../../table/context/GenericTableContext";
import {LabelNullOrValue , LazyLabelDateTime} from "front-lib";
import DialogEditTicket from "./dialogs/DialogEditTicket";

export const ticketColumns: IColumn[] = [
    {
        property: "actions",
        label: "Actions",
        disableSorting: true,
        isVisible: true,
        component: ({row, column}) => <div className={'flex items-center'} onClick={(event) => event.stopPropagation()}>
            <DialogEditTicket ticket={row}/>
        </div>
    },
    {
        property: "id",
        label: "ID",
        isVisible: false,
        component: ({row, column}) => <>
            {row.id}
        </>
    },
    {
        property: "status",
        label: "Status",
        isVisible: true,
        component: ({row, column}) => <>
            <LabelTicketStatus ticket={row}/>
        </>
    },
    {
        property: "source",
        label: "Source",
        isVisible: true,
        component: ({row, column}) => <>
            <LabelNullOrValue value={row.source}/>
        </>
    },
    {
        property: "email",
        label: "Email",
        isVisible: true,
        component: ({row, column}) => <>
            {row.email}
        </>
    },
    {
        property: "subject",
        label: "Subject",
        isVisible: true,
        component: ({row, column}) => <div>
            <div className={'truncate max-w-[400px] font-bold'}>
                {row.subject}
            </div>
            <div className={'truncate max-w-[400px]'}>
                <LabelNullOrValue value={row.message}/>
            </div>
        </div>
    },
    // {
    //     property: "message",
    //     label: "Message",
    //     isVisible: true,
    //     component: ({row, column}) => <>
    //
    //     </>
    // },
    {
        property: "createdAt",
        label: "Created",
        isVisible: true,
        component: ({row, column}) => <>
            <LazyLabelDateTime timestamp={row.createdAt}/>
        </>
    },

];
