export enum Role {
    Guest = 1,
    User = 10,
    Mod = 50,
    Admin = 100,
    Developer = 150
}


export const toRoleText = (role: Role) => {

    const keys: any = {
        1: "Guest",
        10: "Viewer",
        50: "Streamer",
        75: "Admin",
        100: "Developer",
    }

    return keys[role]
}