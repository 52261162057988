import {useState} from "react";
import {FileUploadClient} from "../client/fileUploadClient";

interface IFileUploadHookParams {
    isUploading: boolean,
    upload: (file: any, onProgress: any, params?: any) => Promise<string>,
    progress: number,
    error: any,
    isFinishedUpload?: boolean
    uploadPromise?: any
}

export const useFileUpload = (): IFileUploadHookParams => {

    const [isUploading, setIsUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [uploadPromise, setUploadPromise] = useState<any>(null);
    const [error, setError] = useState(null);
    const isFinishedUpload = progress === 100 && !isUploading;

    const upload = async (url: string, file: any, params?: any): Promise<string> => {

        try {
            setIsUploading(true);
            setError(null);
            console.log("useFileUpload: starting upload");
            setProgress(0);

            const fileUploadClient = new FileUploadClient(url);
            const uploadPromise = fileUploadClient.upload(file, (progress) => {
                setProgress(progress);
            }, params);
            setUploadPromise(uploadPromise);
            const fileEntity: any = await uploadPromise;
            // await sleep(3000)
            setProgress(100);
            console.log("useFileUpload: file upload SUCCESS", fileEntity);
            setIsUploading(false);
            return fileEntity.url || "";
        } catch (err: any) {
            console.log("useFileUpload: error", err);
            setIsUploading(false);
            setError(err.message);
            throw err;
        }

    };

    return {isUploading, upload, progress, error, isFinishedUpload, uploadPromise};

}
