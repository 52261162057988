import {useContext} from "react";

import {ThemeContext} from "../provider/ThemeProvider";
import {hexToRgb} from "../services/ThemeService";

export const useTheme = () => {

    // console.log("useTheme")
    const context = useContext(ThemeContext)
    if (context === undefined) {
        throw new Error("useTheme must be used within ThemeContext");
    }
    return context
    //     const context = useContext(ThemeContext);
//     if (context === undefined) {
//         throw new Error('useTheme must be used within ThemeProvider');
//     }
//
//     return context;
}


// #111827
//
//
// const colors = [
//     "#dbdb55",
//     "#cdcd4f",
//     "#bfbf4a",
//     "#b0b044",
//     "#a2a23f",
//     "#949439",
//     "#868634",
//     "#77772e",
//     "#696929",
//     "#5b5b23",
//     "#4d4d1e"
// ]
//
// const colors2 = colors.map((c, index) => {
//     // return `--color-secondary-${index * 100}: ${(c)}`
//     return `--color-secondary-${index * 100} : ${hexToRgb(c)};`
// })
// console.log(JSON.stringify(colors2, null, 2));


// const colors = ["primary", "gray"].map((color: string) => {
//     return [50, 100, 200, 300, 400, 500, 600, 700, 800, 900].map((shade) => {
//         console.log(`${color}-${shade} `)
//         return getComputedStyle(document.documentElement).getPropertyValue(`--color-${color}-${shade}`).trim();
//     });
// })
//
//
