import React, {useEffect} from "react"
import {LoadingCover} from "front-lib";
import {MdSearchOff} from "react-icons/all";
import {useEnvironmentLoader} from "./context/useEnvironmentLoader";
import EnvLoaderContextProvider from "./context/EnvLoaderContextProvider";

const EnvironmentLoader = (props: any) => {
    return <EnvLoaderContextProvider>
        <EnvironmentLoaderContent {...props}/>
    </EnvLoaderContextProvider>
}

const EnvironmentLoaderContent = ({render}: any) => {
    const {isLoading, env, isBuildReady} = useEnvironmentLoader();

    return (
        <>
            {isLoading && <div className={'py-10'}>
                <LoadingCover isLoading={true}/>
            </div>}
            {!isLoading && <>
                {isBuildReady && render({env})}
                {!isBuildReady && <NoBuildMessage/>}
            </>}
        </>
    )
}
const NoBuildMessage = () => {
    return <div className={'noData'}>
        <div>
            <div
                className="py-8 px-4 mx-auto max-w-screen-md text-center lg:py-16 lg:px-12 flex justify-center items-center flex-col">
                <MdSearchOff size={40} className={'text-gray-500'}/>

                <h3 className="mb-3 text-3xl font-bold tracking-tight leading-none text-gray-950    dark:text-white">
                    No build found
                </h3>
                <p className="font-light text-gray-500 text-md dark:text-gray-400">
                    Try to run your first build</p>
            </div>
        </div>
    </div>;

};
const NoPermissions = () => {
    return <div className={'noData'}>
        <div>
            <div
                className="py-8 px-4 mx-auto max-w-screen-md text-center lg:py-16 lg:px-12 flex justify-center items-center flex-col">
                <MdSearchOff size={40} className={'text-gray-500'}/>

                <h3 className="mb-3 text-3xl font-bold tracking-tight leading-none text-gray-950    dark:text-white">
                    No permissions
                </h3>
                <p className="font-light text-gray-500 text-md dark:text-gray-400">
                    Make sure everything is correct
                </p>
            </div>
        </div>
    </div>;

};
export {EnvironmentLoader}