import React from "react";
import {
    ButtonFormSubmit,
    FormGlobalErrorMessage,
    FormGlobalSuccessMessage,
    FormInputText,
    FormSwitch,
    FormTextarea,
    useForm, withForm
} from "front-lib";


interface IProps {
    ticket: any;
    onSubmit?: (formData?: any) => Promise<void>;
    onCancel?: () => void
}

const TicketForm = ({ticket, onSubmit, onCancel}: IProps) => {
    // const {t} = useLanguage();
    const {watch} = useForm();
    const isOpen = watch("isOpen", ticket.isOpen);
    return (
        <>
            <div className="space-y-6 bg-white dark:bg-gray-900 px-4 py-5 sm:p-6">

                <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6">
                        <FormSwitch label={isOpen ? "Open" : "Closed"}
                                    name={"isOpen"}
                                    defaultValue={ticket.isOpen}
                        />
                    </div>
                    <div className="col-span-6">
                        <FormInputText label={"Source"} name={"source"}
                                       defaultValue={ticket.source}/>
                    </div>

                    <div className="col-span-6">
                        <FormInputText label={"Email"} name={"email"}
                                       defaultValue={ticket.email}/>
                    </div>

                    <div className="col-span-6">
                        <FormInputText label={"Subject"} name={"subject"}
                                       defaultValue={ticket.subject}/>
                    </div>

                    <div className="col-span-6 sm:col-span-3">

                        <FormTextarea label={"Message"} name={"message"} defaultValue={ticket.message}/>
                    </div>

                    <div className="col-span-6">
                        <FormGlobalErrorMessage/>
                        <FormGlobalSuccessMessage/>
                    </div>
                </div>


            </div>

            <div
                className="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">

                <ButtonFormSubmit onSubmit={onSubmit}/>
                <button type="button"
                        onClick={onCancel}
                        className="text-gray-500 min-h-[50px] bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg border border-gray-200 text-lg font-medium px-5 py-2.5 hover:text-gray-950 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                    Cancel
                </button>
            </div>
        </>);

};

export default withForm(TicketForm)
