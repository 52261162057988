import React from 'react';
const LabelNull = ({text}: any) => {
    return (
        <small className={"text-gray-400"}>
            {text || 'null'}
        </small>
    );
};

export {LabelNull};
