import React from "react";
import {Navigate} from "react-router-dom";
import {useUser} from "../hooks";

interface IProps {
    element: JSX.Element
}

export const GuardCannotBeAuthenticated = ({element}: IProps) => {
    const {user} = useUser();
    console.log("GuardCannotBeAuthenticated", user)
    const isAuthorized = Boolean(!user);
    return isAuthorized ? element : <Navigate to={`/app`} replace={true}/>;
};
