import React from "react";
import {IColumn} from "../../table/context/GenericTableContext";
import {Avatar, LazyLabelDateTime} from "front-lib";

export const auditColumns: IColumn[] = [
    {
        property: "id",
        label: "ID",
        isVisible: false,
        component: ({row, column}) => <>
            {row.id}
        </>
    },
    {
        property: "user",
        label: "User",
        isVisible: true,
        component: ({row, column}) => <div className={'flex items-center'}>
            <div className={'h-11 w-11'}>
                <Avatar url={row.user.profile.avatar}/>
            </div>
            <span className={'ml-2'}>{row.user.profile.firstname} {row.user.profile.lastname} </span>

        </div>
    },

    {
        property: "action",
        label: "Action",
        isVisible: true,
        component: ({row, column}) => <>
            {row.action}
        </>
    },
    {
        property: "description",
        label: "Description",
        isVisible: true,
        component: ({row, column}) => <>
            <pre>
                {JSON.stringify(row.params, null, 2)}
            </pre>
        </>
    },

    {
        property: "createdAt",
        label: "Created",
        isVisible: true,
        component: ({row, column}) => <>
            <LazyLabelDateTime timestamp={row.createdAt}/>
        </>
    },

];
