import React from "react";
import {Helmet} from "react-helmet-async";
import {UserAccountForm, ProfileThemeForm, ApiKeyOptionsForm} from "frontenvs-lib";
import {IProfile, useAuthContext, useUser} from "front-lib";

const MyProfileRoute = ({}) => {
    const {user} = useUser();
    const {updateMyProfileAndContextState} = useAuthContext()

    return (
        <>
            <Helmet>
                <title>frontenvs - My Profile</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl'}>

                <header className="">
                    <div className="max-w-7xl py-6">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-950 dark:text-white">My Profile</h1>
                    </div>
                </header>
                <div className={'bg-gray-100 dark:bg-gray-800'}>
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1 hidden md:block">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-950 dark:text-gray-300">Profile</h3>
                                <p className="mt-1 text-sm text-gray-600 dark:text-gray-500">
                                    Information about your profile</p>
                            </div>
                        </div>
                        <div className="mt-5 md:col-span-2 md:mt-0">
                            <div className="shadow sm:overflow-hidden sm:rounded-md">
                                <UserAccountForm user={user}/>
                            </div>
                        </div>
                    </div>
                    <div className="pt-[100px] pb-5 hidden md:block">
                        <div className="border-t border-gray-200 dark:border-gray-700"></div>
                    </div>
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1 hidden md:block">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-950 dark:text-gray-300">API</h3>
                                <p className="mt-1 text-sm text-gray-600 dark:text-gray-500">
                                    API configuration
                                </p>
                                <div>
                                    <small className={'description-under mr-2'}>
                                        Usage information can be found <a
                                        href={"https://platform.openai.com/account/usage"} target={"_blank"}
                                        className={'font-bold text-primary-500'}>here</a>.<br/>
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div className="md:col-span-2 space-y-6 mt-5 md:mt-0">

                            <div className="shadow sm:overflow-hidden sm:rounded-md">

                                <div className="card">
                                    <div>
                                        <h3 className="">API Options</h3>
                                        <p className="">
                                            API configurations.
                                        </p>
                                    </div>
                                </div>
                                <ApiKeyOptionsForm profile={user.profile}
                                                   onProfileChanged={async (profile: IProfile) => {
                                                       await updateMyProfileAndContextState(profile)
                                                   }}/>
                            </div>
                        </div>

                    </div>
                    <div className="pt-[100px] pb-5 hidden md:block">
                        <div className="border-t border-gray-200 dark:border-gray-700"></div>
                    </div>
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1 hidden md:block">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-950 dark:text-gray-300">Theme</h3>
                                <p className="mt-1 text-sm text-gray-600 dark:text-gray-500">
                                    Theme configuration
                                </p>
                            </div>
                        </div>
                        <div className="md:col-span-2 space-y-6 mt-5 md:mt-0">

                            <div className="shadow sm:overflow-hidden sm:rounded-md">

                                <div className="card">
                                    <div>
                                        <h3 className="">Theme Options</h3>
                                        <p className="">
                                            Personalized theme options
                                        </p>
                                    </div>
                                </div>
                                <ProfileThemeForm profile={user.profile}
                                                  onProfileChanged={async (profile: IProfile) => {
                                                      await updateMyProfileAndContextState(profile)
                                                  }}/>


                            </div>
                        </div>


                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <br/>

                </div>
            </div>
        </>
    )
}

export default MyProfileRoute
