import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {api} from "front-lib"

export const useMyReferrals = () => {
    const [myReferrals, setMyReferrals] = useState<any>();

    const [searchParams] = useSearchParams();
    const referralId = searchParams.get("referral");

    console.log("referralId", referralId)

    useEffect(() => {

        (async () => {
            const response = await api.get(`/referrals/stats`)
            console.log("response", response)
            setMyReferrals(response.data)
        })()
    }, [referralId]);

    return {myReferrals}
}
