import React from 'react'

const CardProfileList = ({options, renderOption}: any) => {
    return (
        <>
            <div className="flex flex-col bg-white dark:bg-gray-900 max-w-md px-6 rounded-lg shadow-md">
                <ul className="-mx-4">
                    {
                        options.map((referral: any) => {
                            return renderOption(referral);
                        })
                    }
                </ul>
            </div>
        </>
    )
}

export {CardProfileList}