import React from "react";

const NotificationList = ({}) => {
    return (
        <>
            <div>
                <a href="#"
                   className="flex py-3 px-4 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600">

                    <div className="pl-3 w-full">
                        <div
                            className="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400">New
                            message from <span
                                className="font-semibold text-gray-950 dark:text-white">Bonnie Green</span>:
                            "Hey, what's up? All set for the presentation?"
                        </div>
                        <div
                            className="text-xs font-medium text-primary-700 dark:text-primary-400">a
                            few moments ago
                        </div>
                    </div>
                </a>
                <a href="#"
                   className="flex py-3 px-4 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600">

                    <div className="pl-3 w-full">
                        <div
                            className="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400">
                                                <span
                                                    className="font-semibold text-gray-950 dark:text-white">Jese leos</span> and <span
                            className="font-medium text-gray-950 dark:text-white">5 others</span> started
                            following you.
                        </div>
                        <div
                            className="text-xs font-medium text-primary-700 dark:text-primary-400">10
                            minutes ago
                        </div>
                    </div>
                </a>
                <a href="#"
                   className="flex py-3 px-4 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600">

                    <div className="pl-3 w-full">
                        <div
                            className="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400">
                                        <span
                                            className="font-semibold text-gray-950 dark:text-white">Joseph Mcfall</span> and <span
                            className="font-medium text-gray-950 dark:text-white">141 others</span> love
                            your story. See it and view more stories.
                        </div>
                        <div
                            className="text-xs font-medium text-primary-700 dark:text-primary-400">44
                            minutes ago
                        </div>
                    </div>
                </a>
                <a href="#"
                   className="flex py-3 px-4 border-b hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600">

                    <div className="pl-3 w-full">
                        <div
                            className="text-gray-500 font-normal text-sm mb-1.5 dark:text-gray-400">
                                        <span
                                            className="font-semibold text-gray-950 dark:text-white">Leslie Livingston</span> mentioned
                            you in a comment: <span
                            className="font-medium text-primary-700 dark:text-primary-500">@bonnie.green</span> what
                            do you say?
                        </div>
                        <div
                            className="text-xs font-medium text-primary-700 dark:text-primary-400">1
                            hour ago
                        </div>
                    </div>
                </a>

            </div>
        </>
    )
}

export default NotificationList