import React from "react";
import {
    Avatar, DialogConfirmation,
    FormGlobalErrorMessage,
    FormGlobalSuccessMessage,
    LabelDateTime,
    Tabs,
    useForm, withForm
} from "front-lib";
import LabelBuildScreenshot from "../labels/LabelBuildScreenshot";
import {LabelEnvLoaderUrl} from "../labels/LabelEnvLoaderUrl";
import {BuildAssetsForm} from "./BuildAssetsForm";
import {LabelBuildStatus} from "../labels/LabelBuildStatus";
import {useWorkspace} from "../../context/useWorkspace";


interface IProps {
    build: any;
    environment: any;
    onSubmit?: (formData?: any) => Promise<void>;
    onCancel?: () => void;
    onRemove?: (env: any) => Promise<void>
}

const BuildForm = ({build, environment, onSubmit, onCancel, onRemove}: IProps) => {
    const {triggerBuild} = useWorkspace();
    const {handleSubmit, watch} = useForm();
    // const assets = build.snapshot && build.snapshot.urls ? build.snapshot.urls : [];
    const routes = build.snapshot && build.snapshot.routes ? build.snapshot.routes : [];
    const assets = routes.map((r: any) => {
        return r.urls.map(({key, url}: any) => {
            return {
                key,
                url,
                routeSlug: r.slug
            }
        });
    }).flat()
    const retryBuild = async () => {
        console.log("retryBuild", build.key)
        triggerBuild(build.key)
    }
    return (
        <>
            <div className={'min-h-[470px]'}>
                <Tabs tabs={[
                    {
                        label: <span>Details</span>,
                        content: <>
                            <div className="grid grid-cols-6 gap-6  px-4 py-5 sm:p-6">
                                <div className="col-span-6">
                                    <LabelEnvLoaderUrl env={environment} build={build}/>
                                </div>
                                <div className="col-span-6">
                                    <table
                                        className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                        <thead
                                            className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                                        <tr>

                                            <th scope="col" className="px-6 py-3">
                                                Key
                                            </th>
                                            <th scope="col" className="px-6 py-3">
                                                Value
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                                            <th scope="row"
                                                className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap dark:text-gray-400">
                                                Status
                                            </th>
                                            <td className="px-6 py-4">
                                                <LabelBuildStatus build={build}/>
                                            </td>
                                        </tr>
                                        <RowNameValue name={"Key"} value={build.key}/>
                                        <RowNameValue name={"Size"} value={`${build.size}MB`}/>
                                        <RowNameValue name={"Assets"} value={`${assets.length} files`}/>


                                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                                            <th scope="row"
                                                className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap dark:text-gray-400">
                                                Routes
                                            </th>
                                            <td className="px-6 py-4">
                                                {routes.map((r: any) => {

                                                    return <span key={r.slug}
                                                                 className="px-2 mr-1 py-1.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">
                                                        {r.key}
                                                    </span>
                                                })}
                                            </td>
                                        </tr>
                                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                                            <th scope="row"
                                                className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap dark:text-gray-400">
                                                Screenshot
                                            </th>
                                            <td className="px-6 py-4">
                                                <LabelBuildScreenshot env={environment}
                                                                      width={400}
                                                                      build={build}/>
                                            </td>
                                        </tr>

                                        <RowNameValue name={"CI/CD"} value={`${build.viaApi ? "Yes" : "No"}`}/>
                                        <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                                            <th scope="row"
                                                className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap dark:text-gray-400">
                                                Triggered By
                                            </th>
                                            <td className="px-6 py-4">
                                                <div className={'flex items-center'}>
                                                    <div className={'h-11 w-11'}>
                                                        <Avatar url={build.profile.avatar}/>
                                                    </div>
                                                    <span
                                                        className={'ml-2'}>{build.profile.firstname} {build.profile.lastname} </span>

                                                </div>
                                            </td>
                                        </tr>
                                        <RowNameValue name={"Completed At"}
                                                      value={<LabelDateTime timestamp={build.completedAt}/>}/>

                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-span-6">
                                    <FormGlobalErrorMessage/>
                                    <FormGlobalSuccessMessage/>
                                </div>
                            </div>
                            <div className={''}>
                                <div
                                    className=" flex items-center justify-between p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                                    <div className={'flex items-center space-x-2'}>
                                        <DialogConfirmation
                                            renderTrigger={<button type="button"
                                                                   className="text-lg min-h-[50px] flex justify-center items-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                                                Retry
                                            </button>}
                                            onApprove={retryBuild}
                                        />
                                        <DialogConfirmation
                                            renderTrigger={<button type="button"
                                                                   className="text-gray-500 min-h-[50px] bg-white hover:bg-error-100 focus:ring-4 focus:outline-none focus:ring-primary-300   text-lg font-medium px-5 py-2.5 hover:text-error-950 focus:z-10 dark:bg-error-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                                                Cancel
                                            </button>}
                                            onApprove={() => {
                                                handleSubmit(async () => {
                                                    onRemove && await onRemove(build)
                                                })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    },
                    {
                        label: <span>Assets</span>,
                        content: <div className="grid grid-cols-6 gap-6  px-4 py-5 sm:p-6">
                            <div className="col-span-6">
                                <BuildAssetsForm build={build} assets={assets}/>
                            </div>
                        </div>
                    },
                    {
                        label: <span>JSON</span>,
                        content: <div className="grid grid-cols-6 gap-6  px-4 py-5 sm:p-6">
                            <div className="col-span-6 sm:col-span-4">
                                <div className={'min-h-[600px] mt-5 '}>
                                    <pre>{JSON.stringify({build}, null, 2)}</pre>
                                </div>
                            </div>
                        </div>
                    },


                ]}/>
            </div>


            {/*<div*/}
            {/*    className="flex items-center justify-between p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">*/}
            {/*    <div className={'flex items-center space-x-2'}>*/}
            {/*        <ButtonFormSubmit onSubmit={onSubmit}/>*/}
            {/*        <button type="button"*/}
            {/*                onClick={onCancel}*/}
            {/*                className="text-gray-500 min-h-[50px] bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg border border-gray-200 text-lg font-medium px-5 py-2.5 hover:text-gray-950 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">*/}
            {/*            Cancel*/}
            {/*        </button>*/}
            {/*    </div>*/}


            {/*</div>*/}
        </>);

};

export const RowNameValue = ({name, value}: any) => {
    return <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
        <th scope="row"
            className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap dark:text-gray-400">
            {name}
        </th>
        <td className="px-6 py-4">
           <span
               className="px-2 py-1.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">
           {value}
            </span>
        </td>
    </tr>
}

export default withForm(BuildForm)
