
import {IFilter} from "../../table/context/GenericTableContext";

export const guestFilters: IFilter[] = [



];


