import React from "react"

const BuildAssetsForm = ({build, assets}: any) => {
    return (
        <>
            <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Route
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Original URL
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Local Pathname
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {assets.map((asset: any, index: number) => {
                        return <tr key={asset.to + index}
                                   className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                            <th scope="row"
                                className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap dark:text-gray-400">
                                {asset.routeSlug}

                            </th>
                            <th scope="row"
                                className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap dark:text-gray-400">
                                {cropString(asset.url)}

                            </th>
                            <td className="px-6 py-4">
                                <span
                                    className="px-2 py-1.5 text-xs font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg dark:bg-gray-600 dark:text-gray-100 dark:border-gray-500">
                                {cropString(asset.key)}
                            </span>
                            </td>
                        </tr>
                    })}

                    </tbody>
                </table>
            </div>


        </>
    )
}

function cropString(str = "") {
    if (str === null) {
        return ''
    }
    const frontLen = 30;
    const backLen = 30;
    const strLen = str.length
    const truncateStr = '......'
    if (frontLen >= strLen || backLen >= strLen || (frontLen + backLen) >= strLen) {
        return str
    } else {
        return str.slice(0, frontLen) + truncateStr + str.slice(strLen - backLen)
    }
}

export {BuildAssetsForm}