import React from "react"

const LabelEnvironmentDomain = ({env}: any) => {

    const domain = (() => {
        try {
            return new URL(env.rootUrl).hostname.replace('www.', '');
        } catch {
            return ""
        }
    })();
    return (
        <>
            <span
                className={``}>
                {env.name || domain}
            </span>

        </>
    )
}

export {LabelEnvironmentDomain}