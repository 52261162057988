import React from "react"
import {DialogBasic} from "front-lib";
import UserForm from "../forms/UserForm";
import {useGenericTable} from "../../../table/context/GenericTableContext";
import {useUserCrudApi} from "../api/useUserCrudApi";

const DialogEditUser = ({user}: any) => {
    const crudApi = useUserCrudApi();
    const {loadRows} = useGenericTable();
    return (
        <>
            <DialogBasic
                title={"Edit User"}
                renderTrigger={<button
                    className="rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    Edit
                </button>}
                renderContent={({toggleOpen}: any) => {
                    return <>
                        <UserForm user={user} onSubmit={async ({dirtyValues}: any) => {
                            console.log("dirtyValues", dirtyValues)
                            await crudApi.edit({id: user.id, ...dirtyValues});
                            await loadRows()
                            toggleOpen()
                        }} onCancel={toggleOpen}/>
                    </>
                }}
            />
        </>
    )
}

export default DialogEditUser