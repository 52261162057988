import React from "react";
import {IColumn} from "../../table/context/GenericTableContext";
import {Avatar, LazyLabelDateTime} from "front-lib";

export const flowColumns: IColumn[] = [
    {
        property: "id",
        label: "ID",
        isVisible: false,
        component: ({row, column}) => <>
            {row.id}
        </>
    },
    {
        property: "user",
        label: "User",
        isVisible: true,
        component: ({row, column}) => <div className={'flex items-center'}>
            <div className={'h-11 w-11'}>
                <Avatar url={row.profile.avatar}/>
            </div>
            <span className={'ml-2'}>{row.profile.firstname} {row.profile.lastname} </span>

        </div>
    },

    {
        property: "key",
        label: "Key",
        isVisible: true,
        component: ({row, column}) => <>
            {row.key}
        </>
    },
    {
        property: "build",
        label: "Build",
        isVisible: true,
        component: ({row, column}) => <>
            {row.build.key}
        </>
    },
    {
        property: "selectors",
        label: "Selectors",
        isVisible: true,
        component: ({row, column}) => <>
            <div className={''}>
                <pre>{JSON.stringify(row.selectors, null, 2)}</pre>
            </div>
        </>
    },
    {
        property: "createdAt",
        label: "Created",
        isVisible: true,
        component: ({row, column}) => <>
            <LazyLabelDateTime timestamp={row.createdAt}/>
        </>
    },

];
