import React from "react";
import {Helmet} from "react-helmet-async";
import NotificationTable from "../../../../modules/admin/crud/entities/notification/NotificationTable";

const NotificationsRoute = ({}) => {
    return (
        <>
            <Helmet>
                <title>Notifications</title>
            </Helmet>
            <div className={'mx-auto max-w-screen-xl'}>
                {/*<Breadcrumb/>*/}
                {/*<br/>*/}
                <NotificationTable/>
            </div>
        </>
    )
}

export default NotificationsRoute