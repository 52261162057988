import React, {useState} from "react";
import {DialogAnimated} from "./DialogAnimated";

const DialogConfirmation = ({
                                title = "Confirm your action",
                                renderTrigger,
                                renderContent,
                                onApprove,
                                onCancel
                            }: any) => {
    const [isOpen, setIsOpen] = useState(false);

    const _renderContent = renderContent || (() => {
        return <>
            Before proceeding, please take a moment to confirm your intentions.
        </>
    })
    const renderTriggerWithOnClick = () => {
        const trigger = typeof (renderTrigger) === 'function' ? renderTrigger({isOpen}) : renderTrigger;
        if (React.isValidElement(trigger)) {
            return React.cloneElement<any>(trigger, {
                onClick: (event: any) => {
                    event.stopPropagation();
                    toggleOpen()
                }
            });
        }
        return renderTrigger
    }

    const toggleOpen = () => {
        // console.log("toggleOpen")
        const newState = !isOpen;
        setIsOpen(newState);
    }

    return (
        <>
            {renderTriggerWithOnClick()}
            <DialogAnimated isOpen={isOpen} onClose={toggleOpen} title={title} size={"md"}>
                <>
                    <div className={'pb-4 px-4 sm:px-6'}>
                        {_renderContent()}
                    </div>
                    <div
                        className="flex items-center p-4 sm:p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">

                        <button type="submit"
                                onClick={async () => {
                                    onApprove && await onApprove()
                                    toggleOpen();
                                }}
                                className={`text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800`}>
                            Confirm
                        </button>
                        <button type="button"
                                onClick={async () => {
                                    onCancel && await onCancel()
                                    toggleOpen();
                                }}
                                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-primary-300 rounded-lg border border-gray-200 font-medium px-5 py-2.5 hover:text-gray-950 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                            Cancel
                        </button>
                    </div>
                </>
            </DialogAnimated>
        </>
    )
}

export {DialogConfirmation}