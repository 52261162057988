import React, { useContext, useState } from "react";
import { FormContext } from "./FormContext";


export function useForm() {
  const context = useContext(FormContext);

  if (context === undefined) {
    throw new Error("useForm must be used within FormContextProvider");
  }
  return context;
}

