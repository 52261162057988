import React from "react";
import {IColumn} from "../../table/context/GenericTableContext";
import {Avatar, LazyLabelDateTime} from "front-lib";

export const guestColumns: IColumn[] = [
    {
        property: "id",
        label: "ID",
        isVisible: false,
        component: ({row, column}) => <>
            {row.id}
        </>
    },
    {
        property: "createdBy",
        label: "Created By",
        isVisible: true,
        component: ({row, column}) => <div className={'flex items-center'}>
            <div className={'h-11 w-11'}>
                <Avatar url={row.createdBy.avatar}/>
            </div>
            <span className={'ml-2'}>{row.createdBy.firstname} {row.createdBy.lastname} </span>

        </div>
    },

    {
        property: "environment",
        label: "Environment",
        isVisible: true,
        component: ({row, column}) => <>
            {row.environment.key}
        </>
    },
    {
        property: "credentials",
        label: "Credentials",
        isVisible: true,
        component: ({row, column}) => <>
            {row.username}/{row.password}
        </>
    },
    {
        property: "createdAt",
        label: "Created",
        isVisible: true,
        component: ({row, column}) => <>
            <LazyLabelDateTime timestamp={row.createdAt}/>
        </>
    },

];
