import React from "react"
import {showToast, IToastMethods} from "front-lib";
import ToastUploadCompleted from "./ToastUploadCompleted";
import ToastRecordingUploadStarted from "./ToastRecordingUploadStarted";
import ToastBuildCompleted from "./ToastBuildCompleted";
import ToastBuildStarted from "./ToastBuildStarted";


export const useToasts = () => {

    const showBuildStartedToast = (build: any) => {
        showToast(({closeToast}: IToastMethods) => {
            return <ToastBuildStarted build={build} onClose={closeToast}/>
        }, `toast-build-started`)
    }


    const showBuildCompletedToast = (build: any) => {
        showToast(({closeToast}: IToastMethods) => {
            return <ToastBuildCompleted build={build} onClose={closeToast}/>
        }, `toast-build-completed`)
    }

    // const showRecordingUploadStartedToast = () => {
    //     showToast(({closeToast}: IToastMethods) => {
    //         return <ToastRecordingUploadStarted onClose={closeToast}/>
    //     }, `recording-process-started`)
    // }


    return {
        showBuildStartedToast,
        showBuildCompletedToast
    }
}