import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumbs = ({breadcrumbs}: any) => {
    return (
        <>
            <nav className="flex mt-2" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                    {breadcrumbs.map((b: any, index: number) => {
                        return <li className="inline-flex items-center" key={b.text}>
                            <Link to={b.to}
                               className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-950 dark:text-gray-400 dark:hover:text-white">
                                {index === 0 ? <HomeIcon/> : <ArrowIcon/>}
                                {b.text}
                            </Link>
                        </li>
                    })}

                    {/*<li aria-current="page">*/}
                    {/*    <div className="flex items-center">*/}
                    {/*        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20"*/}
                    {/*             xmlns="http://www.w3.org/2000/svg">*/}
                    {/*            <path fillRule="evenodd"*/}
                    {/*                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"*/}
                    {/*                  clipRule="evenodd"/>*/}
                    {/*        </svg>*/}
                    {/*        <span*/}
                    {/*            className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">*/}
                    {/*            donoslide*/}
                    {/*        </span>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    {/*<li aria-current="page">*/}
                    {/*    <div className="flex items-center">*/}
                    {/*        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20"*/}
                    {/*             xmlns="http://www.w3.org/2000/svg">*/}
                    {/*            <path fillRule="evenodd"*/}
                    {/*                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"*/}
                    {/*                  clipRule="evenodd"/>*/}
                    {/*        </svg>*/}
                    {/*        <span*/}
                    {/*            className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">*/}

                    {/*            b137*/}
                    {/*        </span>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                </ol>
            </nav>
        </>
    )
}
const HomeIcon = () => {
    return <>
        <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"/>
        </svg>
    </>
}
const ArrowIcon = () => {
    return <>
        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"/>
        </svg>
    </>
}
export {Breadcrumbs}