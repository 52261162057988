import {useContext, useEffect, useState} from "react";
import {SocketContext} from "./SocketProvider";

export const useSocket = () => {
    // console.log("useSocket");
    const context = useContext(SocketContext);
    if (context === undefined) {
        throw new Error("useSocket must be used within SocketProvider");
    }
    return context;
}

export const useSocketEvent = (event: string, callback: Function, deps: any[] = []) => {
    const {socket} = useSocket();
    useEffect(() => {
        // console.log("on", event)
        socket.on(event, callback)
        return () => {
            // console.log("off", event)
            socket.off(event, callback)
        };
    }, deps)
}