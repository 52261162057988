import React, {useEffect} from "react";
import GenericTable from "../../table/GenericTable";
import {userFilters} from "./UserFilters";
import {userColumns} from "./UserColumns";
import DialogCreateUser from "./dialogs/DialogCreateUser";
import {useUserCrudApi} from "./api/useUserCrudApi";

const UserTable =() => {
    const crudApi = useUserCrudApi();
    return (
        <>

            <GenericTable
                // onRowClick={({row}) => {
                //     console.log("onRowClick", row)
                // }}
                renderHeader={({loadRows}) => {
                    return <>
                        <div className={'flex items-center'}>
                            <h3 className="font-semibold text-lg">Users</h3>
                            {/*<button id="toggleSidebar" aria-expanded="true" aria-controls="sidebar"*/}
                            {/*        onClick={() => {*/}
                            {/*        }}*/}
                            {/*        className="p-2 ml-1 text-gray-600 rounded cursor-pointer lg:inline hover:text-gray-950 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700">*/}
                            {/*    <RiFilter3Line size={22}/>*/}
                            {/*</button>*/}
                        </div>
                        <DialogCreateUser onCreated={loadRows} renderTrigger={<button
                            className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            Create
                        </button>}/>

                    </>
                }}
                crudApi={crudApi}
                filters={userFilters}
                columns={userColumns}
            />
        </>
    );
};

export default UserTable;
