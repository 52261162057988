// import jsonColors from "../../../../style/colors.json"
// @ts-ignore
import {tint, shade} from "tint-shade-color";

// const cssVariables: any = jsonColors;

export const themeColors = ["primary", "secondary", "gray", "success", "error", "warning"]
export const ThemeService = {
    getDefaultTheme: (): Theme => {
        const theme: Theme = {
            palette: themeColors.reduce<any>((obj: any, key: string) => {
                const shades = getCssVariablesFromDocument(key);
                return {
                    ...obj,
                    [key]: {
                        main: shades[5],
                        shades
                    }
                }
            }, {})
        }
        // console.log("getDefaultTheme", theme)
        return theme;
    },
    setDarkMode: (isDarkMode: boolean) => {
        // console.log("setDarkMode", isDarkMode)
        const elements: any = document.getElementsByClassName("tp-137");

        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (isDarkMode) {
                element.classList.add("dark");
            } else {
                element.classList.remove("dark");

            }
        }
    },
    setThemeColor: (colorName: string, shades: string[]) => {
        // console.log("setThemeColor", colorName, shades)
        setCssVariablesInDocument(colorName, shades)
    },
    getShades(hex: string) {
        const shades: string[] = []
        const lightestColor = tint(hex, 0.2)
        // console.log("lightest Color", lightestColor)
        for (let i = 0; i < 11; i++) {
            const factor = 0.065 * i;
            const colorWithShade = shade(lightestColor, factor); // #664b21
            // console.log("shade", colorWithShade, i, factor)
            // colors.push((colorWithShade))
            shades.push(colorWithShade)
        }

        return shades;
    }
}


function getCssVariablesFromDocument(colorName: string) {
    return [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950].map((key) => {
        const element = document.documentElement;//document.getElementsByClassName("theme-provider")[0] ; //document.documentElement
        const shade = getComputedStyle(element).getPropertyValue(`--color-${colorName}-${key}`).trim();
        const rgb = shade.trim().split(" ").map(str => parseInt(str))
        return rgbToHex(rgb[0], rgb[1], rgb[2])
        // const shade = cssVariables[`--color-${colorName}-${key}`]
        // // console.log("shade", `--color-${colorName}-${key}`, shade)
        // const rgb = shade.trim().split(" ")
        // return rgbToHex(rgb[0], rgb[1], rgb[2])
    });
}


function setCssVariablesInDocument(colorName: string, shades: string[]) {
    const elements: any = document.getElementsByClassName("tp-137");
    console.log("setCssVariablesInDocument", elements, shades)

    for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        element.style.setProperty(`--color-${colorName}-50`, hexToRgb(shades[0]));
        element.style.setProperty(`--color-${colorName}-100`, hexToRgb(shades[1]));
        element.style.setProperty(`--color-${colorName}-200`, hexToRgb(shades[2]));
        element.style.setProperty(`--color-${colorName}-300`, hexToRgb(shades[3]));
        element.style.setProperty(`--color-${colorName}-400`, hexToRgb(shades[4]));
        element.style.setProperty(`--color-${colorName}-500`, hexToRgb(shades[5]));
        element.style.setProperty(`--color-${colorName}-600`, hexToRgb(shades[6]));
        element.style.setProperty(`--color-${colorName}-700`, hexToRgb(shades[7]));
        element.style.setProperty(`--color-${colorName}-800`, hexToRgb(shades[8]));
        element.style.setProperty(`--color-${colorName}-900`, hexToRgb(shades[9]));
        element.style.setProperty(`--color-${colorName}-950`, hexToRgb(shades[10]));
    }


}


export function hexToRgb(hex: string): string {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `${parseInt(result[1], 16)} ${parseInt(result[2], 16)} ${parseInt(result[3], 16)}` : '';

}

export function rgbToHex(r: number, g: number, b: number) {
    return "#" + (1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1);
}

export interface ThemeColor {

    main: string;
    shades: string[]
}

export interface ThemeColorPalette {
    primary: ThemeColor,
    secondary: ThemeColor,
    gray: ThemeColor,
    success: ThemeColor,
    error: ThemeColor,
    warning: ThemeColor,
}

export interface Theme {
    name?: string;
    palette: ThemeColorPalette
}