import React, {useEffect} from "react";
import {Outlet, useLocation} from "react-router-dom";
import HelmetSiteRouteSeoMetaTags from "../site/seo/HelmetSiteRouteSeoMetaTags";
import {HotToaster, InlineDevTools} from "front-lib";



const SharedLayout = ({}) => {

    const {pathname} = useLocation();

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            // @ts-ignore
            behavior: "instant",
        });
        // console.log("route changed", pathname);
        // ReactGA.send({ hitType: "pageview", page: pathname});

    }, [pathname]);

    return (
        <>
            <HelmetSiteRouteSeoMetaTags/>
            <Outlet/>
            <InlineDevTools/>
            <HotToaster/>
        </>
    )
}

export default SharedLayout