import {LoadingStripes, api, useUser, useSocketEvent} from "front-lib";
import React, {  useEffect, useState} from "react";
import {useToasts} from "frontenvs-lib";
import { WorkspaceContext } from "./context";


const WorkspaceContextProvider = ({children, username}: any) => {
    const {user} = useUser();
    const {showBuildCompletedToast, showBuildStartedToast} = useToasts();
    const profile = {
        ...user.profile,
        user: {
            ...user,
            profile: undefined
        }
    }
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [stats, setStats] = useState<any>(null);
    const [workspaceUsers, setWorkspaceUsers] = useState<any[]>([]);
    const [workspaceEnvironments, setWorkspaceEnvironments] = useState<any[]>([]);
    const [workspaceBuilds, setWorkspaceBuilds] = useState<any[]>([]);
    // const [workspaceTags, setWorkspaceTags] = useState<any[]>([]);
    // const [workspaceSubTags, setWorkspaceSubTags] = useState<any[]>([]);
    // const [workspaceBuckets, setWorkspaceBuckets] = useState<any[]>([]);
    // const [workspaceReports, setWorkspaceReports] = useState<any[]>([]);


    const loadWorkspaceUsers = async () => {
        const response = await api.get('/workspace/users');
        return response.data;
    }

    const loadWorkspaceEnvironments = async () => {
        const response = await api.get('/workspace/environments');
        return response.data;
    }

    const loadWorkspaceBuilds = async () => {
        const response = await api.get('/workspace/builds');
        return response.data;
    }

    // const getEnvByKey = async (envKey: string) => {
    //     const response = await api.get(`/workspace/environments/${envKey}`);
    //     return response.data;
    // }

    const createEnvironment = async (environment: any) => {
        const response = await api.post('/workspace/environments', environment);
        const environmentDb = {...response.data}
        // await loadEnvsAndBuildsData();
        console.log("createEnvironment end", environmentDb)
        return environmentDb;
    }
    const editEnvironment = async (environment: any) => {
        const response = await api.put('/workspace/environments', environment);
        const environmentDb = {...response.data};
        await loadEnvsAndBuildsData();
        console.log("editEnvironment end", environmentDb)
    }
    const loadStats = async () => {
        const response = await api.get('/workspace/stats');
        return response.data.stats;
    }

    const editWorkspace = async (workspace: any) => {
        const response = await api.put('/workspace', workspace);
        const conversationDb = {...response.data}
        console.log("editWorkspace end", conversationDb)
    }
    const buildEnv = async (envKey: string) => {
        const prepareResponse = await api.get(`/workspace/builds/prepare/${envKey}`)
        const buildKey = prepareResponse.data.build.key;
        triggerBuild(buildKey)
        // console.log("build result", response.data);
    }
    const triggerBuild = async (buildKey: string) => {
        console.log("triggerBuild", buildKey);
        const response = await api.get(`/workspace/builds/trigger/${buildKey}`);
    }

    async function loadEnvsAndBuildsData() {
        console.log("loadEnvsAndBuildsData")
        const workspaceEnvironments = await loadWorkspaceEnvironments();
        setWorkspaceEnvironments(workspaceEnvironments);

        const workspaceBuilds = await loadWorkspaceBuilds();
        setWorkspaceBuilds(workspaceBuilds);

        const stats = await loadStats();
        setStats(stats);
    }

    const initWorkSpace = async (key?: string) => {
        try {

            const workspaceUsers = await loadWorkspaceUsers();
            setWorkspaceUsers(workspaceUsers);
            await loadEnvsAndBuildsData();

        } catch (err) {

        } finally {
            setIsLoading(false)

        }

    }


    useEffect(() => {
        setIsLoading(true);
        initWorkSpace();
    }, [])

    useSocketEvent("build-completed", async (args: any) => {
        console.log("socket event : build-completed", args);
        const {build} = args
        await loadEnvsAndBuildsData();
        showBuildCompletedToast(build);
    }, [])

    useSocketEvent("build-started", async (args: any) => {
        console.log("socket event : build-started", args);
        const {build} = args
        await loadEnvsAndBuildsData();
        showBuildStartedToast(build);
    }, [])


    const context = {
        createEnvironment,
        editEnvironment,
        workspaceBuilds,
        triggerBuild,
        // workspaceTags,
        // workspaceSubTags,
        loadEnvsAndBuildsData,
        workspaceUsers,
        workspaceEnvironments,
        loadWorkspaceUsers,
        buildEnv,
        initWorkSpace,
        editWorkspace,
        stats,
        isLoading,
        profile
    }
    return (
        <WorkspaceContext.Provider value={context}>
            {!isLoading && children}
            {isLoading && <div className={'flex justify-center items-center  mx-auto pt-10'}>
                <LoadingStripes/>
            </div>}
        </WorkspaceContext.Provider>
    )
}

export default WorkspaceContextProvider
