import React from "react"
import {DialogBasic , Role} from "front-lib";
import UserForm from "../forms/UserForm";
import {useUserCrudApi} from "../api/useUserCrudApi";

const DialogCreateUser = ({renderTrigger, onCreated}: any) => {
    const crudApi = useUserCrudApi();

    return (
        <>
            <DialogBasic
                title={"Create User"}
                renderTrigger={renderTrigger}
                renderContent={({toggleOpen}: any) => {
                    return <>
                        <UserForm user={{role: Role.User, profile: {}}} onSubmit={async ({fieldValues}: any) => {

                            console.log("fieldValues", fieldValues)
                            await crudApi.create(fieldValues);
                            await onCreated && onCreated();
                            toggleOpen();
                        }} onCancel={toggleOpen}/>
                    </>
                }}
            />
        </>
    )
}

export default DialogCreateUser