import React from "react";
import {Link, useLocation} from "react-router-dom";
import BrandName from "../brand/BrandName";

const AuthHeader = ({}) => {
    const {pathname} = useLocation();
    return (
        <>
            <header className={'fixed w-full z-40 bg-white dark:bg-black h-[60px]'}>
                <nav className="border-gray-200 px-6 py-2.5  max-w-screen-xl mx-auto h-full">
                    <div className="flex flex-wrap justify-between items-center h-full">
                        <BrandName/>
                        {/*{pathname.indexOf("auth/social/cb") === -1 && <div className="flex items-center lg:order-2">*/}
                        {/*    <ul className="flex flex-row  font-medium   lg:space-x-5">*/}
                        {/*        {pathname !== "/register" && <li>*/}
                        {/*            <Link to={"/register"}*/}
                        {/*                  className="block py-2 pr-4 pl-3 text-gray-700 hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">*/}
                        {/*                Sign Up*/}
                        {/*            </Link>*/}
                        {/*        </li>}*/}
                        {/*        {pathname !== "/login" && <li>*/}
                        {/*            <Link to={"/login"}*/}
                        {/*                  className="block py-2 pr-4 pl-3 text-gray-700 hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">*/}
                        {/*                Sign In*/}
                        {/*            </Link>*/}
                        {/*        </li>}*/}
                        {/*    </ul>*/}
                        {/*</div>}*/}
                    </div>
                </nav>
            </header>
        </>
    )
}

export default AuthHeader