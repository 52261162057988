import React, {useState} from "react";
import {useForm} from "react-hook-form";
import { FormContext } from "./FormContext";


const FormContextProvider = ({children}: any) => {
    const {handleSubmit, setError, clearErrors, formState, getValues, ...rest} = useForm({mode: "onBlur"});

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const dirtyFields = formState.dirtyFields;
    // const { handleSubmit, setError, clearErrors, formState, ...rest } = useFormContext();


    const _handleSubmit = function (onSubmit: (dirtyValues: any, fieldValues?: any) => Promise<string | void>) {
        // const oldValues = getValues()
        // console.log("form-before-submit", oldValues);
        clearErrors("global");

        handleSubmit(async (fieldValues) => {
            try {
                setSuccessMessage("");
                setIsSubmitting(true)
                const dirtyValues = getDirtyValues(fieldValues);
                const message = await onSubmit({dirtyValues, fieldValues});
                setIsSubmitting(false)
                if (message) {
                    setSuccessMessage(message);
                }

            } catch (e) {
                setGlobalFormError(e);
            } finally {
                setIsSubmitting(false)
            }
        })();
    };

    const getDirtyValues = (fieldValues: any) => {
        const dirtyKeys = Object.keys(dirtyFields);
        // console.log("getDirtyValues", dirtyKeys, fieldValues, formState)
        if (!dirtyKeys.length) return undefined;

        const dirtyValues: any = {}
        dirtyKeys.forEach((key: any) => {
            dirtyValues[key] = fieldValues[key];
        })
        return dirtyValues;
    }
    const setGlobalFormError = function (error: any) {
        console.log("global-error:", error);

        const err = {
            type: error.errorCode,
            message: error.message,
            details: error.details
        }
        setError("global", err);
    };

    const getError = (name: string) => {
        return byString(formState.errors, name);
    };


    const context = {
        handleSubmit: _handleSubmit,
        isSubmitting,
        setGlobalFormError,
        getError,
        setError,
        formState,
        // isSubmitting: formState.isSubmitting,
        isSubmitted: formState.isSubmitted,
        isSubmitSuccessful: formState.isSubmitSuccessful,
        clearErrors,
        successMessage,
        setSuccessMessage,
        getValues,
        dirtyFields,
        ...rest
    };
    return <FormContext.Provider value={context}>
        {children}
    </FormContext.Provider>;
};

export {FormContextProvider};

const byString = function (o: any, s: string) {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, "");           // strip a leading dot
    let a = s.split(".");
    for (let i = 0, n = a.length; i < n; ++i) {
        let k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
};

