import React, {useEffect} from "react";
import {ProfileSocialConnectionsForm} from "./ProfileSocialConnectionsForm";
import {FormGlobalSuccessMessage, FormInputText, IUser, useAuthContext, useForm, withForm} from "front-lib";
import {FormUploadAvatar} from "./FormUploadAvatar";

interface IProps {
    user: IUser
}

const UserAccountForm = withForm(({user}: IProps) => {

    const {updateMyProfileAndContextState} = useAuthContext()
    const {handleSubmit, watch} = useForm();
    const avatar = watch("avatar", user.profile.avatar);
    // const username = watch("username", user.profile.username);

    useEffect(() => {
        if (avatar === user.profile.avatar) return;
        console.log("avatar changed", avatar)
        const dirtyValues: any = {avatar};
        updateMyProfileAndContextState(dirtyValues);
    }, [avatar])

    // useEffect(() => {
    //     console.log("username changed222", username)
    // }, [username])

    // useDebouncedEffect(() => {
    //     if (!username) return;
    //     console.log("username changed", username)
    //     const dirtyValues: any = {username};
    //     updateMyProfileAndContextState(dirtyValues);
    // }, [username], 300)
    const onSubmit = async ({dirtyValues}: any) => {
        console.log("dirtyValues", dirtyValues)
        await updateMyProfileAndContextState(dirtyValues);
        return "Profile changes saved successfully"
    }
    return (
        <>
            <div className="space-y-6 bg-white dark:bg-gray-950 px-4 py-5 sm:p-6">
                <div>
                    <FormUploadAvatar defaultValue={user.profile.avatar}/>
                </div>
                <div className="grid grid-cols-6 gap-6">


                    <div className="col-span-6 sm:col-span-3">
                        <FormInputText label={"First name"} name={"firstname"} placeholder={"Bruce"}
                                       defaultValue={user.profile.firstname}/>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                        <FormInputText label={"Last name"} name={"lastname"} placeholder={"Wayne"}
                                       defaultValue={user.profile.lastname}/>
                    </div>

                    <div className="col-span-6 sm:col-span-4">
                        <FormInputText label={"Email"} name={"email"} placeholder={"bruce@waynecorp.com"}
                                       defaultValue={user.email} disabled={true}/>
                    </div>
                    {Boolean(user.twitchId || user.discordId) && <div className="col-span-6 sm:col-span-4">
                        <ProfileSocialConnectionsForm user={user}/>
                    </div>}

                    {/*<div className="col-span-6">*/}
                    {/*    <label className=" font-medium text-gray-950">Email</label>*/}
                    {/*    <div className="mt-4 space-y-4">*/}
                    {/*        <div>*/}
                    {/*            <FormSwitch defaultValue={user.profile.isEmailConfirmed}*/}
                    {/*                        disabled={true}*/}
                    {/*                        name={"profile.isEmailConfirmed"}*/}
                    {/*                        label={"Email Confirmed"}/>*/}
                    {/*            <p className="text-sm text-gray-500">*/}
                    {/*                {!user.profile.isEmailConfirmed && <>*/}
                    {/*                    Your email address <span className={''}>{user.email}</span> is not*/}
                    {/*                    confirmed yet.<br/>*/}
                    {/*                    request new confirmation email&nbsp;<a onClick={() => {*/}
                    {/*                    requestConfirmationEmail();*/}
                    {/*                    alert("requested new enmail")*/}
                    {/*                }} className={'font-semibold cursor-pointer hover:underline'}>here</a>.*/}
                    {/*                </>}*/}
                    {/*                {user.profile.isEmailConfirmed && <>*/}
                    {/*                    Your email address is confirmed.*/}
                    {/*                </>}*/}
                    {/*            </p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="col-span-6">
                        <FormGlobalSuccessMessage/>
                    </div>
                </div>


            </div>
            <div className="bg-gray-50 dark:bg-gray-900 px-4 py-3 text-right sm:px-6">
                {/*<ButtonFormSubmit/>*/}
                <button type="submit"
                        onClick={() => {
                            console.log("before on submit")
                            //make sure all the form is valid
                            handleSubmit(onSubmit)

                            // console.log("after on submit")
                        }}
                        className="inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">Save
                </button>
            </div>
        </>
    )
})

export {UserAccountForm}