import React from "react";
import { useForm } from "../../context";
import {TextErrorMessage} from "./TextErrorMessage";


interface IProps {
  name: string;
}

function FormFieldErrorMessage({ name }: IProps) {
  // const theme = useTheme();
  const { getError } = useForm();
  const error = getError(name);
  const hasError = Boolean(error);
  // console.log("FormFieldErrorMessage", error);
  return (
    <>
      {hasError && <div>
        <div
          style={{ margin: 0, color: "red", fontSize: "14px", marginTop: "1px" }}>
          <TextErrorMessage error={error} />
        </div>
      </div>}
    </>
  );
}


export { FormFieldErrorMessage };
