import {IProfile, IUser} from '../interfaces';
import {useAuthContext} from "../context";

export const useUser = (): { user: IUser } => {
    const {user} = useAuthContext();

    return {user};
};

export const useProfile = (): { profile: IProfile | null } => {
    const {user} = useUser();

    const profile = user && user.profile ? user.profile : null
    return {profile}
};
