import React from "react";
import {buildFilters} from "./BuildFilters";
import {buildColumns} from "./BuildColumns";
import GenericTable from "../../table/GenericTable";
import {useBuildCrudApi} from "./api/useBuildCrudApi";

const BuildCrudTable = ({withHeader = true}: any) => {
    const crudApi = useBuildCrudApi();

    return (
        <>
            <GenericTable renderHeader={withHeader && <>
                <div className={'flex items-center'}>
                    <h3 className="font-semibold text-lg dark:text-white">Builds</h3>

                </div>
            </>}
                          crudApi={crudApi}
                          filters={buildFilters}
                          columns={buildColumns}
            />
        </>
    );
};

export default BuildCrudTable;
