import React from "react";
import "../style/dev-tools.scss";

const DevToolVersionNumber = ({}) => {

    return (
        <div className={'inlineVersion'} onClick={() => {
            // navigate("/dev")
        }}>
            <LabelVersionNumber/>
        </div>
    )
}
const REACT_APP_ENV = (() => {
    const mode = import.meta.env.MODE;
    // if (mode === "development") return "dev";
    if (mode === "staging") return "staging";
    if (mode === "production") return "prod";
    return "dev";
})();
const REACT_APP_VERSION_NUMBER = import.meta.env.VITE_APP_VERSION;
export const LabelVersionNumber = () => {
    const version = REACT_APP_VERSION_NUMBER; //document.getElementsByName("version")[0].getAttribute("content");
    const env = REACT_APP_ENV;//|| document.getElementsByName("env")[0].getAttribute("content");
    // console.log("LabelVersionNumber", env, version)
    return <span>v{version}-{env}</span>
}

export {DevToolVersionNumber}
