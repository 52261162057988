import React from "react";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useWatch} from "react-hook-form";
import {
    ProtectedByReCaptchaText,
    AlertInfo,
    useForm,
    FormInputText,
    isValidEmail,
    ValidationError,
    FormInputPassword, FormCheckbox, ButtonFormSubmit, FormGlobalErrorMessage, withForm, AlertModern
} from "front-lib";
import {useReferral} from "../hooks/useReferral";
import {useAuthContext} from "../context";
// import {useReturnUrl} from "../hooks/useReturnUrl";

const RegisterForm = withForm(({returnURL = "/app"}) => {

    const {handleSubmit, control} = useForm();
    const {registerAndLoadMyProfile} = useAuthContext();
    const password = useWatch({control, name: "password"});
    const navigate = useNavigate();
    // const returnUrl = useReturnUrl();

    const {referralId, referralProfile} = useReferral();

    const doRegister = () => {

        handleSubmit(async ({fieldValues}: any) => {
            console.log('doRegister', fieldValues);
            await registerAndLoadMyProfile({
                firstname: fieldValues.firstname,
                lastname: fieldValues.lastname,
                email: fieldValues.email,
                password: fieldValues.password
            }, referralId);
            console.log("returnURL", returnURL)
            navigate(returnURL);
        });
    };

    return (
        <>
            {referralProfile && <div className={'pt-5'}>
                <AlertInfo>
                    You've been invited to join frontenvs by <span
                    className={'font-bold'}>{referralProfile.firstname} {referralProfile.lastname}</span>.
                </AlertInfo>
            </div>}

            <div className="flex flex-col items-center justify-center  py-8 lg:py-0">

                {/*<div>*/}
                {/*    <pre>{JSON.stringify(referralProfile, null, 2)}</pre>*/}
                {/*</div>*/}

                <div
                    className="w-full md:mt-0 sm:max-w-md xl:p-0 dark:border-gray-700">
                    <div className="space-y-1">
                        {/*<div className="rounded-t mb-0  py-6">*/}
                        {/*    <div className="text-center mb-3"><h6*/}
                        {/*        className="text-blueGray-500 text-sm font-bold">Sign up with</h6></div>*/}
                        {/*    <div className="flex justify-center">*/}
                        {/*        <ConnectWithDiscord/>*/}
                        {/*    </div>*/}
                        {/*    <hr className="mt-6 border-b-1 border-blueGray-300"/>*/}
                        {/*</div>*/}
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-950 md:text-2xl dark:text-white">
                            Create new account
                        </h1>
                        <div className="grid grid-cols-6 gap-4">
                            <div className="col-span-6 sm:col-span-3">
                                <FormInputText label={"First name"} name={"firstname"} placeholder={"Bruce"}/>
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <FormInputText label={"Last name"} name={"lastname"} placeholder={"Wayne"}/>
                            </div>
                            <div className="col-span-6">
                                <FormInputText label={'Your email'} name={"email"}
                                               placeholder={"bruce@waynecorp.com"}
                                               defaultValue={''}
                                               rules={{
                                                   required: true,
                                                   validate: (value: string) => {
                                                       const isValid = isValidEmail(value)
                                                       return isValid || ValidationError.ERROR_NOT_VALID_EMAIL
                                                   }
                                               }}
                                />
                            </div>
                            <div className="col-span-6">
                                <FormInputPassword label={'Password'} name={"password"}
                                                   defaultValue={''}
                                                   rules={{
                                                       required: true,
                                                   }}
                                />
                            </div>
                            <div className="col-span-6">
                                <FormInputPassword label={'Confirm Password'} name={"confirmPassword"}
                                                   defaultValue={''}
                                                   rules={{
                                                       required: true,
                                                       validate: (value: any) => value === password || "PASSWORD_NOT_MATCH"
                                                   }}
                                />
                            </div>
                            <div className="col-span-6">
                                <div className="flex items-start text-sm">
                                    <div className="font-light">
                                        <FormCheckbox label={"I accept the "} name={"terms"}
                                                      rules={{
                                                          required: true,
                                                      }}/>
                                    </div>
                                    <span>&nbsp;</span>
                                    <Link className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                                          to={"/terms"} target={"_blank"}>
                                        Terms and Conditions
                                    </Link>
                                </div>
                            </div>
                            <div className="col-span-6">
                                <ButtonFormSubmit onSubmit={doRegister} text={"Create an account"}
                                                  className={'w-full'}/>
                            </div>
                            <div className="col-span-6">
                                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                                    Already have an account? <Link to={"/login"}
                                                                   className="font-medium text-primary-600 hover:underline dark:text-primary-500">
                                    Login here
                                </Link>
                                </p>
                                <FormGlobalErrorMessage className={'mt-2'}/>
                            </div>


                        </div>
                    </div>
                </div>
                <div className={'mx-auto sm:max-w-md'}>
                    <ProtectedByReCaptchaText/>
                </div>
            </div>
        </>
    )
})

export {RegisterForm}