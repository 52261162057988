
import {IFilter} from "../../table/context/GenericTableContext";

export const flowFilters: IFilter[] = [



];


