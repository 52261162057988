import React from "react";
import {
    AiOutlineAudit,
    BiMessageAlt, BsTag,
    FaTicketAlt,
    FaUser, FiFile,
    MdChat,
    RiBearSmileLine,
    SiSocketdotio
} from "react-icons/all";
import {useWorkspace} from "../context/useWorkspace";

const WorkspaceStats = ({}: any) => {
    const {stats} = useWorkspace();

    return (
        <>
            {stats && <div className="grid grid-cols-8 gap-3 mb-3">
                <StatItem value={stats.conversationsCount.toLocaleString("en-US")} text={"Reports"}
                          icon={<MdChat className={'w-8 h-8'}/>}/>
                <StatItem value={stats.puppetsCount.toLocaleString("en-US")} text={"Files"}
                          icon={<MdChat className={'w-8 h-8'}/>}/>


                <StatItem value={stats.messagesCount.toLocaleString("en-US")} text={"Tags"}
                          icon={<BsTag className={'w-8 h-8'}/>}/>

                <StatItem value={stats.usersCount.toLocaleString("en-US")} text={"Users"}
                          icon={<FaUser className={'w-8 h-8'}/>}/>

                {/*<StatItem value={stats.filesCount} text={"Files"}*/}
                {/*          icon={<FiFile className={'w-8 h-8'}/>}/>*/}


            </div>}
        </>
    )
}

const StatItem = ({icon, text, value}: any) => {

    return <>
        <div className={`col-span-8 sm:col-span-4 xl:col-span-2`}>
            <div
                className="relative flex flex-col min-w-0 break-words bg-white dark:bg-gray-950 rounded xl:mb-0 shadow-lg">
                <div className="flex-auto p-4">
                    <div className="flex flex-wrap">
                        <div className="relative w-full pr-4 max-w-full flex-grow flex-1"><h5
                            className=" uppercase font-bold text-xs">{text}</h5><span
                            className="font-semibold text-xl ">{value}</span></div>
                        <div className="relative w-auto pl-4 flex-initial">
                            <div
                                className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full bg-primary-500">
                                {icon}
                            </div>
                        </div>
                    </div>
                    {/*<p className="text-sm  mt-4"><span className="text-orange-500 mr-2"><i*/}
                    {/*    className="fas fa-arrow-down"></i> 1.10%</span><span className="whitespace-nowrap">Since yesterday</span>*/}
                    {/*</p>*/}
                </div>
            </div>
        </div>
    </>
}
export default WorkspaceStats