import React from "react";
import {Outlet} from "react-router-dom";
import SiteHeader from "./header/SiteHeader";
import SiteFooter from "./footer/SiteFooter";

const SiteLayout = ({children = <Outlet/>}) => {

    return (
        <>
            <div className={' '}>
                <SiteHeader/>
                <div className={'pt-[60px] min-h-[calc(100vh_-_130px)] site-layout'}>
                    {children}
                </div>
                <div className={'mt-[60px]'}>
                    <SiteFooter/>
                </div>
            </div>
        </>
    )
}

export default SiteLayout