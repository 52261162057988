import React from "react"

const LabelBuildStatus = ({build}: any) => {

    const labels: any = {
        "completed": <span
            className="inline-flex items-center bg-success-100 text-success-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-success-900 dark:text-success-300">
                <span className="w-2 h-2 mr-1 bg-success-500 rounded-full"></span>
                Completed
            </span>,
        "not-active": <span
            className="inline-flex items-center bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">
                <span className="w-2 h-2 mr-1 bg-gray-500 rounded-full"></span>
                Not Active
            </span>,
        "building": <span
            className="inline-flex items-center bg-warning-100 text-warning-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-warning-700 dark:text-warning-300">
                <span className="w-2 h-2 mr-1 bg-warning-500 rounded-full"></span>
                Building
            </span>,
        "error": <span
            className="inline-flex items-center bg-error-100 text-error-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-error-700 dark:text-error-300">
                <span className="w-2 h-2 mr-1 bg-error-500 rounded-full"></span>
                Error
            </span>
    }
    const label = labels[build.status];
    return (
        <>
            {label}
        </>
    )
}

export {LabelBuildStatus}