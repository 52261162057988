import React from "react";
import ContactUsForm from "../forms/ContactUsForm";
import {DialogBasic} from "front-lib";

const DialogContactUs = ({renderTrigger, source}: any) => {

    const _renderTrigger = renderTrigger || <a
        className="font-medium text-primary-600 hover:underline dark:text-primary-500 cursor-pointer">
        Contact Us
    </a>

    return (
        <>
            <DialogBasic
                title={"Contact Us"}
                renderTrigger={_renderTrigger}
                renderContent={({toggleOpen}: any) => {
                    return <div className={'p-8'}>
                        <ContactUsForm onSubmit={async () => {

                            // toggleOpen()
                        }} onCancel={toggleOpen} source={source}/>
                    </div>
                }}
            />
        </>
    )
}

export default DialogContactUs