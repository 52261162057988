import React from "react";
import {useWindowSize} from "front-lib";
import {useTailwindBreakpoint} from "../../theme";
import "../style/dev-tools.scss";


const DevToolBreakPoint = ({}) => {
    const windowSize = useWindowSize();
    const breakPoint = useTailwindBreakpoint()
    const {width, height} = windowSize;
    return (
        <div className={'inlineBreakPoint'} onClick={() => {
            // navigate("/dev")
        }}>
            {breakPoint}: {width}<small style={{margin: "0px 2px"}}>x</small>{height}
        </div>
    )
}

export {DevToolBreakPoint}
