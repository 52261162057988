
import {IFilter} from "../../table/context/GenericTableContext";

export const ticketFilters: IFilter[] = [

    {
        property: "status",
        label: "Status",
        componentType: "select",
        componentProps: {
            defaultValue: ""
        },
        getOptions: () => {
            return [
                {value: "open", label: "Open"},
                {value: "closed", label: "Closed"}
            ];
        }
    },

];


