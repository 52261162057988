import React, {useState} from "react";
import {IProfile, IUser} from "../interfaces";
import {useAuthApi} from "../api";
import {useProfileApi} from "../api";
import { AuthContext } from "./AuthContext";

const USER_STORAGE_KEY = "user";
const userString = localStorage.getItem(USER_STORAGE_KEY);

const defaultUser = (() => {
    if (userString && userString !== "undefined") {
        const user = JSON.parse(userString);
        return user;
    }
    return null;
})();

const AuthContextProvider = ({children}: any) => {

    const [user, setUser] = useState<IUser | null>(defaultUser);
    const {login, register, logout} = useAuthApi();
    const {getMyProfile, updateMyProfile} = useProfileApi();

    const loginAndLoadMyProfile = async (credentials: any) => {
        await login(credentials);
        return await loadMyProfileAndSaveToContextState();
    }
    const registerAndLoadMyProfile = async (user: any, referralId: string) => {
        await register({
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            password: user.password,
            referralId
        });
        //todo remove this if we don't want to login just after register
        return await loadMyProfileAndSaveToContextState();
    }
    const logoutAndRemoveMyProfile = () => {
        localStorage.clear();
        // localStorage.removeItem(USER_STORAGE_KEY);
        setUser(null)
        logout();
    }

    const updateMyProfileAndContextState = async (profile: IProfile) => {
        await updateMyProfile(profile)
        if (user) {
            const userWithProfileChanges: any = {...user, profile: {...user.profile, ...profile}};
            localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(userWithProfileChanges));
            setUser(userWithProfileChanges)
        }

    }
    const loadMyProfileAndSaveToContextState = async () => {
        const user = await getMyProfile();
        console.log("user from me ", user)
        localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
        setUser(user);
        return user;
    }
    const context = {
        user,
        loginAndLoadMyProfile,
        registerAndLoadMyProfile,
        logoutAndRemoveMyProfile,
        updateMyProfileAndContextState,
        loadMyProfileAndSaveToContextState
    };

    return <AuthContext.Provider value={context}>
        {children}
    </AuthContext.Provider>;
};


export {AuthContextProvider};


