import React from "react";
import {LazyLabelDateTime} from "front-lib";
import {IColumn} from "../../table/context/GenericTableContext";
import DialogEditTranslation from "./dialogs/DialogEditTranslation";

export const translationColumns: IColumn[] = [

    {
        property: "actions",
        label: "Actions",
        disableSorting: true,
        isVisible: true,
        component: ({row, column}) => <div className={'flex items-center'} onClick={(event) => event.stopPropagation()}>
            <DialogEditTranslation translation={row}/>
        </div>
    },
    {
        property: "id",
        label: "ID",
        isVisible: false,
        component: ({row, column}) => <>
            {row.id}
        </>
    },

    {
        property: "language",
        label: "Language",
        isVisible: true,
        component: ({row, column}) => <>
            {row.language}
        </>
    },
    {
        property: "key",
        label: "Key",
        isVisible: true,
        component: ({row, column}) => <>
            {row.key}
        </>
    },
    {
        property: "value",
        label: "Value",
        isVisible: true,
        component: ({row, column}) => <>
            {row.value}
        </>
    },
    {
        property: "createdAt",
        label: "Created",
        isVisible: true,
        component: ({row, column}) => <>
            <LazyLabelDateTime timestamp={row.createdAt}/>
        </>
    },

];
