import React, {useRef} from "react";
import {useFileUpload} from "front-lib";

const AvatarFileUpload = ({
                              children,
                              onChange,
                              accept = "image/svg,image/svg+xml,image/jpg,image/jpeg,image/png"
                          }: any) => {
    const inputFile = useRef<any>(null)
    const {progress, isUploading, upload, error} = useFileUpload();

    const onClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.value = '';
        inputFile.current.click();
    };

    const onChangeFile = async (event: any) => {
        console.log("onChangeFile")
        event.stopPropagation();
        event.preventDefault();
        const file = event.target.files[0];
        const url = await upload("/upload/avatar", file)
        console.log("file uploaded", url)
        onChange && onChange(url);
    }

    const renderChildrenWithOnClick = () => {
        if (React.isValidElement(children)) {
            return React.cloneElement<any>(children, {onClick});
        }
        return children
    }

    return (
        <>

            {renderChildrenWithOnClick()}
            <input type='file' ref={inputFile} style={{display: 'none'}}
                   accept={accept}
                   onChange={onChangeFile}/>

        </>
    )
}

export {AvatarFileUpload}