import React, {useEffect, useState} from "react";
import {EnvironmentLoader} from "../../../modules/env-loader/EnvironmentLoader";
import EnvironmentIframe from "../../../modules/env-loader/EnvironmentIframe";
import {useEnvironmentLoader} from "../../../modules/env-loader/context/useEnvironmentLoader";


const EnvironmentLoaderRoute = ({}) => {
    return (
        <>
            <EnvironmentLoader render={({env}: any) => <EnvironmentLoaderRouteContent env={env}/>}/>
        </>
    )
}

const EnvironmentLoaderRouteContent = ({env}: any) => {
    const {routeKey} = useEnvironmentLoader();

    useEffect(() => {
        console.log("route changed in EnvironmentLoaderRoute", routeKey)
        if (routeKey !== "/") {
            window.location.hash = routeKey;
        } else {
            if (window.location.hash) {
                window.location.hash = ""
            }
        }
    }, [routeKey]);
    return <>
        <EnvironmentIframe env={env} build={env.builds[0]}/>
    </>
}


export default EnvironmentLoaderRoute
