import {ICrudFilters} from "../table/context/GenericTableContext";
import {sleep , api} from "front-lib";
import {useState} from "react";

export const useCrudApi = ({pluralName, singleName, relations}: any) => {
    const [entities, setEntities] = useState<any[]>();
    const [rowsCreatedCount, setRowsCreatedCount] = useState<number>(0);
    const [rowsDeletedCount, setRowsDeletedCount] = useState<number>(0);


    const getMany = async (filters?: ICrudFilters) => {
        // console.log("getMany", filters)
        await sleep(300);
        const response = await api.get(`/admin/${pluralName}`, {
            params: {
                ...filters,
                relations: relations
            }
        });
        setEntities(response.data);
        return response.data;

    }

    const edit = async (entity: any) => {
        const response = await api.put(`/admin/${pluralName}`, entity);
        // console.log("response", response);
        return response.data;
    }

    const create = async (entity: any) => {
        // console.log("creating entity", entity);
        const response = await api.post(`/admin/${pluralName}`, entity);
        // console.log("response", response);
        setRowsCreatedCount(rowsCreatedCount => rowsCreatedCount + 1)

        return response.data;
    }

    const deleteMany = async (ids: string[]) => {
        // console.log("delete ids", ids);
        const response = await api.put(`/admin/${pluralName}/delete-many`, ids);
        setRowsDeletedCount(rowsDeletedCount => rowsDeletedCount + 1)
        // console.log("response", response);
    }

    const search = async (filters?: ICrudFilters) => {
        console.log("search", filters)

        const response = await api.get(`/admin/${pluralName}/search`, {
            params: {
                ...filters,
                relations: relations
            }
        });
        setEntities(response.data);
        return response.data;

    }

    return {
        entities,
        rowsDeletedCount,
        rowsCreatedCount,
        getMany,
        edit,
        create,
        deleteMany,
        search

    }
}