import React from "react";
import {Outlet} from "react-router-dom";
import AppHeader from "./header/AppHeader";
import AdminFooter from "./footer/AdminFooter";
import {ApplicationConfigsProvider, SocketProvider, WhiteLabelThemeProvider} from "front-lib";
import WorkspaceContextProvider from "../app/workspace/context/WorkspaceContextProvider";

const AppLayout = ({}) => {
    // console.log("app layout")
    // useLayoutEffect(() => {
    //     initRecaptcha();
    // }, []);
    return (
        <>
            <WhiteLabelThemeProvider>
                <ApplicationConfigsProvider>
                    <SocketProvider name={"app"}
                                    source={"app"}
                    >
                        <AppLayoutContent/>
                    </SocketProvider>
                </ApplicationConfigsProvider>
            </WhiteLabelThemeProvider>
        </>
    )
}

const AppLayoutContent = () => {

    return <>
        <AppHeader/>
        <div className={'pt-[60px]'}>
            <div className={'px-4 min-h-[calc(100vh_-_120px)] bg-gray-100 dark:bg-gray-800 dark:text-white'}>
                <WorkspaceContextProvider>
                    <Outlet/>
                </WorkspaceContextProvider>
            </div>
            <AdminFooter/>
        </div>
    </>
}
export default AppLayout
