import React, {useState} from "react"
import {IconLogo, useUser} from "front-lib";

const WelcomeBanner = () => {
    const {user} = useUser();
    console.log("WelcomeBanner", !!user)
    const [isOpen, setIsOpen] = useState(!!user);
    const closeBanner = () => {
        setIsOpen(false)
    }
    return (
        <>
            {isOpen && <div tabIndex={-1}
                            className="absolute z-50 flex flex-col md:flex-row justify-between w-[calc(100%-2rem)] p-4 -translate-x-1/2 bg-gray-100 border border-gray-100 rounded-lg shadow-sm lg:max-w-7xl left-1/2 top-6 dark:bg-gray-700 dark:border-gray-600">
                <div
                    className="flex items-center">
                    <a href="https://frontenvs.com/"
                       className="flex items-center mb-2 border-gray-200 md:pr-4  mr-2 md:border-e md:mb-0 dark:border-gray-600">
                        <IconLogo className={'w-11 h-11 fill-primary-200 '}/>
                        <span
                            className="self-center text-lg font-semibold whitespace-nowrap dark:text-white">Frontenvs</span>
                    </a>
                    <p className="flex mb-0 pt-1  items-center text-md font-bold text-gray-500 dark:text-gray-400">
                        This demo environment built by Frontenvs
                    </p>
                </div>
                <div className="flex items-center flex-shrink-0">
                    <a href="https://frontenvs.com/register"
                       className="px-5 py-2 me-2 text-xs font-medium text-white bg-primary-700 rounded-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
                        Sign
                        up
                    </a>
                    <button onClick={closeBanner} data-dismiss-target="#marketing-banner" type="button"
                            className="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white">
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close banner</span>
                    </button>
                </div>
            </div>

            }
        </>
    )
}

export {WelcomeBanner}