
import { IFilter } from "../../table/context/GenericTableContext";

export const userFilters: IFilter[] = [
  {
    property: "id",
    label: "ID",
    componentType: "text"
  },
  {
    property: "email",
    label: "Email",
    componentType: "text",
    whereType: "like"
  },
  {
    property: "firstName",
    label: "FirstName",
    componentType: "text",
    whereType: "like"
  },

  {
    property: "lastName",
    label: "LastName",
    componentType: "text",
    whereType: "like"
  },
  {
    property: "isActive",
    label: "Active",
    componentType: "select",
    componentProps: {
      defaultValue: ""
    },
    getOptions: () => {
      return [
        { value: false, label: "No" },
        { value: true, label: "Yes" }
      ];
    }
  },
  {
    property: "isDeleted",
    label: "Deleted",
    componentType: "select",
    componentProps: {
      defaultValue: ""
    },
    getOptions: () => {
      return [
        { value: false, label: "No" },
        { value: true, label: "Yes" }
      ];
    }
  },
];


