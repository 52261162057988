import React from "react"
import {FormFieldErrorMessage, FormInputText, isValidUrl, useForm, ValidationError, withForm} from "front-lib";
import {FaLink} from "react-icons/fa";
import {useNavigate} from "react-router-dom";

const HeroWizard = withForm(() => {
    const {handleSubmit} = useForm();
    const navigate = useNavigate();

    const onSubmit = () => {
        handleSubmit(({fieldValues}: any) => {
            console.log("fieldValues", fieldValues)

            const url = `/register?url=${encodeURIComponent(fieldValues.rootUrl)}`
            navigate(url)
        })
    }
    return (
        <>
            <div className="relative max-w-[500px] mx-auto  text-left">
                <div className="relative">
                    <div
                        className="absolute inset-y-0 rtl:inset-x-0 start-0 flex items-center pl-3.5 pointer-events-none">
                        <FaLink className={'w-5 h-5 text-gray-500 dark:text-gray-400'}/>
                    </div>
                    <FormInputText name={"rootUrl"}
                                   withError={false}
                                   placeholder="Enter your URL here"
                                   className="block w-full p-6 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-warning-500 focus:border-warning-500 dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-warning-500 dark:focus:border-warning-500"
                                   rules={{
                                       required: true,
                                       validate: (value: string) => {

                                           const isValid = value && isValidUrl(value)
                                           console.log("validate", isValid)
                                           return isValid || ValidationError.ERROR_NOT_VALID_URL
                                       }
                                   }}
                                   defaultValue={''}/>
                    <button type="submit"
                            onClick={onSubmit}
                            className="text-white absolute right-2.5 bottom-2 bg-warning-700 hover:bg-warning-800 focus:ring-4 focus:outline-none focus:ring-warning-300 font-medium rounded-lg text-lg px-6 py-3 dark:bg-warning-600 dark:hover:bg-warning-700 dark:focus:ring-warning-800">
                        Start Build
                    </button>
                </div>
                <FormFieldErrorMessage name={'rootUrl'}/>
            </div>
        </>
    )
})

export {HeroWizard}