import React from "react";
import {FormContextProvider} from "./FormContextProvider";

const withForm = (Component: any) => {

    function WithForm({...rest}) {

        return (
            <FormContextProvider>
                <form className={'h-full w-full min-h-full'} onSubmit={(e: any) => {
                    e.preventDefault();
                }}>
                    <Component {...rest} />
                </form>
            </FormContextProvider>
        );
    }

    return WithForm;
};

export {withForm};
