import React from 'react';
import {useTheme} from "../../theme";
import {DarkModePicker} from "../../theme";
import {LazyFormColorPicker, useForm, withForm , FormSelectThemePaletteColor} from "front-lib";

export const FormThemeColorBuilder = withForm(() => {
    const {setThemeColor, isDarkMode, setDarkMode} = useTheme();
    const {handleSubmit} = useForm();
    const defaultPaletteColor = "primary"
    // const paletteColor = watch("paletteColor", defaultPaletteColor)
    const {theme} = useTheme();
    return <>

        <DarkModePicker />

        <br/>
        <br/>
        <LazyFormColorPicker defaultValue={theme.palette.primary.main} name={"hex"}
                             onChange={(hex: string) => {
                                 setThemeColor("primary", hex)
                             }}
                             label={"Primary Color"}/>
        <br/>

        <FormSelectThemePaletteColor defaultValue={defaultPaletteColor}/>
        <br/>
        <button type="button"
                onClick={() => {
                    handleSubmit(({fieldValues}: any) => {
                        console.log("submit", fieldValues.paletteColor, fieldValues.hex)
                        setThemeColor(fieldValues.paletteColor, fieldValues.hex)
                    })

                }}
                className="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">
            Set Theme Color
        </button>


        {/*<pre>*/}
        {/*  {JSON.stringify(cssVariables(), null, 2)}*/}
        {/*</pre>*/}

    </>
})
