import React, {useEffect} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useUser} from "../hooks";
import {Role} from "../enums";

interface IProps {
    element: JSX.Element;
    role?: Role;
    redirectTo?  : string;
}


export const GuardPrivateRoute = ({element, redirectTo  = "/login" , role = Role.Guest}: IProps) => {
    const {user} = useUser();
    console.log("GuardPrivateRoute", user)
    const {pathname, search} = useLocation();
    const from = pathname + search;
    const isAuthorized = user && user.role >= role;
    // console.log("GuardPrivateRoute", from)
    useEffect(() => {
        if (!isAuthorized) {
            localStorage.setItem("f", from)
        }
    }, [isAuthorized])
    return isAuthorized ? element : <Navigate to={redirectTo} state={{from}}/>;
};

// const DEFAULT_RETURN_URL = process.env.DEFAULT_RETURN_URL || '/';
//
// export const useReturnUrl = () => {
//   const { state }: any = useLocation();
//   return state && state.from ? state.from : DEFAULT_RETURN_URL;
// };
//
//
