import {api, postWithCaptchaToken} from "front-lib";

export const useProfileApi = () => {
    const updateMyProfile = async (profile: any) => {
        await api.put("/profile/me", profile);
    }

    const getMyProfile = async () => {
        const response = await api.get("/profile/me");
        return response.data;
    }

    // const getPublicProfile = async (username?: string) => {
    //     // await sleep(600)
    //     // const response = await getWithCaptchaToken(`/profile/${username}` , 'get_public_profile');
    //     const response = await api.get(`/profile/${username}`);
    //     return response.data;
    //
    // }

    const confirmEmailAddress = async (token: string) => {
        const response = await postWithCaptchaToken('/profile/confirm-email', {token}, "confirm_email");
        return response.data;
    }

    const requestConfirmationEmail = async () => {
        const response = await api.post('/profile/request-confirmation-email');
        return response.data;
    }
    return {
        updateMyProfile,
        getMyProfile,
        // getPublicProfile,
        confirmEmailAddress,
        requestConfirmationEmail

    }
}