import React from 'react'
import {ImHeartBroken} from "react-icons/im";

const ErrorBrokenHeart = ({}) => {
    return (
        <>
            <ImHeartBroken className={'text-primary-500 w-[100px] h-[100px] my-4'}/>
            <span className={'text-gray-400 mb-4'}>Something is broken</span>
        </>
    )
}

export {ErrorBrokenHeart}