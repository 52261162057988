import React, {useEffect, useState} from "react";
import WorkspaceStats from "./stats/WorkspaceStats";
import {useWorkspace} from "./context/useWorkspace";
import WorkspaceUsersTable from "./users/WorkspaceUsersTable";
import DialogEditWorkspace from "./dialogs/DialogEditWorkspace";

const WorkspaceView = ({}) => {
    const {profile} = useWorkspace();
    const {workspace} = profile;
    const pieData = [
        {key: "Male", value: 20},
        {key: "Female", value: 5},
    ]
    return (
        <>


            <WorkspaceStats/>
            <div className="flex items-center mb-4 ">
                <div className="w-full">

                    <div
                        className="relative overflow-hidden bg-white shadow-md dark:bg-gray-950 sm:rounded-lg">

                        <div
                            className="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                            <div>
                                <h5 className="mr-3 font-semibold dark:text-white">{workspace.name}</h5>
                                <p className="text-gray-500 dark:text-gray-400 mb-1">
                                    {/*{selectedPuppet.context}*/}
                                    {/*{selectedPuppet.context.replace(" my prompt:","")}*/}

                                </p>


                            </div>
                            <DialogEditWorkspace workspace={workspace}/>
                            {/*<button type="button"*/}
                            {/*        className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5 mr-2 -ml-1"*/}
                            {/*         viewBox="0 0 20 20" fill="currentColor"*/}
                            {/*         aria-hidden="true">*/}
                            {/*        <path*/}
                            {/*            d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"/>*/}
                            {/*    </svg>*/}
                            {/*    Add new user*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className={'mt-3'}>*/}
            {/*    <WorkspaceBucketsTable/>*/}
            {/*</div>*/}
            {/*{workspaceBuckets && workspaceBuckets.length && <div className={'mt-3 pb-20'}>*/}
            {/*    <RecordsTable feedbacks={workspaceBuckets[0].records}/>*/}
            {/*</div>}*/}
            <div className={'mt-3 pb-20'}>
                <WorkspaceUsersTable/>
            </div>
            {/*<div className={'mt-3 pb-20'}>*/}
            {/*    <WorkspaceFilesTable/>*/}
            {/*</div>*/}

        </>
    )
}

export default WorkspaceView