import React from "react";
import {Helmet} from "react-helmet-async";
import {LoginForm} from "front-lib";

const LoginRoute =() => {
    return (
        <>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <section>
                <LoginForm/>
            </section>
        </>
    );
};

export default LoginRoute;
