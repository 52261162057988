import React from "react"
import ContactUsForm from "./forms/ContactUsForm";

const ContactUsSection = ({}) => {
    return (
        <section className={''}>
            <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
                <ContactUsForm source={"home-contact-us"}/>
            </div>
        </section>
    )
}

export default ContactUsSection